<template>
	<draggable v-bind="dragOptions" tag="div" class="item-container" :list="list" :value="value" @input="emitter">
		<div v-for="(item, index) in realValue" :key="item.id" class="item-group">
			<div class="item p-2 on-parent">
				<div class="row">
					<div class="col d-flex">
						<strong v-if="value" class="text-primary-300 me-1 cursor-move">{{ index + 1 }}.</strong>
						<strong v-else class="text-gray me-1 cursor-move">{{ index + 1 }})</strong>

						<span>{{ item.title }}</span>

						<span
							v-if="item.text"
							class="d-inline-block text-neutral-400 text-truncate ms-1"
							style="max-width: 300px"
						>
							- {{ item.text }}
						</span>
					</div>
					<div class="col-auto">
						<button class="btn-icon text-primary-200 show-on-hover" @click="editItem(item)">
							<font-awesome-icon :icon="['fas', 'pencil']" />
						</button>
						<button class="btn-icon text-danger-200 show-on-hover ms-2" @click="removeItem(item)">
							<font-awesome-icon :icon="['fas', 'trash']" />
						</button>
					</div>
				</div>
				<div v-if="item.files.length" class="ps-3 mt-1">
					<a
						v-for="file in item.files"
						:key="file"
						:href="getPublicFileUrl(file)"
						target="_blank"
						class="inline-block px-2 py-1 border rounded-1 bg-neutral-50 text-dark me-2"
					>
						<font-awesome-icon
							:icon="['fas', file.endsWith('pdf') ? 'file-pdf' : 'file']"
							class="text-primary-100"
						/>
						{{ truncateString(file.split('/').at(-1)) }}</a
					>
				</div>
			</div>

			<agenda-items-list
				v-if="value"
				class="item-sub"
				:list="item.subitems"
				@removeItem="removeItem"
				@editItem="editItem"
			/>
		</div>
	</draggable>
</template>

<style lang="scss" scoped>
.btn-icon {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
}

.item-container {
	margin: 0;
}

.sortable-ghost {
	background-color: #fecaca;
	border-radius: 0.25rem;
}

.sortable-chosen {
	background-color: #fcd34d;
	border-radius: 0.25rem;
}

.sortable-drag {
	background-color: #67e8f9;
	border-radius: 0.25rem;
}

.item {
	border-radius: 0.25rem;
	transition: background-color 0.15s ease-in-out;

	&:hover {
		background-color: #f5f5f5;
	}
}

.item-sub {
	margin-left: 1.25rem;
}
</style>

<script>
import draggable from 'vuedraggable'

import { getPublicFileUrl } from '@/utils.js'
import { truncateString } from '@/lib/strings.js'

export default {
	name: 'AgendaItemsList',
	components: {
		draggable,
	},
	props: {
		value: {
			required: false,
			type: Array,
			default: null,
		},
		list: {
			required: false,
			type: Array,
			default: null,
		},
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
			}
		},
		realValue() {
			return this.value || this.list
		},
	},
	methods: {
		getPublicFileUrl,
		truncateString,

		emitter(value) {
			//console.log('drag emit', value)
			this.$emit('input', value)
		},
		editItem(item) {
			this.$emit('editItem', item)
		},
		removeItem(item) {
			this.$emit('removeItem', item)
		},
	},
}
</script>
