<template>
	<span
		v-if="card"
		class="bg-light d-inline-block px-2 rounded-1"
		:title="`${card.country || '__'} ${card.brand}, funding: ${card.funding || 'unknown'}`"
	>
		<font-awesome-icon :icon="['fab', `cc-${card.brand}`]" class="text-primary" />
		{{ card.last4 || card.acctLastFour }}
		<small v-if="paymentMethod.type === 'card_present'" class="text-muted">💳</small>
	</span>
	<span
		v-else-if="paymentMethod.type === 'us_bank_account'"
		class="bg-light d-inline-block px-2 rounded-1"
		:title="`${details.routing_number} **** ${details.last4} - ${details.bank_name}`"
	>
		<font-awesome-icon :icon="['fas', `building-columns`]" class="text-primary" />
		{{ details.last4 }}
	</span>
	<span
		v-else-if="paymentMethod.type === 'cash'"
		class="bg-light d-inline-block px-2 rounded-1"
		:title="details.note"
	>
		<font-awesome-icon :icon="['fas', `money-bill`]" class="text-primary" /> Cash
	</span>
	<span
		v-else-if="paymentMethod.type === 'check'"
		class="bg-light d-inline-block px-2 rounded-1"
		:title="`Check ${details.note}`"
	>
		<font-awesome-icon :icon="['fas', `money-check`]" class="text-primary" /> {{ details.note }}
	</span>
</template>

<script>
import { Tooltip } from 'bootstrap'

export default {
	name: 'PaymentMethod',
	props: {
		paymentMethod: {
			type: Object,
			required: true,
		},
		tooltip: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			card: null,
			details: null,
		}
	},
	created() {
		if (['card', 'card_present'].includes(this.paymentMethod.type)) {
			// from Stripe
			this.card = this.paymentMethod[this.paymentMethod.type]
		} else if ('card' in this.paymentMethod) {
			// from JustiFi
			this.card = this.paymentMethod.card
		} else if (this.paymentMethod[this.paymentMethod.type]) {
			this.details = this.paymentMethod[this.paymentMethod.type]
		}
	},
	mounted() {
		if (this.tooltip) {
			new Tooltip(this.$el)
		}
	},
}
</script>
