<template>
	<div class="meeting-public-page row justify-content-center">
		<div class="col-xl-7 col-lg-8 col-md-10">
			<div
				v-if="meeting.video_file_path || meeting.audio_file_path"
				class="ratio bg-light rounded-1 mb-4"
				:class="meeting.video_file_path ? 'ratio-16x9' : 'ratio-21x9'"
				ref="meetingOverviewPlayer"
			>
				<div
					v-if="meetingPlayer.position === 'custom'"
					class="d-flex align-items-center justify-content-center"
					@click="setPlayerPosition"
				>
					<span class="text-neutral-400"
						><font-awesome-icon :icon="['fas', 'video']" class="me-1" /> Bring the video here</span
					>
				</div>
			</div>

			<div class="scrolling-tabs mb-3">
				<nav class="hide-scrollbar">
					<ul
						class="nav nav-pills nav-pills-sm hide-scrollbar"
						style="flex-wrap: nowrap; min-width: 100%; overflow-x: scroll;"
					>
						<li class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'overview' }" @click="tab = 'overview'"
								>Overview
							</span>
						</li>
						<li class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'agenda' }" @click="tab = 'agenda'"
								>Agenda</span
							>
						</li>
						<li v-if="meeting.state === 'past'" class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'minutes' }" @click="tab = 'minutes'"
								>Minutes</span
							>
						</li>
					</ul>
				</nav>
			</div>

			<div v-if="tab === 'overview'" class="card mb-4">
				<div class="card-body">
					<p class="mb-2">
						<span class="text-neutral-400">Date: </span>
						<strong class="me-1" :title="`Timezone: ${j.timezone}`">{{
							new Date(meeting.starts_at).toLocaleString('en-US', {
								dateStyle: 'medium',
								timeStyle: 'short',
								timeZone: j.timezone,
							})
						}}</strong>
						<span class="text-neutral-400">({{ meeting.starts_at | dateToNow }})</span>
					</p>

					<p class="card-text">
						<span class="text-neutral-400 me-1">Categories:</span>
						<template v-if="meeting.categories.length">{{
							meetingCategories
								.filter(c => meeting.categories.includes(c.id))
								.map(c => c.name)
								.join(', ')
						}}</template>
						<small v-else class="text-neutral-300">(none)</small>
					</p>

					<p v-if="meeting.description" class="card-text">{{ meeting.description }}</p>

					<div v-if="meeting.state === 'upcoming'">
						<p class="card-text text-center">
							This meeting takes place {{ meeting.starts_at | dateToNow(true) }}.
						</p>
						<p class="card-text text-center">
							Check back later for recording and minutes.
						</p>
					</div>
					<div v-else-if="meeting.state === 'happening'">
						<p class="card-text text-center">
							This meeting is happening right now!
						</p>
						<p class="card-text text-center">
							Check back later for recording and minutes.
						</p>
					</div>
					<div v-else>
						<p v-if="meeting.notes" class="card-text">{{ meeting.notes }}</p>

						<div v-if="meeting.motions.length">
							<p class="subtitle mb-2">Motions made during the meeting</p>

							<div
								v-for="(motion, index) in meeting.motions"
								:key="index"
								class="bg-light rounded-1 p-2 mb-3"
							>
								<span v-if="['carried', 'passed'].includes(motion.vote)">👍</span>
								<span v-else class="badge bg-primary-50 text-primary-400">{{ motion.vote }}</span>
								{{ motion.text || motion.motion }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="tab === 'agenda'" class="card mb-4">
				<div class="card-body">
					<div class="card-table">
						<table class="table table-hover">
							<thead>
								<tr>
									<th scope="col">Item</th>
									<th scope="col">🗂️</th>
									<th v-if="hasRecording" scope="col">timestamp</th>
								</tr>
							</thead>

							<tbody>
								<template v-for="(item, index) in meeting.agenda_items.filter(i => !i.parent_id)">
									<tr :key="item.id">
										<td>
											<strong class="text-primary-300 me-2">{{ index + 1 }}.</strong>

											<span>{{ item.title }}</span>

											<span
												v-if="item.text"
												class="d-inline-block text-neutral-400 text-truncate ms-1"
												style="max-width: 300px"
											>
												- {{ item.text }}
											</span>
										</td>
										<td>
											<small v-if="!item.files.length" class="text-neutral-300">-</small>
										</td>
										<td v-if="hasRecording">
											<small
												class="text-neutral-300 cursor-pointer"
												@click="$emit('playerTimestamp', item.timestamp_start)"
											>
												{{ formatTimestamp(item.timestamp_start) }}
											</small>
										</td>
									</tr>

									<tr
										v-for="(subitem, index2) in meeting.agenda_items.filter(
											i => i.parent_id === item.id
										)"
										:key="subitem.id"
									>
										<td class="ps-5">
											<strong class="text-warning-400 me-2">{{ index2 + 1 }}.</strong>

											<span>{{ subitem.title }}</span>

											<span
												v-if="subitem.text"
												class="d-inline-block text-neutral-400 text-truncate ms-1"
												style="max-width: 300px"
											>
												- {{ subitem.text }}
											</span>
										</td>
										<td>
											<small v-if="!subitem.files.length" class="text-neutral-300">-</small>
										</td>
										<td v-if="hasRecording">
											<small
												class="text-neutral-300 cursor-pointer"
												@click="$emit('playerTimestamp', item.timestamp_start)"
											>
												{{ formatTimestamp(subitem.timestamp_start) }}
											</small>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>

					<!-- <pre>{{ meeting.agenda_items }}</pre> -->
				</div>
				<div class="card-footer">
					<button class="btn btn-sm btn-outline-primary" disabled>Download Agenda</button>
				</div>
			</div>
			<div v-else-if="tab === 'minutes'" class="mb-4">
				<iframe
					v-if="meeting.minutes_status === 'manual-upload'"
					:src="getPublicFileUrl(meeting.meeting_minutes_file_path)"
					width="100%"
					height="1000"
					frameborder="0"
				></iframe>
				<div v-else class="card">
					<div class="card-body">
						<h2 class="card-title text-center mt-4 mb-2">{{ meeting.title }}</h2>
						<h3 class="card-title text-center mb-5">Meeting minutes</h3>

						<div
							v-if="['done', 'fresh-done'].includes(meeting.minutes_status)"
							v-html="meeting.minutes_text"
						></div>

						<p v-else class="text-neutral-400 text-center">
							This meeting has no minutes yet. Please check back later.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import { hgApi } from '@/api.js'
import { getPublicFileUrl } from '@/utils.js'

export default {
	name: 'MeetingsPublicPage',
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	data() {
		return {
			tab: 'overview',
			meetingCategories: [],
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'departments']),
		...mapGetters(['auth', 'currentRole', 'isStaff']),
		hasRecording() {
			return this.meeting.video_file_path || this.meeting.audio_file_path
		},
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.loadMeetingCategories()
	},
	mounted() {
		if (this.meetingPlayer.position === 'default') {
			this.setPlayerPosition()
		}
	},
	methods: {
		getPublicFileUrl,

		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`).then(response => {
				if (response.ok) {
					response.json().then(categories => {
						this.meetingCategories.push(...categories)
					})
				} else {
					Vue.toasted.error('Failed to load meeting categories')
				}
			})
		},

		setPlayerPosition() {
			const $player = this.$refs.meetingOverviewPlayer

			if ($player) {
				const $playerPosition = $player.getBoundingClientRect()

				this.$emit('playerStyles', {
					position: 'absolute',
					width: `${$playerPosition.width}px`,
					top: `${$playerPosition.top + window.scrollY}px`,
					left: `${$playerPosition.left}px`,
					right: 'auto',
					//bottom: '10px',
				})
			}
		},

		formatTimestamp(seconds) {
			if (!seconds) {
				return '00:00'
			}
			const minutes = Math.floor(seconds / 60)
			const remainingSeconds = seconds % 60
			return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
		},
	},
}
</script>
