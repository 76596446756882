import Vue from 'vue'
import Vuex from 'vuex'
import { decode } from 'jsonwebtoken'
import { uniq } from 'lodash'
import { Device } from '@capacitor/device'

import { version } from '@/../package.json'
import router from '../router'
import heyGovApi from '@/api.js'
import { tracker } from '@/utils.js'

Vue.use(Vuex)

const onboarded = !!localStorage.getItem('heygov-hey')

if (!onboarded) {
	localStorage.setItem('heygov-hey', crypto.randomUUID())
}

// production API Endpoint
let apiUrl = 'https://api.heygov.com/'

// guess Api endpoint for current environment
if (localStorage.getItem('heygov-api')) {
	apiUrl = localStorage.getItem('heygov-api')
} else if (process.env.VUE_APP_ENV === 'staging') {
	apiUrl = 'https://heygov-api-main-uez7jeil7q-uc.a.run.app/'
} else if (process.env.VUE_APP_ENV === 'preview') {
	apiUrl = 'https://heygov-api-f-clerkminutes-claude-ai-b6nw773ffq-uc.a.run.app/'
}

// basic detection for device platform
let platform = 'unknown'

if (window.location.origin === 'capacitor://localhost') {
	platform = 'ios'
} else if (window.matchMedia('(display-mode: standalone)').matches) {
	platform = 'android'
}

export default new Vuex.Store({
	state: {
		env: process.env.VUE_APP_ENV,
		apiUrl,
		device: { platform },
		appVersion: version,
		isMobile: window.innerWidth < 760,
		onboarded,
		geolocation: JSON.parse(sessionStorage.getItem('heygov-geolocation')),
		menuOpen: false,
		sidebarMini: localStorage.getItem('heygov-sidebar-mini') == '1',
		stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
		stripePublishableTestKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_TEST_KEY,
		colors: ['#C0EEEB', '#B7D4FF', '#D0BAEC', '#C6DDB8', '#FFD6F8', '#FFEBA5', '#FFD2B1', '#FFC9C9'],

		// auth token & account info
		token: localStorage.getItem('auth-token') || null,
		authPerson: null,
		account: null,

		// available jurisdictions for current auth person
		jurisdictions: JSON.parse(localStorage.getItem('auth-jurisdictions') || null),

		// current app jurisdiction
		j: JSON.parse(localStorage.getItem('auth-current-jurisdiction') || null),

		features: {
			clerkminutes: 'ClerkMinutes',
			issues: 'Hey311',
			forms: 'HeyLicense',
			polls: 'HeyPolling',
			venues: 'HeyReserve',
			payments: 'QuickPay',
			meetings: 'Meetings',
			events: 'Events',
			faqs: 'FAQs',
			chatbot: 'Chatbot',
		},
		demos: {
			'townofatanas.com': 'https://widget-demo.heygov.com/',
			'blackriverfalls.us': 'https://widget-demo.heygov.com/blackriverfalls.html',
			'crescentcity-fl.com': 'https://widget-demo.heygov.com/crescentcity.html',
		},
		extraDisclaimers: ['forestvilletown.com'],
		threadSources: {
			widget:
				'From HeyGov widget <img src="https://files.heygov.com/assets/heygov-logo-mark-180.png" width="18" height="18" alt="HeyGov" loading="lazy">',
			ios:
				'HeyGov iOS app <img src="https://files.heygov.com/assets/heygov-logo-mark-180.png" width="18" height="18" alt="HeyGov" loading="lazy">',
			android:
				'HeyGov Android app <img src="https://files.heygov.com/assets/heygov-logo-mark-180.png" width="18" height="18" alt="HeyGov" loading="lazy">',
			messenger:
				'From Messenger <img src="https://static.xx.fbcdn.net/rsrc.php/ym/r/YQbyhl59TWY.ico" width="18" height="18" alt="Messenger" loading="lazy">',
			facebook:
				'From Facebook Page <img src="https://static.xx.fbcdn.net/rsrc.php/yo/r/iRmz9lCMBD2.ico" width="18" height="18" alt="Facebook" loading="lazy">',
		},
		roles: {
			ADMIN: {
				name: 'HeyGov Admin',
				class: 'bg-warning-lighter text-warning',
				text:
					'Person with permissions to HeyGov billing, add & edit departments, inviting other Admins, and manage forms, venues, and 311 requests.',
			},
			EDITOR: {
				name: 'Department Admin',
				class: 'bg-info-lighter text-info',
				text:
					'Person with permissions to change own department info, invite people to department, handle 311 requests',
			},
			WORKER: {
				name: 'Department Member',
				class: 'bg-light text-dark',
				text:
					'Person with permissions to view info in own department: 311 requests, payments, form applications, etc.',
			},
		},
		statuses: {
			new: {
				name: 'Received',
				class: 'text-secondary',
				bgClass: 'bg-secondary',
			},
			working: {
				name: 'In Progress',
				class: 'text-primary',
				bgClass: 'bg-primary',
			},
			resolved: {
				name: 'Resolved',
				class: 'text-success',
				bgClass: 'bg-success',
			},
			closed: {
				name: 'Declined',
				class: 'text-danger',
				bgClass: 'bg-danger',
			},
		},
		services: null,
		departments: null,
		people: {},
		notifications: [],
		pollAnswers: JSON.parse(localStorage.getItem('heygov-polls') || '{}'),
		previewPersonId: null,

		// app cache
		forms: [],
		formsLoaded: false,
		venues: [],
		venuesLoaded: false,
		venueGroups: [],
		venueGroupsLoaded: false,
	},
	getters: {
		auth(state) {
			if (state.token && !state.authPerson) {
				state.authPerson = decode(state.token)
			}

			return state.authPerson
		},
		currentRole(state) {
			let role = state?.j?.role || 'CITIZEN'

			// if current user is a HeyGov Admin, make it Admin in all jurisdiction
			if (state.authPerson?.is_admin === 1) {
				role = 'ADMIN'
			}

			return role
		},
		isStaff(state) {
			return state.authPerson?.is_admin === 1
		},
		isTestingApp() {
			return location.href.includes('testing') || location.href.includes('localhost')
		},
		servicesByGroup(state) {
			let servicesByGroup = {}

			if (state.services) {
				state.services.forEach(service => {
					if (service.status === 'active') {
						servicesByGroup[service.group] ||= []
						servicesByGroup[service.group].push(service)
					}
				})
			}

			return servicesByGroup
		},
		activeDepartments(state) {
			return (state.departments || []).filter(d => d.status === 'active' && d.assignable)
		},
		unseenNotifications(state) {
			return state.notifications.filter(n => !n.seen)
		},
	},
	mutations: {
		deviceInfo(state) {
			Device.getInfo().then(device => {
				// overwrite platform in PWA mode
				if (device.platform === 'web' && ['ios', 'android'].includes(platform)) {
					device.platform = platform
				}

				state.device = device

				if (state.j && !window.location.href.includes('pdfPreview')) {
					heyGovApi.get(`/${state.j?.slug || 'hey-gov'}/say-this/app-open`, {
						params: {
							hey: device.uuid || localStorage.getItem('heygov-hey'),
						},
					})
				}
			})
		},
		setApiUrl(state, url) {
			state.apiUrl = url
			localStorage.setItem('heygov-api', url)
		},
		setGeolocation(state, geo) {
			state.geolocation = geo
			sessionStorage.setItem('heygov-geolocation', JSON.stringify(geo))
		},

		setAuth(state, token) {
			localStorage.setItem('auth-token', token)
			state.token = token
		},
		setAuthJurisdictions(state, jurisdictions) {
			localStorage.setItem('auth-jurisdictions', JSON.stringify(jurisdictions))
			state.jurisdictions = jurisdictions
		},
		clearFormsData(state) {
			state.forms = []
			state.formsLoaded = false
		},
		clearVenuesData(state) {
			state.venues = []
			state.venuesLoaded = false
			state.venueGroups = []
			state.venueGroupsLoaded = false
		},
		authLogout(state) {
			state.token = null
			state.authPerson = null
			state.jurisdictions = null
			state.j = null
		},
		accountInfo(state, account) {
			state.account = account
		},
		notOnboarded(state) {
			state.onboarded = false
		},
		toggleMenu(state, open) {
			if (open === undefined) {
				open = !state.menuOpen
			}

			state.menuOpen = open
		},
		toggleSidebar(state) {
			state.sidebarMini = !state.sidebarMini
			localStorage.setItem('heygov-sidebar-mini', state.sidebarMini ? '1' : '0')
		},

		// jurisdiction data
		setCurrentJurisdiction(state, jurisdiction) {
			localStorage.setItem('auth-current-jurisdiction', JSON.stringify(jurisdiction))
			state.j = jurisdiction
		},
		updateCurrentJurisdiction(state, jurisdiction) {
			state.j = { ...state.j, ...jurisdiction }
		},

		addPeople(state, people) {
			people.forEach(person => {
				state.people[person.id] = person
			})
		},
		addPerson(state, person) {
			const newPeople = {}
			newPeople[person.id] = person
			state.people = { ...state.people, ...newPeople }
		},

		setServices(state, services) {
			state.services = services
		},
		addService(state, service) {
			state.services.push(service)
		},
		removeService(state, service) {
			state.services = state.services.filter(s => s.id !== service.id)
		},

		setDepartments(state, departments) {
			state.departments = departments
		},
		addDepartment(state, department) {
			state.departments.push(department)
		},
		addNotifications(state, notifications) {
			state.notifications.unshift(...notifications)
		},
		/* Add Category
		addCategory(state, category) {
			state.category.push(category)
		},
		*/

		clearNotifications(state) {
			state.notifications = []
		},
		markNotificationsAsSeen(state) {
			if (state.notifications.length && !state.notifications[0].seen) {
				heyGovApi.post(`/${state.j.slug}/notifications/last-seen`, { last_id: state.notifications[0].id })
				//state.notifications = state.notifications.map(n => ({ ...n, seen: 1 }))
				state.notifications.forEach(n => {
					n.seen = 1
				})
			}
		},
		pollAnswered(state, payload) {
			state.pollAnswers[payload.poll] = payload.answer

			localStorage.setItem('heygov-polls', JSON.stringify(state.pollAnswers))
		},

		// Forms
		setForms(state, forms) {
			state.forms = forms
		},
		addForm(state, form) {
			state.forms.push(form)
		},

		// Venues
		setVenues(state, venues) {
			state.venuesLoaded = true
			state.venues = venues
		},
		addVenue(state, venue) {
			state.venues.push(venue)
		},

		// Venue Groups
		setVenueGroups(state, venueGroups) {
			state.venueGroupsLoaded = true
			state.venueGroups = venueGroups
		},

		setPreviewPersonId(state, id) {
			state.previewPersonId = id
		},
	},
	actions: {
		accountRegister: ({ commit, dispatch }, account) => {
			return new Promise((resolve, reject) => {
				heyGovApi
					.post('account', account)
					.then(resp => {
						commit('setAuth', resp.data.token)

						dispatch('domainsMonitoring')
						commit('accountInfo', resp.data.person)
						resolve(resp)
					})
					.catch(err => {
						//commit(AUTH_ERROR, err)
						localStorage.removeItem('auth-token')
						reject(err)
					})
			})
		},
		authRequest: ({ commit, dispatch }, account) => {
			return new Promise((resolve, reject) => {
				heyGovApi
					.post('account/login', account)
					.then(resp => {
						// This is a successful authentication
						if (resp.data.auth) {
							commit('setAuthJurisdictions', resp.data.jurisdictions)
							if (resp.data.jurisdictions.length === 1) {
								commit('setCurrentJurisdiction', resp.data.jurisdictions[0])
							}
							commit('setAuth', resp.data.token)
							dispatch('accountInfo')
						}

						resolve(resp)
					})
					.catch(err => {
						//commit(AUTH_ERROR, err)
						localStorage.removeItem('auth-token')
						reject(err)
					})
			})
		},
		authLogout: ({ commit, dispatch }) => {
			return new Promise(resolve => {
				dispatch('clearJurisdictionData')
				commit('authLogout')
				localStorage.removeItem('auth-token')
				localStorage.removeItem('auth-jurisdictions')
				localStorage.removeItem('auth-current-jurisdiction')
				resolve()
				router.push('/account/logged-out')
				window.location.reload()
			})
		},
		accountInfo: ({ state, commit }) => {
			if (state.account === null && state.token) {
				commit('accountInfo', false)

				heyGovApi.get('account/me').then(resp => {
					commit('accountInfo', resp.data)
				})
			}
		},
		accountUpdate: ({ commit, state }, account) => {
			return new Promise((resolve, reject) => {
				heyGovApi
					.put(`account/${state.authPerson.id}`, account)
					.then(resp => {
						commit('accountInfo', resp.data)
						Vue.toasted.success(`Account info is updated`)
						resolve(resp)

						// TODO refresh auth token if name or email is updated
					})
					.catch(error => {
						Vue.toasted.error(`Account update failed (${error.message})`)
						reject(error)
					})
			})
		},
		accountUpdateIfEmpty: ({ state, dispatch }, fields) => {
			const toUpdate = {}

			for (const field in fields) {
				if (!state.account[field]) {
					toUpdate[field] = fields[field]
				}
			}

			if (Object.keys(toUpdate).length) {
				dispatch('accountUpdate', toUpdate)
			}
		},

		setJurisdiction: ({ commit, dispatch, state }, jurisdictionSlug) => {
			return new Promise((resolve, reject) => {
				heyGovApi(jurisdictionSlug).then(
					({ data: jurisdiction }) => {
						// find jurisdiction in person auth list
						if (state.jurisdictions) {
							const authJurisdiction = state.jurisdictions.find(j => j.slug === jurisdictionSlug)

							if (authJurisdiction) {
								jurisdiction.role = authJurisdiction.role
								jurisdiction.roles = authJurisdiction.roles
								jurisdiction.department_id = authJurisdiction.department_id
							}
						}

						if (
							!state.j ||
							state.j.slug !== jurisdictionSlug ||
							state.j.features.length !== jurisdiction.features.length
						) {
							dispatch('clearJurisdictionData')

							commit('setCurrentJurisdiction', jurisdiction)
							resolve(state.j)
						} else {
							commit('setCurrentJurisdiction', jurisdiction)

							resolve(state.j)
						}

						tracker.setMetadata('jurisdiction', jurisdictionSlug)
					},
					error => {
						reject(new Error(`Jurisdiction not found (${error.message})`))
					}
				)
			})
		},

		saveCurrentJurisdiction({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				heyGovApi
					.put(`/jurisdictions/${state.j.id}`, {
						name: state.j.name,
						website: state.j.website,
						email: state.j.email,
						location: JSON.stringify(state.j.location),
						population_count: state.j.population_count,
						households_count: state.j.households_count,
						features_options: JSON.stringify(state.j.features_options),
					})
					.then(
						resp => {
							commit('setCurrentJurisdiction', state.j)

							// save updated info in list of auth jurisdictions
							// to have up-to-date info in case of muni switch
							const updatedJurisdictions = state.jurisdictions.map(j => {
								if (j.id === resp.data.id) {
									j = {
										...j,
										...resp.data,
									}
								}

								return j
							})
							commit('setAuthJurisdictions', updatedJurisdictions)

							Vue.toasted.success(payload?.message || `Info for "${state.j.name}" is saved`)
							resolve(resp)
						},
						error => {
							Vue.toasted.error(error)
							reject(error)
						}
					)
			})
		},
		clearJurisdictionData: ({ commit }) => {
			commit('setServices', null)
			commit('setDepartments', null)
			commit('clearFormsData')
			commit('clearVenuesData')
			commit('clearNotifications')
		},

		loadPeople: ({ dispatch }, ids) => {
			/*
			heyGovApi.get(`${state.j.id}/people`, { params: { id: ids.join(',') } })
				.then(resp => {
					commit('addPeople', resp.data)
				})
			*/

			uniq(ids.filter(Boolean)).forEach(id => {
				dispatch('loadPerson', id)
			})
		},
		loadPerson: ({ commit, state }, personId) => {
			if (!(personId in state.people)) {
				//state.people[personId] = {}
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.id}/people/${personId}`)
						.then(resp => {
							commit('addPerson', resp.data)
							resolve(resp)
						})
						.catch(reject)
				})
			}
		},

		loadServices: ({ commit, state }, forceReload) => {
			if (state.services === null || forceReload) {
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.id}/services`)
						.then(resp => {
							commit('setServices', resp.data)
							resolve(resp)
						})
						.catch(reject)
				})
			}
		},
		loadDepartments: ({ commit, state }, forceReload) => {
			if (state.departments === null || forceReload) {
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.slug}/departments`)
						.then(resp => {
							commit('setDepartments', resp.data)
							resolve(resp.data)
						})
						.catch(reject)
				})
			} else {
				return state.departments
			}
		},

		getForms: ({ state, commit }) => {
			if (state.formsLoaded) {
				return state.forms
			} else {
				state.formsLoaded = true

				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.slug}/forms?orderBy=name&order=asc`)
						.then(resp => {
							resp.data = resp.data.map(form => {
								form.steps = []

								return form
							})

							commit('setForms', resp.data)
							resolve(resp.data)
						})
						.catch(reject)
				})
			}
		},
		getForm: ({ state, commit }, idOrSlug) => {
			const foundForm = state.forms.find(form => form.id == idOrSlug || form.slug == idOrSlug)

			if (foundForm) {
				return foundForm
			} else {
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.slug}/forms/${idOrSlug}`)
						.then(resp => {
							resp.data.steps = []
							commit('addForm', resp.data)
							resolve(resp.data)
						})
						.catch(reject)
				})
			}
		},

		getVenues: ({ state, commit }) => {
			if (state.venuesLoaded) {
				return state.venues
			} else {
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.slug}/venues`)
						.then(resp => {
							commit('setVenues', resp.data)
							resolve(resp.data)
						})
						.catch(reject)
				})
			}
		},
		getVenue: ({ state, commit }, idOrSlug) => {
			const foundVenue = state.venues.find(venue => venue.id == idOrSlug || venue.slug == idOrSlug)

			if (foundVenue) {
				return foundVenue
			} else {
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.slug}/venues/${idOrSlug}`)
						.then(resp => {
							resp.data.steps = []
							commit('addVenue', resp.data)
							resolve(resp.data)
						})
						.catch(reject)
				})
			}
		},

		getVenueGroups: ({ state, commit }) => {
			if (state.venueGroupsLoaded) {
				return state.venueGroups
			} else {
				return new Promise((resolve, reject) => {
					heyGovApi
						.get(`${state.j.slug}/venue-groups`)
						.then(resp => {
							commit('setVenueGroups', resp.data)
							resolve(resp.data)
						})
						.catch(reject)
				})
			}
		},

		loadNotifications({ commit, state, dispatch }) {
			if (state.j && state.token) {
				const params = {}

				if (state.notifications.length) {
					params.since_id = state.notifications[0].id
				}

				heyGovApi.get(`/${state.j.slug}/notifications`, { params }).then(({ data }) => {
					const peopleIds = data.map(t => t.by_person_id).filter(Boolean)
					dispatch('loadPeople', uniq(peopleIds))

					commit('addNotifications', data)
				})
			}
		},

		updateThread({ state }, { thread, fields, fieldsOld, messages }) {
			const isUndo = Boolean(thread._undo)

			return new Promise((resolve, reject) => {
				// tell API this is an undo update
				if (isUndo && messages) {
					const lastMessage = messages.pop()
					fields._undo = lastMessage.id
				}

				heyGovApi.put(`/${state.j.id}/threads/${thread.id}`, fields).then(
					response => {
						if (response.data.activityMessage && messages) {
							const foundMessage = messages.find(m => m.id === response.data.activityMessage.id)

							if (foundMessage) {
								// if a previous activity message was updated
								foundMessage.message = response.data.activityMessage.message
								foundMessage.data = response.data.activityMessage.data
							} else {
								// if an activity message was created
								messages.push(response.data.activityMessage)
							}
						}

						const toastedOptions = {}

						if (isUndo) {
							thread._undo = false
						} else {
							if (fieldsOld) {
								toastedOptions.action = {
									text: 'Undo',
									onClick: (e, toastObject) => {
										thread._undo = true
										for (const field in fieldsOld) {
											thread[field] = fieldsOld[field]
										}
										toastObject.goAway(100)
									},
								}
							}

							Vue.toasted.show(`Issue updated, ${response.data.activityMessage.message}`, toastedOptions)
						}

						resolve(response)
					},
					error => {
						Vue.toasted.error(error)
						reject(error)
					}
				)
			})
		},
		reset: ({ commit, dispatch }) => {
			return new Promise(resolve => {
				dispatch('clearJurisdictionData')
				commit('authLogout')
				commit('notOnboarded')
				localStorage.removeItem('auth-token')
				localStorage.removeItem('auth-jurisdictions')
				localStorage.removeItem('auth-current-jurisdiction')
				localStorage.removeItem('heygov-api')

				resolve()
				router.push('/')
			})
		},
	},
	modules: {},
	plugins: [],
})
