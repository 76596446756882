<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title my-0">Edit Transcript Line</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="modal"
				aria-label="Close"
				@click="$emit('close')"
			/>
		</div>
		<div class="modal-body">
			<div class="transcript-line-editor hover" :class="{ 'transcript-line-updated': text !== line.text }">
				<form>
					<textarea
						class="form-control"
						rows="1"
						v-model="text"
						autocorrect="off"
						required
						placeholder="🙈 can't be empty"
						style="height: 400px"
					/>
				</form>
			</div>
		</div>
		<div class="modal-footer justify-content-between">
			<button class="btn btn-sm" type="button" data-bs-dismiss="modal" @click="$emit('close')">
				Cancel
			</button>
			<button
				class="btn btn-sm btn-primary"
				data-bs-dismiss="modal"
				:disabled="text === line.text"
				@click="$emit('save', { ...line, text })"
			>
				Save
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EditTranscriptLineForm',
	props: {
		line: {
			type: Object,
			required: true,
		},
	},
	data() {
		return { text: this.line.text }
	},
}
</script>
