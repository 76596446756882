<template>
	<img
		v-if="person"
		class="person-avatar rounded-circle shadow-sm"
		:class="[`role-${role}`]"
		:src="person.photo || gravatarUrl"
		:alt="person.name"
		:width="size"
		:height="size"
		data-bs-toggle="tooltip"
		:title="person.name"
		@click="openPreview"
	/>
</template>

<style scoped lang="scss">
@import '@/assets/variables';

.person-avatar {
	border: 1px solid white;

	&.role-admin {
		border-color: $warning-200;
	}

	&.role-editor {
		border-color: $primary-200;
	}

	&.role-worker {
		border-color: $gray;
	}
}
</style>

<script>
import md5 from 'md5'
import { Offcanvas, Tooltip } from 'bootstrap'

export default {
	name: 'PersonAvatar',
	props: {
		person: {
			type: Object,
			required: true,
		},
		size: {
			type: Number,
			default: 32,
		},
		preview: {
			type: Boolean,
			default: true,
		},
		tooltip: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			defaultAvatarUrl: 'https://files.layered.market/neutral-1.png',
		}
	},
	computed: {
		gravatarUrl() {
			const emailHash = this.person.email_hash || md5(this.person.email.trim().toLowerCase())

			return `https://secure.gravatar.com/avatar/${emailHash}?s=${this.size * 2}&d=${encodeURIComponent(
				this.defaultAvatarUrl
			)}`
		},
		role() {
			if (this.person.role) {
				return this.person.role.toLowerCase()
			}

			if (this.person.roles?.length) {
				if (this.person.roles.includes('ADMIN')) {
					return 'admin'
				} else if (this.person.roles.includes('EDITOR')) {
					return 'editor'
				} else if (this.person.roles.includes('WORKER')) {
					return 'worker'
				} else {
					return 'citizen'
				}
			}

			return 'citizen'
		},
	},
	mounted() {
		if (this.tooltip) {
			document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
				new Tooltip(el)
			})
		}
	},
	methods: {
		openPreview($event) {
			if (this.preview) {
				$event.preventDefault()
				$event.stopPropagation()
				const of = new Offcanvas(document.getElementById('person-preview'))
				of.show()
				this.$store.commit('setPreviewPersonId', this.person.id)
			}
		},
	},
}
</script>
