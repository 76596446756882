<template>
	<div class="bookings-page">
		<div class="row mb-3">
			<h3 class="mb-0">
				<router-link :to="`/${j.slug}/venues`">HeyReserve</router-link>
				<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" /> All Bookings
			</h3>
		</div>

		<div class="bar-filters bar-filters-venue-bookings border rounded bg-white p-1 mb-2">
			<div class="row align-items-center g-2">
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.venue_id">
						<option value="">All venues</option>
						<option value="group-null">Individual venues</option>
						<optgroup label="Grouped">
							<option
								v-for="venueGroup in venueGroups"
								:key="`${venueGroup.id}-venue-group`"
								:value="`group-${venueGroup.id}`"
								>{{ venueGroup.name }}</option
							>
						</optgroup>
						<optgroup label="Ungrouped">
							<option
								v-for="venue in venues.filter(v => !v.venue_group_id)"
								:key="`${venue.id}-venue`"
								:value="venue.id"
								>{{ venue.name }}</option
							></optgroup
						>
					</select>
				</div>
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.status">
						<option value="">Status</option>
						<option value="draft">Draft</option>
						<option value="pending">Pending</option>
						<option value="approved">Approved</option>
						<option value="progress">In progress</option>
						<option value="ended">Checked out</option>
						<option value="completed">Completed</option>
						<option value="declined">Declined</option>
						<option value="canceled">Canceled</option>
					</select>
				</div>
				<div class="col-sm-6 col-lg-3">
					<people-search
						placeholder="🔍 Guest"
						:limit="10"
						:fields="['name', 'email']"
						@person="setPersonFilter"
						@noPerson="clearPersonFilter"
					></people-search>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="input-group rounded">
						<select class="form-select select-date" v-model="dateType">
							<option value="created_at">Created</option>
							<option value="starts_at">Booking Date</option>
						</select>
						<date-picker
							:formatter="dateFormatter"
							v-model="dateRange"
							:editable="false"
							:clearable="true"
							input-class="form-control"
							range
							@change="setDateFilters"
							placeholder="Any time"
						>
							<template v-slot:sidebar="{ emit }">
								<div class="text-secondary">
									Current
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPThisWeek(emit)">
											This Week
										</button>
									</div>
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPThisMonth(emit)">
											This Month
										</button>
									</div>
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="getDPThisQuarter(emit)"
										>
											This Quarter
										</button>
									</div>
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPThisYear(emit)">
											This Year
										</button>
									</div>
								</div>
								<div class="text-secondary">
									{{ dateType === 'created_at' ? 'Previous' : 'Next' }}
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="
												dateType === 'created_at' ? getDPLastWeek(emit) : getDPNextWeek(emit)
											"
										>
											{{ dateType === 'created_at' ? 'Last' : 'Next' }} Week
										</button>
									</div>
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="
												dateType === 'created_at' ? getDPLastMonth(emit) : getDPNextMonth(emit)
											"
										>
											{{ dateType === 'created_at' ? 'Last' : 'Next' }} Month
										</button>
									</div>
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="
												dateType === 'created_at'
													? getDPLastQuarter(emit)
													: getDPNextQuarter(emit)
											"
										>
											{{ dateType === 'created_at' ? 'Last' : 'Next' }} Quarter
										</button>
									</div>
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="
												dateType === 'created_at' ? getDPLastYear(emit) : getDPNextYear(emit)
											"
										>
											{{ dateType === 'created_at' ? 'Last' : 'Next' }} Year
										</button>
									</div>
								</div>
							</template>
						</date-picker>
					</div>
				</div>
				<!--
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.department_id">
						<option value="">Department</option>
						<option
							v-for="department in activeDepartments"
							:key="`${department.id}-department`"
							:value="department.id"
							>{{ department.name }}</option
						>
					</select>
				</div>
				-->
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.payment">
						<option value="">Payment</option>
						<option value="succeeded">Paid</option>
						<option value="requires_capture">Authorized</option>
						<option value="requires_payment_method">Waiting</option>
						<option value="canceled">Canceled</option>
					</select>
				</div>
			</div>
		</div>
		<div class="my-2 bg-white rounded px-3 py-2 shadow-sm">
			<div class="row align-items-center" style="height:35px">
				<div class="col">
					<input type="checkbox" @change="selectAll" id="people-select-all" class="me-2" />

					<span v-if="selectedBookingsIds.length">
						<button
							v-if="selectedBookingsPending.length"
							data-bs-toggle="modal"
							data-bs-target="#decline-bookings-modal"
							class="btn btn-sm btn-primary mx-1"
						>
							<font-awesome-icon :icon="['fas', 'hand-paper']" class="me-1" /> Decline
							{{ selectedBookingsPending.length === 1 ? 'booking' : 'bookings' }}
						</button>

						<button v-if="isStaff" class="btn btn-sm btn-outline-danger mx-1" @click="deleteBookings">
							<font-awesome-icon :icon="['fas', 'trash']" class="me-1" /> Delete
							{{ selectedBookingsIds.length === 1 ? 'booking' : 'bookings' }}
						</button>
					</span>
					<label v-else for="people-select-all" class="text-muted">
						<span v-if="pag.total"
							>Showing {{ pag.perPage * (pag.page - 1) + 1 }}-{{ pag.perPage * pag.page }} of
							{{ pag.total }}</span
						>
					</label>
				</div>
				<div v-if="states.bookings === 'idle'" class="col-auto">
					<a :href="exportUrl" class="btn btn-sm btn-outline-dark" :class="{ disabled: !pag.total }"
						>Export reservations</a
					>
				</div>
				<div class="col-auto ps-0">
					<div class="dropdown">
						<button
							class="btn btn-sm px-2"
							type="button"
							data-bs-toggle="dropdown"
							title="Customize table columns"
						>
							<font-awesome-icon :icon="['fas', 'columns']" />
						</button>
						<ul class="dropdown-menu">
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="person_id" /> Guest
									name</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="starts_at" /> Reservation
									time</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="venue_id" /> Venue</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="status" /> Booking
									status</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="price" /> Price</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="payment_status" /> Payment
									status</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="deposit" /> Deposit</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="deposit_status" /> Deposit
									status</label
								>
							</li>

							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="booked_at" /> Booked on</label
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr class="text-uppercase text-muted fw-normal">
								<td></td>
								<td v-if="tableColumns.includes('venue_id')">Venue</td>
								<td
									v-if="tableColumns.includes('starts_at')"
									@click="sortTable('starts_at', 'desc')"
									class="hover cursor-pointer"
								>
									Date
									<span v-if="sorting.orderBy === 'starts_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
								<td v-if="tableColumns.includes('person_id')">Guest</td>
								<td v-if="tableColumns.includes('status')">Status</td>
								<td
									v-if="tableColumns.includes('price')"
									@click="sortTable('price', 'desc')"
									class="hover cursor-pointer"
								>
									Price
									<span v-if="sorting.orderBy === 'price'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
								<td v-if="tableColumns.includes('payment_status')">Payment status</td>
								<td
									v-if="tableColumns.includes('deposit')"
									@click="sortTable('deposit', 'desc')"
									class="hover cursor-pointer"
								>
									Deposit
									<span v-if="sorting.orderBy === 'deposit'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
								<td v-if="tableColumns.includes('deposit_status')">Deposit status</td>

								<td
									v-if="tableColumns.includes('booked_at')"
									@click="sortTable('booked_at', 'desc')"
									class="hover cursor-pointer"
								>
									Booked on
									<span v-if="sorting.orderBy === 'booked_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
							</tr>
						</thead>
						<tbody>
							<template v-for="booking in bookings">
								<router-link
									v-if="venues.length"
									:key="booking.id"
									class="on-parent"
									:to="
										`/${j.slug}/venues/${
											venues.find(v => v.id === booking.venue_id).slug
										}/bookings/${booking.uuid}`
									"
									tag="tr"
								>
									<td class="py-1 pe-1 ps-2">
										<label
											class="p-1 ps-2"
											:for="`vb-check-${booking.id}`"
											@click="$event.stopPropagation()"
										>
											<input
												type="checkbox"
												v-model="selectedBookingsIds"
												:value="booking.id"
												:id="`vb-check-${booking.id}`"
											/>
										</label>
									</td>
									<td v-if="tableColumns.includes('venue_id')">
										<router-link
											v-if="venues.length"
											:to="
												`/${j.slug}/venues/${venues.find(v => v.id === booking.venue_id).slug}`
											"
											>{{ venues.find(v => v.id === booking.venue_id).name }}</router-link
										>
									</td>
									<td v-if="tableColumns.includes('starts_at')">
										{{
											new Date(booking.starts_at).toLocaleString('en-US', {
												dateStyle: 'short',
												timeStyle: 'short',
												timeZone: j.timezone,
											})
										}}
									</td>
									<td v-if="tableColumns.includes('person_id')">
										<person-link :id="booking.person_id" :avatar="16">{{
											booking.person_id
										}}</person-link>
									</td>
									<td v-if="tableColumns.includes('status')">
										<venue-booking-status
											:venueBooking="booking"
											:tooltip="true"
										></venue-booking-status>
									</td>
									<td v-if="tableColumns.includes('price')" class="text-end">
										<span :class="{ 'text-neutral-400': !booking.price }">{{
											booking.price | currency
										}}</span>
									</td>
									<td v-if="tableColumns.includes('payment_status')">
										<div v-if="booking.payment">
											<span
												class="badge"
												:class="venueBookingsPaymentStatuses[booking.payment].class"
												>{{ venueBookingsPaymentStatuses[booking.payment].name }}</span
											>
										</div>
										<div v-else class="d-none"></div>
									</td>
									<td v-if="tableColumns.includes('deposit')" class="text-end">
										<span :class="{ 'text-neutral-400': !booking.deposit }">{{
											booking.deposit | currency
										}}</span>
									</td>
									<td v-if="tableColumns.includes('deposit_status')">
										<span
											v-if="venueBookingsPaymentStatuses[booking.deposit_status]"
											class="badge"
											:class="venueBookingsPaymentStatuses[booking.deposit_status].class"
											>{{ venueBookingsPaymentStatuses[booking.deposit_status].name }}</span
										>
									</td>

									<td v-if="tableColumns.includes('booked_at')">
										{{ booking.booked_at || booking.created_at | dateLocal }}
									</td>
								</router-link>
							</template>
						</tbody>
						<tfoot>
							<tr v-if="states.bookings === 'loading'">
								<td colspan="10" class="text-center py-5">
									<span class="spinner-border" role="status"></span>
								</td>
							</tr>
							<tr v-else-if="!bookings.length">
								<td colspan="10" class="text-center py-5">
									<div v-if="hasActiveFilters">
										<img
											src="https://files.heygov.com/illustrations/illustration-arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">No reservations found. Try changing the filters</p>
									</div>
									<div v-else>
										<img
											src="https://files.heygov.com/illustrations/illustration-building-people.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">Awaiting reservations</p>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<div v-if="pag.total" class="row">
					<div class="col">
						<span class="me-2 text-muted">Per page</span>
						<div class="d-inline-flex">
							<select class="form-select form-select-sm" v-model="pag.perPage">
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>

					<!-- Pagination -->
					<div class="col-auto">
						<nav aria-label="Page navigation example">
							<ul v-if="pag.pages" class="pagination mb-0">
								<!-- TODO add these buttons when there are > 20 pages -->
								<!--
									<li class="page-item">
										<a class="page-link" @click="pag.page = 1">First</a>
									</li> -->
								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a class="page-link three-dots" @click="pag.page = p">
										{{ p }}
									</a>
								</li>
								<!-- <li class="page-item">
										<a class="page-link" @click="pag.page = pag.pages">Last</a>
									</li>
								-->
							</ul>
						</nav>
					</div>
				</div>
			</div>

			<!-- Venue booking(s) decline  -->

			<div class="modal fade" id="decline-bookings-modal" aria-hidden="true">
				<div class="modal-dialog">
					<form @submit.prevent="declineBookings">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">Decline bookings</h5>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>

							<div class="modal-body">
								<p v-if="selectedBookingsPending.length === selectedBookingsIds.length">
									Bulk decline <strong>{{ selectedBookingsPending.length }}</strong> selected
									bookings.
								</p>
								<p v-else>
									From <strong>{{ selectedBookingsIds.length }}</strong> selected bookings, only
									<strong>{{ selectedBookingsPending.length }}</strong> with status "Pending" will be
									declined.
								</p>

								<div class="form-group mb-3">
									<label class="form-label" for="bookings-decline-message">Rejection message</label>

									<textarea
										class="form-control"
										id="bookings-decline-message"
										v-model="review_message"
										required
										placeholder="Type a nice message"
									></textarea>
								</div>

								<p class="mb-2">
									Each guest will receive a notification with the rejection message and a full refund
									of their payment.
								</p>
							</div>
							<div class="modal-footer">
								<button class="btn btn-outline-danger">
									Decline bookings
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.select-date {
	max-width: 50%;
}
</style>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { Modal } from 'bootstrap'
import {
	format,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	startOfYear,
	startOfQuarter,
	lastDayOfQuarter,
	subDays,
	nextMonday,
	addDays,
	addMonths,
	addYears,
	lastDayOfMonth,
	subMonths,
} from 'date-fns'

import heyGovApi from '@/api.js'
import { setting } from '@/utils.js'

import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'
import PersonLink from '@/components/PersonLink.vue'
import PeopleSearch from '@/components/PeopleSearch.vue'

export default {
	name: 'VenueBookings',
	components: { PersonLink, VenueBookingStatus, PeopleSearch, DatePicker },
	data() {
		return {
			states: {
				bookings: 'idle',
				venues: 'idle',
			},
			venues: [],
			bookings: [],
			selectedBookingsIds: [],

			filters: {
				venue_id: this.$route.query.venue_id || 'group-null',
				status: this.$route.query.status || '',
				payment: this.$route.query.payment || '',
				department_id: this.$route.query.department_id || '',
				person_id: this.$route.query.person_id || '',
				date_start: this.$route.query.date_start || '',
				date_end: this.$route.query.date_end || '',
				booking_start: this.$route.query.booking_start || '',
				booking_end: this.$route.query.booking_end || '',
			},
			sorting: {
				orderBy: localStorage.getItem('venue-bookings-orderBy') || 'starts_at',
				order: localStorage.getItem('venue-bookings-order') || 'desc',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				perPage: localStorage.getItem('venue-bookings-per-page') || 10,
			},
			venueBookingsPaymentStatuses: {
				succeeded: {
					name: 'Successful',
					class: 'bg-success-lighter text-success',
				},
				requires_capture: {
					name: 'Authorized',
					class: 'bg-warning-lighter text-warning',
				},
				requires_payment_method: {
					name: 'Waiting',
					class: 'bg-light text-gray',
				},
				// Pending status & class
				pending: {
					name: 'Waiting',
					class: 'bg-light text-gray',
				},
				refunded: {
					name: 'Refunded',
					class: 'bg-info-lighter text-info',
				},
				refunded_partial: {
					name: 'Partial refund',
					class: 'bg-info-lighter text-info',
				},
				canceled: {
					name: 'Canceled',
					class: 'bg-danger-lighter text-danger',
				},
				expired: {
					name: 'Expired',
					class: 'bg-danger-lighter text-danger',
				},
			},
			dateType: 'created_at',
			dateFormatter: {
				stringify(date) {
					const currentYear = new Date().getFullYear()

					if (currentYear === date.getFullYear()) {
						return date.toLocaleDateString(undefined, {
							month: 'short',
							day: 'numeric',
						})
					} else {
						return date.toLocaleDateString()
					}
				},
			},

			dateRange: [],
			tableColumns: [
				'person_id',
				'starts_at',
				'venue_id',
				'status',
				'price',
				//'deposit',
				'payment_status',
				//'deposit_status',
				'booked_at',
			],

			review_message: null,
		}
	},
	mounted() {
		this.$modalDeclineBookings = new Modal(document.getElementById('decline-bookings-modal'))
	},
	computed: {
		...mapState(['j', 'apiUrl', 'venueGroups']),
		...mapGetters(['activeDepartments', 'auth', 'isStaff']),
		selectedBookings() {
			return this.bookings.filter(booking => this.selectedBookingsIds.includes(booking.id))
		},
		selectedBookingsPending() {
			return this.bookings.filter(
				booking => this.selectedBookingsIds.includes(booking.id) && booking.status === 'pending'
			)
		},
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		exportUrl() {
			const url = new URL(`${this.apiUrl + this.j.slug}/venue-bookings/export`)
			url.searchParams.set('format', 'csv')
			url.searchParams.set('exported_by', this.auth.id)

			for (const filter in this.filters) {
				if (this.filters[filter]) {
					url.searchParams.set(filter, this.filters[filter])
				}
			}

			for (const sort in this.sorting) {
				if (this.sorting[sort]) {
					url.searchParams.set(sort, this.sorting[sort])
				}
			}

			return url
		},
	},
	created() {
		this.loadBookings()
		this.loadVenues()
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getVenueGroups')
		this.preloadDates()

		setting('venue-bookings-table-columns').then(columns => {
			if (columns) {
				this.tableColumns = columns
			}
		})
	},
	methods: {
		setting,

		loadVenues() {
			this.states.venues = 'loading'
			heyGovApi.get(`/${this.j.slug}/venues`).then(
				({ data }) => {
					this.venues.push(...data)
					this.states.venues = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading venues ~ ${error}`)
				}
			)
		},
		loadBookings() {
			this.states.bookings = 'loading'

			const params = {
				...this.filters,
				...this.sorting,
				perPage: this.pag.perPage,
				page: this.pag.page,
			}

			// check if venue_id filter is actually a group
			if (typeof params.venue_id === 'string' && params.venue_id.startsWith('group-')) {
				params.venue_group_id = params.venue_id.replace('group-', '')
				delete params.venue_id
			}

			heyGovApi.get(`/${this.j.slug}/venue-bookings`, { params }).then(
				({ data, headers }) => {
					this.pag.total = Number(headers['x-total'])
					this.pag.pages = Math.ceil(this.pag.total / this.pag.perPage)

					this.bookings = data

					this.states.bookings = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading bookings ~ ${error}`)
				}
			)
			this.updatePageUrl()
		},
		updatePageUrl() {
			let query = {}
			for (const filter in this.filters) {
				if (
					this.filters[filter] &&
					['date_start', 'date_end', 'booking_start', 'booking_end'].includes(filter)
				) {
					if (this.filters[filter] instanceof Date) {
						query[filter] = format(this.filters[filter], 'yyyy-MM-dd')
					} else if (typeof this.filters[filter] === 'string') {
						query[filter] = this.filters[filter]
					}
				} else if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}
			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}
			if (this.pag.perPage) {
				query.perPage = this.pag.perPage
			}

			this.$router.replace({ path: `/${this.j.slug}/venues/bookings`, query }).catch(() => {})
		},
		setPersonFilter(person) {
			if (person) {
				this.filters.person_id = person.id
			}
		},
		clearPersonFilter(noPerson) {
			if (noPerson === true) {
				this.filters.person_id = ''
			}
		},
		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},
		selectAll($event) {
			if ($event.target.checked) {
				this.selectedBookingsIds = this.bookings.map(b => b.id)
			} else {
				this.selectedBookingsIds = []
			}
		},
		deleteBookings() {
			if (confirm('For sure to delete so many bookings?')) {
				this.selectedBookings.forEach(booking => {
					this.deleteBooking(booking)
				})
				this.selectedBookingsIds = []
			}
		},
		deleteBooking(booking) {
			heyGovApi.delete(`/${this.j.slug}/venue-bookings/${booking.id}`).then(
				() => {
					this.bookings = this.bookings.filter(b => b.id !== booking.id)

					Vue.toasted.show(`Booking "${booking.uuid}" is deleted`)
				},
				error => {
					Vue.toasted.error(`Error deleting booking ~ ${error}`)
				}
			)
		},
		declineBookings() {
			this.selectedBookingsPending.forEach(booking => {
				this.declineBooking(booking)
			})

			this.selectedBookingsIds = []
			this.review_message = null
			this.$modalDeclineBookings.hide()
		},
		declineBooking(booking) {
			heyGovApi
				.post(`/${this.j.slug}/venue-bookings/${booking.uuid}/decline`, {
					message: this.review_message,
				})
				.then(
					() => {
						booking.status = 'declined'
						Vue.toasted.show(`Booking ${booking.uuid} is declined`)
					},
					error => {
						Vue.toasted.error(`Error declining ${booking.uuid} (${error.message})`)
					}
				)
		},

		setDateFilters() {
			const hasDateRange = this.dateRange.length && this.dateRange[0]

			if (hasDateRange && this.dateType === 'created_at') {
				this.filters.date_start = this.dateRange[0]
				this.filters.date_end = this.dateRange[1]
				this.filters.booking_start = null
				this.filters.booking_end = null
			} else if (hasDateRange && this.dateType === 'starts_at') {
				this.filters.date_start = null
				this.filters.date_end = null
				this.filters.booking_start = this.dateRange[0]
				this.filters.booking_end = this.dateRange[1]
			} else {
				this.filters.date_start = null
				this.filters.date_end = null
				this.filters.booking_start = null
				this.filters.booking_end = null
			}
		},
		preloadDates() {
			if (this.$route.query.date_start && this.$route.query.date_end) {
				this.dateRange = [new Date(this.$route.query.date_start), new Date(this.$route.query.date_end)]
			} else if (this.$route.query.booking_start && this.$route.query.booking_end) {
				this.dateRange = [new Date(this.$route.query.booking_start), new Date(this.$route.query.booking_end)]
				this.dateType = 'starts_at'
			}
		},
		getDay() {
			let d = new Date()
			let day = d.getDay()
			this.filters.days.days = day
			return day
		},
		getDaysInCurrentWeek() {
			let startDay = startOfWeek(new Date())
			let endDay = new Date()
			return [startDay, endDay]
		},
		getDaysInCurrentMonth() {
			let startDay = startOfMonth(new Date())
			let endDay = new Date()
			return [startDay, endDay]
		},
		getDaysInCurrentYear() {
			let startDay = startOfYear(new Date())
			let endDay = new Date()
			this.filters.date_start = startDay
			this.filters.date_end = endDay
			return [startDay, endDay]
		},
		getDaysInCurrentQuarter() {
			let startDay = startOfQuarter(new Date())
			let endDay = lastDayOfQuarter(new Date())
			this.filters.date_start = startDay
			this.filters.date_end = endDay
			return [startDay, endDay]
		},
		getLastWeek() {
			let lastMonday = startOfWeek(new Date())
			lastMonday = subDays(lastMonday, 6)
			let lastSunday = endOfWeek(new Date())
			lastSunday = subDays(lastSunday, 6)
			this.filters.date_start = lastMonday
			this.filters.date_end = lastSunday
			return [lastMonday, lastSunday]
		},
		getNextWeek() {
			let nextMonday1 = nextMonday(new Date())
			let nextSunday = addDays(nextMonday1, 6)
			this.filters.booking_start = nextMonday1
			this.filters.booking_end = nextSunday
			return [nextMonday1, nextSunday]
		},
		getLastMonth() {
			let month = startOfMonth(new Date())
			let previousMonth = subMonths(new Date(month), 1)
			let firstDay = startOfMonth(new Date(previousMonth))
			let lastDay = lastDayOfMonth(new Date(previousMonth))
			this.filters.booking_start = firstDay
			this.filters.booking_end = lastDay
			return [firstDay, lastDay]
		},
		getNextMonth() {
			let month = startOfMonth(new Date())
			let nextMonth = addMonths(new Date(month), 1)
			let firstDay = startOfMonth(new Date(nextMonth))
			let lastDay = lastDayOfMonth(new Date(nextMonth))
			this.filters.booking_start = firstDay
			this.filters.booking_end = lastDay
			return [firstDay, lastDay]
		},
		getLastQuarter() {
			let date = new Date(),
				y = date.getFullYear(),
				m = date.getMonth()
			let start = Math.floor(m / 3) * 2 + 1
			let end = start + 2
			let startDate = new Date(start + '-01-' + y)
			this.filters.date_start = startDate
			let endDate = new Date(y, end, 0)
			this.filters.date_end = endDate
			return [startDate, endDate]
		},
		getNextQuarter() {
			let date = new Date(),
				y = date.getFullYear(),
				m = date.getMonth()
			let start = Math.floor(m / 3) * 2 + 6
			let end = start + 2
			let startDate = new Date(start + '-01-' + y)
			this.filters.booking_start = startDate
			let endDate = new Date(y, end, 0)
			this.filters.booking_end = endDate
			return [startDate, endDate]
		},
		getLastYear() {
			let currentDate = new Date()
			let theFirst = new Date(currentDate.getFullYear() - 1, 0, 1)
			this.filters.date_start = theFirst
			let theLast = new Date(currentDate.getFullYear() - 1, 11, 31)
			this.filters.date_end = theLast
			return [theFirst, theLast]
		},
		getNextYear() {
			let currentDate = new Date()
			let nextYear = addYears(new Date(currentDate), 2)
			let theFirst = new Date(nextYear.getFullYear() - 1, 0, 1)
			this.filters.booking_start = theFirst
			let theLast = new Date(nextYear.getFullYear() - 1, 11, 31)
			this.filters.booking_end = theLast
			return [theFirst, theLast]
		},
		getDPThisWeek(emit) {
			let date = this.getDaysInCurrentWeek()
			emit(date)
		},
		getDPThisMonth(emit) {
			let date = this.getDaysInCurrentMonth()
			emit(date)
		},
		getDPThisQuarter(emit) {
			let date = this.getDaysInCurrentQuarter()
			emit(date)
		},
		getDPThisYear(emit) {
			let date = this.getDaysInCurrentYear()
			emit(date)
		},
		getDPLastWeek(emit) {
			let date = this.getLastWeek()
			emit(date)
		},
		getDPNextWeek(emit) {
			let date = this.getNextWeek()
			emit(date)
		},
		getDPLastMonth(emit) {
			let date = this.getLastMonth()
			emit(date)
		},
		getDPNextMonth(emit) {
			let date = this.getNextMonth()
			emit(date)
		},
		getDPLastQuarter(emit) {
			let date = this.getLastQuarter()
			emit(date)
		},
		getDPNextQuarter(emit) {
			let date = this.getNextQuarter()
			emit(date)
		},
		getDPLastYear(emit) {
			let date = this.getLastYear()
			emit(date)
		},
		getDPNextYear(emit) {
			let date = this.getNextYear()
			emit(date)
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.bookings = []
				this.loadBookings()
			},
		},
		sorting: {
			deep: true,
			handler() {
				this.bookings = []
				this.loadBookings()
				localStorage.setItem('venue-bookings-orderBy', this.sorting.orderBy)
				localStorage.setItem('venue-bookings-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.bookings = []
			this.loadBookings()
		},
		'pag.perPage'() {
			this.bookings = []
			this.loadBookings()
			localStorage.setItem('venue-bookings-per-page', this.pag.perPage)
		},
		dateType() {
			this.setDateFilters()
		},
		tableColumns() {
			// saves table columns preferences to local storage
			setting('venue-bookings-table-columns', this.tableColumns)
		},
	},
}
</script>
