<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title my-0">Change Speaker for Line</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="modal"
				aria-label="Close"
				@click="$emit('close')"
			/>
		</div>
		<div class="modal-body">
			<div class="form-group">
				<small id="speakerHelp" class="form-text text-muted">
					Who said this?
					<br />
					<br />
					<blockquote style="font-style: italic;">"{{ line.text }}"</blockquote>
				</small>

				<button
					class="btn dropdown-toggle"
					type="button"
					id="dropdown"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<span v-if="selectedSpeakerNumber !== null">Speaker {{ selectedSpeakerNumber + 1 }}</span>
					<span v-else>
						Select Speaker
					</span>
				</button>
				<ul class="dropdown-menu" aria-labelledby="dropdown">
					<li v-for="option in optionsCopy" :key="option.number" :value="option">
						<span class="dropdown-item" role="button" @click="selectedSpeakerNumber = option.number">
							Speaker {{ option.number + 1 }}
							<span v-if="option.person">
								: {{ option.person.name }}
								<span v-if="option.person.title" class="me-1 text-primary-400">
									({{ option.person.title }})
								</span>
							</span>
							<small v-if="selectedSpeakerNumber === option.number">✓</small>
						</span>
					</li>
					<li><hr class="dropdown-divider bg-primary-100 mt-0" /></li>
					<li>
						<span class="dropdown-item" role="button" @click="selectedSpeakerNumber = options.length">
							<span>Speaker {{ options.length + 1 }} (New)</span>
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="modal-footer justify-content-between">
			<button class="btn btn-sm" type="button" data-bs-dismiss="modal" @click="$emit('close')">
				Cancel
			</button>
			<button
				class="btn btn-sm btn-primary"
				data-bs-dismiss="modal"
				:disabled="selectedSpeakerNumber === null"
				@click="handleSave"
			>
				Save
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChangeSpeakerForLineModal',
	props: {
		line: {
			type: Object,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selectedSpeakerNumber: this.line.speaker,
			optionsCopy: this.options, // to avoid props mutation
		}
	},
	methods: {
		handleSave() {
			if (this.selectedSpeakerNumber === null) {
				alert('Please select a speaker for this line.')
				return
			}
			this.$emit('save', {
				line: this.line,
				number: this.selectedSpeakerNumber,
				person: this.optionsCopy.find(option => option.number === this.selectedSpeakerNumber)?.person,
			})
		},
	},
}
</script>
