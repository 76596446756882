<template>
	<bubble-menu
		:editor="editor"
		:tippy-options="{ duration: 100, maxWidth: 1024, onHidden: closeAISuggestion }"
		v-if="editor"
	>
		<div class="bubble-menu">
			<div v-if="isAISuggestionVisible" class="suggestion">
				<div v-if="aiSuggestionText" style="width: 100%">
					<div class="suggestion-text" v-html="makeHtml(aiSuggestionText)" v-auto-scroll></div>
					<button :disabled="isStreamingInProgress" class="btn btn-md" @click="applySuggestion">
						Accept
					</button>
					<button :disabled="isStreamingInProgress" class="btn btn-md" @click="closeAISuggestion">
						Discard
					</button>
				</div>
				<div v-else class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
			<div class="d-flex align-items-center" v-else>
				<!-- AI Actions Dropdown -->
				<div class="dropdown">
					<button
						class="btn btn-md dropdown-toggle"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<font-awesome-icon :icon="['fas', 'magic-wand-sparkles']" style="margin-right: 10px" />
						<span>AI Actions</span>
					</button>
					<ul class="dropdown-menu">
						<!-- Make Longer -->
						<button
							class="dropdown-item"
							@click="handleAIActionClick('make_longer')"
							@contextmenu.prevent="$emit('make-longer-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'text-height']" style="margin-right: 10px" />
							<span>Make Longer</span>
						</button>

						<!-- Make Shorter -->
						<button
							class="dropdown-item"
							@click="handleAIActionClick('make_shorter')"
							@contextmenu.prevent="$emit('make-shorter-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'minimize']" style="margin-right: 10px" />
							<span>Make Shorter</span>
						</button>

						<!-- Describe Your Change -->
						<button
							class="dropdown-item"
							@click="handleAIActionClick('describe_your_change')"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'comment']" style="margin-right: 10px" />
							<span>Describe Your Change</span>
						</button>

						<!-- Rewrite in Style -->
						<div class="dropdown-divider"></div>
						<h6 class="dropdown-header" style="margin-bottom: 4px;">Rewrite in Different Style</h6>
						<!-- Official Gov Meeting -->
						<button
							class="dropdown-item"
							@click="
								// @ts-ignore
								handleAIActionClick('describe_your_change', 'Rewrite in style: Official Gov Meeting')
							"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'gavel']" style="margin-right: 10px" />
							<span>Official Gov Meeting</span>
						</button>
						<!-- Business Formal -->
						<button
							class="dropdown-item"
							@click="
								// @ts-ignore
								handleAIActionClick('describe_your_change', 'Rewrite in style: Business Formal')
							"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'briefcase']" style="margin-right: 10px" />
							<span>Business Formal</span>
						</button>
						<!-- Casual Language -->
						<button
							class="dropdown-item"
							@click="
								// @ts-ignore
								handleAIActionClick('describe_your_change', 'Rewrite in style: Casual Language')
							"
							@contextmenu.prevent="$emit('describe-your-change-context-menu')"
						>
							<font-awesome-icon :icon="['fas', 'smile']" style="margin-right: 10px" />
							<span>Casual Language</span>
						</button>
					</ul>
				</div>

				<!-- Text Dropdown -->
				<div class="dropdown">
					<button
						class="btn btn-md dropdown-toggle"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<font-awesome-icon :icon="['fas', 't']" style="margin-right: 10px" />
						<span>Text</span>
					</button>
					<ul class="dropdown-menu">
						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleBlockquote()
									.run()
							"
							:disabled="
								!editor
									.can()
									.chain()
									.focus()
									.toggleBlockquote()
									.run()
							"
							:class="{ active: editor.isActive('blockquote') }"
						>
							<font-awesome-icon :icon="['fas', 'quote-right']" style="margin-right: 10px" />
							<span>Quote</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleStrike()
									.run()
							"
							:disabled="
								!editor
									.can()
									.chain()
									.focus()
									.toggleStrike()
									.run()
							"
							:class="{ active: editor.isActive('strike') }"
						>
							<font-awesome-icon
								:icon="['fas', 'strikethrough']"
								class="icon alt"
								style="margin-right: 10px"
							/>
							<span>Strikethrough</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleBulletList()
									.run()
							"
							:class="{ active: editor.isActive('bulletList') }"
						>
							<font-awesome-icon :icon="['fas', 'list-ul']" class="icon alt" style="margin-right: 10px" />
							<span>Unordered List</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleOrderedList()
									.run()
							"
							:class="{ active: editor.isActive('orderedList') }"
						>
							<font-awesome-icon :icon="['fas', 'list-ol']" class="icon alt" style="margin-right: 10px" />
							<span>Ordered List</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 1 })
									.run()
							"
							:class="{ active: editor.isActive('heading', { level: 1 }) }"
						>
							<span style="margin-right: 10px;">h1</span>
							<span>Header Level 1</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 2 })
									.run()
							"
							:class="{ active: editor.isActive('heading', { level: 2 }) }"
						>
							<span style="margin-right: 10px;">h2</span>
							<span>Header Level 2</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleHeading({ level: 3 })
									.run()
							"
							:class="{ active: editor.isActive('heading', { level: 3 }) }"
						>
							<span style="margin-right: 10px;">h3</span>
							<span>Header Level 3</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.setHorizontalRule()
									.run()
							"
						>
							<font-awesome-icon :icon="['fas', 'ruler-horizontal']" style="margin-right: 10px" />
							<span>Horizontal Rule</span>
						</button>

						<button
							class="dropdown-item"
							@click="
								editor
									.chain()
									.focus()
									.toggleCode()
									.run()
							"
							:disabled="
								!editor
									.can()
									.chain()
									.focus()
									.toggleCode()
									.run()
							"
							:class="{ active: editor.isActive('code') }"
						>
							<font-awesome-icon :icon="['fas', 'code']" class="icon alt" style="margin-right: 10px" />
							<span>Code</span>
						</button>
					</ul>
				</div>

				<!-- undo -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.undo()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.undo()
							.run()
					"
				>
					<font-awesome-icon :icon="['fas', 'undo']" class="icon alt" />
				</button>

				<!-- redo -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.redo()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.redo()
							.run()
					"
				>
					<font-awesome-icon :icon="['fas', 'redo']" class="icon alt" />
				</button>

				<!-- bold -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleBold()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleBold()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('bold') }"
				>
					<font-awesome-icon :icon="['fas', 'bold']" class="icon alt" />
				</button>

				<!-- italic -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleItalic()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleItalic()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('italic') }"
				>
					<font-awesome-icon :icon="['fas', 'italic']" class="icon alt" />
				</button>

				<!-- underline -->
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleUnderline()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleUnderline()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('underline') }"
				>
					<font-awesome-icon :icon="['fas', 'underline']" class="icon alt" />
				</button>

				<!-- link -->
				<button class="btn" @click="$emit('setLink')" :class="{ 'btn-primary': editor.isActive('link') }">
					<font-awesome-icon :icon="['fas', 'link']" />
				</button>
			</div>
		</div>
	</bubble-menu>
</template>

<style lang="scss">
.bubble-menu {
	padding: 10px;
	background-color: #f5f5f5;
	border: 1px solid #dedede;
	border-radius: 12px;
	.btn {
		padding: 5px 10px;
	}
}

.suggestion {
	font-size: 0.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 500px;
}

.suggestion-text {
	width: 100%;
	background-color: white;
	max-height: 200px;
	overflow-y: scroll;
	border: 1px solid #dedede;
	border-radius: 5px;
	padding: 10px;
}
</style>

<script>
import { Converter } from 'showdown'
import { BubbleMenu } from '@tiptap/vue-2'

const converter = new Converter()
converter.setOption('simplifiedAutoLink', true)
converter.setOption('openLinksInNewWindow', true)

export default {
	components: { BubbleMenu },
	props: {
		editor: { type: Object },
		onSetLink: { type: Function },
		onMakeLonger: { type: Function },
	},
	data() {
		return {
			isAISuggestionVisible: false,
			aiSuggestionText: '',
			isStreamingInProgress: false,
		}
	},
	methods: {
		makeHtml(text) {
			return converter.makeHtml(text)
		},
		update(md, done) {
			this.aiSuggestionText += md
			if (done) {
				this.isStreamingInProgress = false
			}
		},
		handleAIActionClick(command, prompt = undefined) {
			if (command === 'describe_your_change' && prompt === undefined) {
				// @ts-ignore
				prompt = window.prompt('Describe your change')
				if (!prompt) {
					return
				}
			}

			const { from, to } = this.editor.view.state.selection
			const selectedText = this.editor.state.doc.textBetween(from, to, ' ')

			this.isAISuggestionVisible = true
			this.isStreamingInProgress = true
			this.aiSuggestionText = ''

			this.$emit('ai-action', {
				command: command,
				prompt: prompt,
				fullText: this.editor.getHTML(),
				selection: selectedText,
				update: this.update,
			})
		},
		closeAISuggestion() {
			this.isAISuggestionVisible = false
			this.aiSuggestionText = ''
		},
		applySuggestion() {
			const { from, to } = this.editor.view.state.selection

			const replacing = this.makeHtml(this.aiSuggestionText)

			this.editor
				.chain()
				.focus()
				.insertContentAt({ from, to }, replacing)
				.run()
		},
	},
}
</script>
