import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// views
import Home from '../views/Home.vue'
import MunicipalitySettings from '../views/MunicipalitySettings.vue'
import MunicipalityBilling from '../views/JurisdictionBilling.vue'
import Departments from '../views/Departments.vue'
import Invited from '../views/Invited.vue'

const Account = () => import(/* webpackChunkName: "account" */ '../views/Account/Account.vue')
import AccountLogin from '../views/AccountLogin.vue'
import AccountJurisdiction from '../views/AccountJurisdiction.vue'
import AccountLogout from '../views/AccountLogout.vue'
import AccountResetPass from '../views/AccountResetPass.vue'
import AccountNewPass from '../views/AccountNewPass.vue'
import Notifications from '../views/Notifications.vue'

import Jurisdiction from '../views/Jurisdiction.vue'
import JurisdictionHome from '../views/JurisdictionHome.vue'
import JurisdictionDashboard from '../views/JurisdictionDashboard.vue'

import Threads from '../views/Threads.vue'
import Thread from '../views/Thread.vue'
import ThreadCreate from '../views/ThreadCreate.vue'

import VirtualClerk from '../views/VirtualAssistant/VirtualClerk.vue'

import Workflows from '../views/Workflows/Workflows.vue'

import Polls from '../views/Polls/Polls.vue'
import Poll from '../views/Polls/Poll.vue'
import PollResponses from '../views/Polls/PollResponses.vue'
import PollSummary from '../views/Polls/PollSummary.vue'

import Forms from '../views/Forms/Forms.vue'
import Form from '../views/Forms/Form.vue'
import FormCreate from '../views/Forms/FormCreate.vue'
import FormUploadSuccess from '../views/Forms/FormUploadSuccess.vue'
import FormEdit from '../views/Forms/FormEdit.vue'
import FormSend from '../views/Forms/FormSend.vue'
import FormRequestReview from '../views/Forms/FormRequestReview.vue'
import FormRequestInfo from '../views/Forms/FormRequestInfo.vue'
import FormSubmissions from '../views/Forms/FormSubmissions.vue'
import FormPdf from '../views/Forms/FormPdf.vue'
import FormBuilder from '../views/Forms/FormBuilder.vue'

import Venues from '../views/Venues/Venues.vue'
import Venue from '../views/Venues/Venue.vue'
import VenueSettings from '../views/Venues/VenueSettings.vue'
import VenueAvailability from '@/views/Venues/VenueAvailability'
import VenueEvents from '@/views/Venues/VenueEvents'
import VenueFieldsPricing from '@/views/Venues/VenueFieldsPricing'
import VenueShare from '@/views/Venues/VenueShare'
import VenueImport from '@/views/Venues/VenueImport'
import VenuePublicPage from '../views/Venues/VenuePublicPage'
import VenueEmbed from '../views/Venues/VenueEmbed'
import VenueBooking from '../views/Venues/VenueBooking'
import VenueBookingCreate from '../views/Venues/VenueBookingCreate'
import VenueBookingLandon from '../views/Venues/VenueBookingLandon'
import VenueBookingInfo from '../views/Venues/VenueBookingInfo'
import VenueBookingReview from '../views/Venues/VenueBookingReview'
import VenuesBookings from '../views/Venues/VenuesBookings'

import VenueGroup from '../views/Venues/Groups/Group'
import VenueGroupOverview from '../views/Venues/Groups/Overview'
import VenueGroupSettings from '../views/Venues/Groups/Settings'
import VenueGroupBook from '../views/Venues/Groups/Book'
import VenueGroupReservations from '../views/Venues/Groups/Reservations'

import Meetings from '../views/Meetings/Index.vue'
import MeetingsCreate from '../views/Meetings/Create.vue'
import Meeting from '../views/Meetings/Meeting.vue'
import MeetingOverview from '../views/Meetings/MeetingOverview.vue'
import MeetingTranscript from '../views/Meetings/MeetingTranscript.vue'
import MeetingMinutes from '../views/Meetings/MeetingMinutes.vue'
import MeetingAgenda from '../views/Meetings/MeetingAgenda.vue'
import MeetingFiles from '../views/Meetings/MeetingFiles.vue'
import MeetingPublicPage from '../views/Meetings/MeetingPublicPage.vue'
import MeetingsTry from '../views/Meetings/Try.vue'
import MeetingsClerkMinutes from '../views/Meetings/Unlock.vue'
import MeetingsAgendaProcessingTester from '../views/Meetings/AgendaProcessingTester.vue'

import People from '../views/People/People.vue'

import Payments from '../views/Payments/Payments.vue'
import PaymentsNewAccount from '../views/Payments/PaymentsNewAccount.vue'
import PaymentsList from '../views/Payments/PaymentsList.vue'
import PaymentsSettings from '../views/Payments/PaymentsSettings.vue'
import PaymentsQuickPaySettings from '../views/Payments/QuickPaySettings.vue'
import PaymentsBillingAccounts from '../views/Payments/UtilityAccounts.vue'
import PaymentsBillingAccountsImport from '../views/Payments/UtilityAccountsImport.vue'
import PaymentsBillingAccountsView from '../views/Payments/UtilityAccountsView.vue'
import PaymentsQuickPay from '../views/Payments/QuickPay.vue'
import PaymentsBillingAccount from '../views/Payments/BillingAccount.vue'
import PaymentPage from '../views/Payments/PaymentPage.vue'
import PaymentsHistory from '../views/Payments/PaymentsHistory.vue'
import PaymentsDetails from '../views/Payments/QuickPayPayment.vue'

import PageDev from '../views/Pages/PageDev.vue'
import PageUiKit from '../views/Pages/PageUiKit.vue'
import PageTests from '../views/Pages/Tests.vue'

import PageUpgrade from '../views/Pages/PageUpgrade.vue'

import Events from '../views/Events/Events.vue'
import EventsList from '../views/Events/List.vue'
import Event from '../views/Events/Event.vue'
import EventCreate from '../views/Events/EventCreate.vue'

const Onboarding = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Welcome.vue')
const OnboardingMunicipality = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Municipality.vue')
const OnboardingBilling = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Billing.vue')
const OnboardingDepartments = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Departments.vue')
const OnboardingCategories = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Categories.vue')
const OnboardingForms = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Forms.vue')
const OnboardingWidget = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Widget.vue')
const OnboardingFinish = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Finish.vue')
const OnboardingHey311 = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/Hey311.vue')
const OnboardingClerkMinutes = () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding/ClerkMinutes.vue')

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'HeyGov',
		},
	},
	{
		path: '/municipality-not-using-heygov',
		name: 'JurisdictionRequest',
		component: () => import(/* webpackChunkName: "pages" */ '../views/JurisdictionRequest.vue'),
		meta: {
			title: 'Get your municipality using HeyGov',
			hideMenu: true,
		},
	},

	{
		path: '/your-account',
		component: Account,
		meta: {
			title: 'Your account',
			requiresAuth: true,
			backTo: '/',
		},
		children: [
			{
				path: '',
				component: () => import(/* webpackChunkName: "account" */ '../views/Account/Overview.vue'),
			},
			{
				path: 'info',
				component: () => import(/* webpackChunkName: "account" */ '../views/Account/Info.vue'),
			},
			{
				path: 'login-options',
				component: () => import(/* webpackChunkName: "account" */ '../views/Account/LoginOptions.vue'),
			},
			{
				path: 'privacy-security',
				component: () => import(/* webpackChunkName: "account" */ '../views/Account/PrivacySecurity.vue'),
			},
			{
				path: 'notifications',
				component: () => import(/* webpackChunkName: "account" */ '../views/Account/Notifications.vue'),
			},
			{
				path: 'debug',
				component: () => import(/* webpackChunkName: "account" */ '../views/Account/Debug.vue'),
			},
		],
	},
	{
		path: '/account',
		redirect: '/your-account',
	},
	{
		path: '/account/login',
		name: 'Login',
		component: AccountLogin,
		meta: {
			title: 'Login',
			hideMenu: true,
			backTo: '/',
		},
	},
	{
		path: '/account/logged-out',
		name: 'Logout',
		component: AccountLogout,
		meta: {
			title: 'Logged out',
			hideMenu: true,
			backTo: '/',
		},
	},
	{
		path: '/account/reset-pass',
		name: 'ResetPass',
		component: AccountResetPass,
		meta: {
			title: 'Reset your password',
			hideMenu: true,
			backTo: '/account/login',
		},
	},
	{
		path: '/account/new-pass/:token',
		name: 'AccountCreatePass',
		component: AccountNewPass,
		meta: {
			title: 'Create a new password',
			hideMenu: true,
			backTo: '/',
		},
	},
	{
		path: '/notifications',
		name: 'Notifications',
		component: Notifications,
		meta: {
			title: 'All your notifications',
			requiresAuth: true,
			backTo: '/',
		},
	},

	{
		path: '/threads',
		name: 'Threads',
		component: Threads,
		meta: {
			requiresAuth: true,
			backTo: '/',
		},
	},
	{
		path: '/threads/:threadId',
		name: 'Thread',
		component: Thread,
		meta: {
			requiresAuth: true,
			hideMenu: true,
			backTo: `/threads`,
		},
	},

	{
		path: '/reports',
		name: 'Reports',
		component: PageDev,
	},

	{
		path: '/municipality-settings',
		name: 'Your municipality',
		component: MunicipalitySettings,
		meta: {
			requiresAuth: true,
			hideMenu: true,
			backTo: `/`,
		},
	},
	{
		path: '/departments',
		name: 'Departments & People',
		component: Departments,
		meta: {
			requiresAuth: true,
			hideMenu: true,
			backTo: `/`,
		},
	},

	{
		path: '/heygov-admin',
		component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Admin.vue'),
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: '',
				name: 'AdminDashboard',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Dashboard.vue'),
			},
			{
				path: 'big-stats',
				name: 'AdminDashboardBig',
				meta: {
					embed: true,
				},
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Big.vue'),
			},
			{
				path: 'jurisdictions',
				name: 'AdminJurisdictions',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Jurisdictions.vue'),
			},
			{
				path: 'jurisdiction/:jSlug',
				name: 'AdminJurisdictionsEdit',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/JurisdictionEdit.vue'),
			},
			{
				path: 'create-jurisdiction',
				name: 'AdminJurisdictionsCreate',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/JurisdictionCreate.vue'),
			},
			{
				path: 'issues-list',
				name: 'AdminIssues',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Issues.vue'),
			},
			{
				path: 'people',
				name: 'AdminPeople',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/People.vue'),
			},
			{
				path: 'payments-list',
				name: 'AdminPayments',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Payments.vue'),
			},
			{
				path: 'bookings-list',
				name: 'AdminBookings',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Bookings.vue'),
			},
			{
				path: 'forms',
				name: 'AdminForms',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Forms.vue'),
			},
			{
				path: 'form-requests-list',
				name: 'AdminFormRequests',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/FormRequests.vue'),
			},
			{
				path: 'workflows',
				name: 'AdminWorkflows',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Workflows.vue'),
			},
			{
				path: 'workflows/:id',
				name: 'AdminWorkflowsEdit',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/WorkflowsEdit.vue'),
			},

			{
				path: 'people/:id',
				component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Person.vue'),
				children: [
					{
						path: '',
						name: 'AdminPerson',
						component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/PersonGeneral.vue'),
					},
					{
						path: 'jurisdictions',
						name: 'AdminPersonJurisdictions',
						component: () =>
							import(/* webpackChunkName: "admin" */ '../views/Admin/PersonJurisdictions.vue'),
					},

					{
						path: 'issues',
						name: 'AdminPersonIssues',
						component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/PersonIssues.vue'),
					},
					{
						path: 'form-requests',
						name: 'AdminPersonFormRequests',
						component: () =>
							import(/* webpackChunkName: "admin" */ '../views/Admin/PersonFormRequests.vue'),
					},
					{
						path: 'venue-bookings',
						name: 'AdminPersonBookings',
						component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/PersonBookings.vue'),
					},
					{
						path: 'payments',
						name: 'AdminPersonPayments',
						component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/PersonPayments.vue'),
					},
					{
						path: 'edit',
						name: 'AdminPersonUpdate',
						component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/PersonUpdate.vue'),
						meta: {
							backTo: 'people/:id/',
						},
					},
					{
						path: 'password',
						name: 'AdminPersonPassword',
						component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/PersonPassword.vue'),
						meta: {
							backTo: 'people/:id/',
						},
					},
				],
			},
		],
	},
	{
		path: '/not-found',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "pages" */ '../views/Pages/PageNotFound.vue'),
	},
	{
		path: '/about-heygov',
		name: 'About',
		component: () => import(/* webpackChunkName: "pages" */ '../views/About.vue'),
		meta: {
			backTo: '/',
		},
	},
	{
		path: '/dev/debug',
		name: 'DevDebug',
		component: () => import(/* webpackChunkName: "dev" */ '../views/DevDebug.vue'),
		meta: {
			backTo: '/',
		},
	},
	{
		path: '/heygov-status',
		name: 'Status',
		component: () => import(/* webpackChunkName: "dev" */ '../views/Pages/Status.vue'),
		meta: {
			backTo: '/',
		},
	},
	{
		path: '/dev/ui-kit',
		name: 'UiKit',
		component: PageUiKit,
	},
	{
		path: '/dev/tests',
		name: 'Tests',
		component: PageTests,
	},
	{
		path: '/upgrade',
		name: 'PageUpgrade',
		component: PageUpgrade,
	},

	{
		path: '/select-municipality',
		name: 'AccountJurisdiction',
		component: AccountJurisdiction,
		meta: {
			title: 'Select municipality',
			backTo: `/`,
		},
	},

	{
		path: '/create-clerkminutes-account',
		name: 'OnboardingClerkMinutes',
		component: OnboardingClerkMinutes,
		meta: {
			sidebar: false,
		},
	},

	{
		path: '/register-for-hey311',
		name: 'OnboardingHey311',
		component: OnboardingHey311,
		meta: {
			sidebar: false,
		},
	},

	{
		path: '/:jurisdiction([0-9a-z.-]+)',
		component: Jurisdiction,
		children: [
			{
				path: '',
				component: JurisdictionHome,
			},
			{
				path: 'dashboard',
				name: 'MunicipalityDashboard',
				component: JurisdictionDashboard,
				meta: {
					hideMenu: false,
				},
			},
			{
				path: 'settings',
				name: 'MunicipalitySettings',
				component: MunicipalitySettings,
				meta: {
					hideMenu: false,
				},
			},
			{
				path: 'billing',
				name: 'MunicipalityBilling',
				component: MunicipalityBilling,
				meta: {
					hideMenu: false,
				},
			},
			{
				path: 'invited',
				name: 'HeyGov Invited',
				component: Invited,
				meta: {
					requiresAuth: true,
					backTo: `/`,
				},
			},
			{
				path: 'threads',
				name: 'Issues',
				component: Threads,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'threads/create',
				name: 'ReportIssue',
				component: ThreadCreate,
				meta: {
					hideMenu: true,
					backTo: `/_/threads`,
				},
			},
			{
				path: 'threads/:threadId',
				name: 'Issue Details',
				component: Thread,
				meta: {
					hideMenu: true,
					backTo: `/_/threads`,
				},
			},

			{
				path: 'virtual-clerk',
				name: 'VirtualClerk',
				component: VirtualClerk,
				meta: {
					hideMenu: true,
					backTo: `/`,
				},
			},
			{
				path: 'virtual-assistant',
				name: 'VirtualClerk',
				component: VirtualClerk,
				meta: {
					hideMenu: true,
					backTo: `/`,
				},
			},
			{
				path: 'zapier',
				name: 'Workflows',
				component: Workflows,
				meta: {
					hideMenu: true,
					backTo: `/`,
				},
			},
			{
				path: 'forms',
				name: 'Forms',
				component: Forms,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'forms/create',
				name: 'FormCreate',
				component: FormCreate,
				meta: {
					backTo: `/_/forms`,
				},
			},
			{
				path: 'forms/:formSlug',
				name: 'Form',
				component: Form,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'forms/:formSlug/success',
				name: 'FormUploadSuccess',
				component: FormUploadSuccess,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'forms/:formSlug/editForm',
				name: 'FormEdit',
				component: FormEdit,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'forms/:formSlug/pdfPreview',
				name: 'FormPdf',
				component: FormPdf,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'forms/:formSlug/formBuilder',
				name: 'FormBuilder',
				component: FormBuilder,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'form-builder',
				name: 'Form Builder',
				component: FormBuilder,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'forms/:formSlug/send/:step?',
				name: 'FormSend',
				component: FormSend,
				meta: {
					hideMenu: true,
					backTo: true,
				},
			},
			{
				path: 'forms/:formSlug/request-review/:formRequestId',
				name: 'FormRequestReview',
				component: FormRequestReview,
				meta: {
					hideMenu: true,
					backTo: true,
				},
			},
			{
				path: 'forms/:formSlug/request-info/:formRequestId',
				name: 'FormRequestInfo',
				component: FormRequestInfo,
				meta: {
					hideMenu: true,
					backTo: true,
				},
			},
			{
				path: 'form-requests',
				name: 'FormSubmissions',
				component: FormSubmissions,
				meta: {
					hideMenu: true,
					backTo: `/_/forms`,
				},
			},
			{
				path: 'polls',
				name: 'StrawPolls',
				component: Polls,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'polls/:pollSlug',
				name: 'Poll',
				component: Poll,
				meta: {
					hideMenu: true,
					backTo: `/_/polls`,
				},
			},
			{
				path: 'polls/:pollSlug/responses',
				name: 'PollResponses',
				component: PollResponses,
				meta: {
					hideMenu: true,
					backTo: `/_/polls`,
				},
			},
			{
				path: 'polls/:pollSlug/summary',
				name: 'PollSummary',
				component: PollSummary,
				meta: {
					hideMenu: true,
					backTo: `/_/polls`,
				},
			},
			{
				path: 'people',
				name: 'People',
				component: People,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'payments/quickpay-settings',
				name: 'PaymentsQuickPaySettings',
				component: PaymentsQuickPaySettings,
				meta: {
					backToRoute: 'Payments',
					backTo: `/`,
				},
			},
			{
				path: 'payments/connect-department',
				name: 'PaymentsNewAccount',
				component: PaymentsNewAccount,
			},
			{
				path: 'payments/:departmentId?',
				name: 'Payments',
				component: Payments,
			},
			{
				path: 'payments/:departmentId/payments',
				name: 'PaymentsList',
				component: PaymentsList,
			},
			{
				path: 'payments/:departmentId/billing-accounts',
				name: 'PaymentsBillingAccounts',
				component: PaymentsBillingAccounts,
				meta: {
					backToRoute: 'Payments',
					backTo: `/`,
				},
			},
			{
				path: 'payments/:departmentId/billing-accounts/import',
				name: 'PaymentsBillingAccountsImport',
				component: PaymentsBillingAccountsImport,
				meta: {
					backToRoute: 'Payments',
					backTo: `/`,
				},
			},
			{
				path: 'payments/:departmentId/billing-accounts/:accountId',
				name: 'PaymentsBillingAccountsView',
				component: PaymentsBillingAccountsView,
				meta: {
					backToRoute: 'Payments',
					backTo: `/`,
				},
			},
			{
				path: 'payments/:departmentId/settings',
				name: 'PaymentsSettings',
				component: PaymentsSettings,
			},
			{
				path: 'quick-pay',
				name: 'PaymentsQuickPay',
				component: PaymentsQuickPay,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'quick-pay/pay',
				name: 'PaymentsStartPayment',
				component: PaymentPage,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'quick-pay/billing-account/:accountUuid',
				name: 'QuickPayAccount',
				component: PaymentsBillingAccount,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'quick-pay/payments',
				name: 'QuickPayPaymentsHistory',
				component: PaymentsHistory,
				meta: {
					backTo: `/_/quick-pay`,
				},
			},
			{
				path: 'quick-pay/payments/:paymentUuid',
				name: 'QuickPayPaymentsPage',
				component: PaymentsDetails,
				meta: {
					backTo: `/_/quick-pay/payments`,
				},
			},
			{
				path: 'onboarding/:token',
				name: 'Onboarding',
				component: Onboarding,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/municipality',
				name: 'OnboardingMunicipality',
				component: OnboardingMunicipality,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/billing',
				name: 'OnboardingBilling',
				component: OnboardingBilling,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/departments',
				name: 'OnboardingDepartments',
				component: OnboardingDepartments,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/categories',
				name: 'OnboardingCategories',
				component: OnboardingCategories,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/forms',
				name: 'OnboardingForms',
				component: OnboardingForms,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/widget',
				name: 'OnboardingWidget',
				component: OnboardingWidget,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'onboarding/:token/finish',
				name: 'OnboardingFinish',
				component: OnboardingFinish,
				meta: {
					sidebar: false,
					backTo: `/`,
				},
			},
			{
				path: 'meetings',
				name: 'Meetings',
				component: Meetings,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'meetings/create',
				name: 'MeetingCreate',
				component: MeetingsCreate,
				meta: {
					backTo: `/meetings`,
				},
			},
			{
				path: 'meetings/try',
				name: 'MeetingsTry',
				component: MeetingsTry,
				meta: {
					backTo: `/meetings`,
					embed: true,
				},
			},
			{
				path: 'meetings/clerkminutes',
				name: 'MeetingsClerkMinutes',
				component: MeetingsClerkMinutes,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'meetings/agenda-processing-tester',
				name: 'MeetingsAgendaProcessingTester',
				component: MeetingsAgendaProcessingTester,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'meetings/:meetingId',
				component: Meeting,
				meta: {
					backTo: `/`,
				},
				children: [
					{
						path: '',
						name: 'Meeting',
						component: MeetingOverview,
					},
					{
						path: 'info',
						name: 'MeetingPublicPage',
						component: MeetingPublicPage,
					},
					{
						path: 'agenda',
						name: 'MeetingAgenda',
						component: MeetingAgenda,
					},
					{
						path: 'files',
						name: 'MeetingFiles',
						component: MeetingFiles,
					},
					{
						path: 'minutes',
						name: 'MeetingMinutes',
						component: MeetingMinutes,
					},
					{
						path: 'transcript',
						name: 'MeetingTranscript',
						component: MeetingTranscript,
					},
				],
			},
			{
				path: 'venues',
				name: 'Venues',
				component: Venues,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'venues/group/:group',
				component: VenueGroup,
				meta: {
					backTo: `/venues`,
				},
				children: [
					{
						path: '',
						name: 'VenueGroup',
						component: VenueGroupOverview,
					},
					{
						path: 'settings',
						name: 'VenueGroupSettings',
						component: VenueGroupSettings,
					},
					{
						path: 'book',
						name: 'VenueGroupBook',
						component: VenueGroupBook,
					},
					{
						path: 'reservations',
						name: 'VenueGroupReservations',
						component: VenueGroupReservations,
					},
				],
			},
			{
				path: 'venues/bookings',
				name: 'VenuesBookings',
				component: VenuesBookings,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug',
				name: 'Venue',
				component: Venue,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/settings',
				name: 'VenueSettings',
				component: VenueSettings,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/availability',
				name: 'VenueAvailability',
				component: VenueAvailability,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/blocked-dates',
				name: 'VenueEvents',
				component: VenueEvents,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/fields-pricing',
				name: 'VenueFieldsPricing',
				component: VenueFieldsPricing,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/share',
				name: 'VenueShare',
				component: VenueShare,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/import',
				name: 'VenueImport',
				component: VenueImport,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/info/:tab?',
				name: 'VenueInfo',
				component: VenuePublicPage,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/embed/:tab?',
				name: 'VenueEmbed',
				component: VenueEmbed,
				meta: {
					backTo: `/venues`,
					embed: true,
				},
			},
			{
				path: 'venues/:venueSlug/booking/:date/:slot',
				name: 'VenueBooking',
				component: VenueBooking,
				meta: {
					backTo: `VenueInfo`,
				},
			},
			{
				path: 'venues/:venueSlug/create-booking/:date?',
				name: 'VenueBookingCreate',
				component: VenueBookingCreate,
				meta: {
					backTo: `VenueAvailability`,
				},
			},
			{
				path: 'venues/:venueSlug/booking-admin/:date/:slot',
				name: 'VenueBookingLandon',
				component: VenueBookingLandon,
				meta: {
					backTo: `VenueAvailability`,
				},
			},
			{
				path: 'venues/:venueSlug/bookings/:bookingUuid',
				name: 'VenueBookingReview',
				component: VenueBookingReview,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'venues/:venueSlug/bookings-info/:bookingUuid',
				name: 'VenueBookingInfo',
				component: VenueBookingInfo,
				meta: {
					backTo: `/venues`,
				},
			},
			{
				path: 'events',
				name: 'Events',
				component: EventsList,
				meta: {
					backTo: `/`,
				},
			},
			{
				path: 'events-embed',
				name: 'EventsEmbed',
				component: Events,
				meta: {
					backTo: `/`,
					embed: true,
				},
			},
			{
				path: 'events-embed/:id',
				name: 'EventEmbed',
				component: Event,
				meta: {
					backTo: `/events-embed`,
					embed: true,
				},
			},
			{
				path: 'events/create',
				name: 'EventCreate',
				component: EventCreate,
				meta: {
					backTo: `/`,
				},
			},
		],
	},
	{
		path: '*',
		name: '404',
		redirect: '/not-found',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	//next();

	// check for auth
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// if not, redirect to login page.
		if (!store.getters.auth) {
			next({
				path: '/account/login',
				query: { redirect: to.fullPath },
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
