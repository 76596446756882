<template>
	<div>
		<div class="editor-toolbar" v-if="editor">
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleBold()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.toggleBold()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('bold') }"
			>
				<font-awesome-icon :icon="['fas', 'bold']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleItalic()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.toggleItalic()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('italic') }"
			>
				<font-awesome-icon :icon="['fas', 'italic']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleUnderline()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.toggleUnderline()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('underline') }"
			>
				<font-awesome-icon :icon="['fas', 'underline']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleBlockquote()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.toggleBlockquote()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('blockquote') }"
			>
				<font-awesome-icon :icon="['fas', 'quote-right']" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleStrike()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.toggleStrike()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('strike') }"
			>
				<font-awesome-icon :icon="['fas', 'strikethrough']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleBulletList()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('bulletList') }"
			>
				<font-awesome-icon :icon="['fas', 'list-ul']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleOrderedList()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('orderedList') }"
			>
				<font-awesome-icon :icon="['fas', 'list-ol']" class="icon alt" />
			</button>
			<button class="btn" @click="setLink" :class="{ 'btn-primary': editor.isActive('link') }">
				<font-awesome-icon :icon="['fas', 'link']" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleHeading({ level: 1 })
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('heading', { level: 1 }) }"
			>
				h1
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleHeading({ level: 2 })
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('heading', { level: 2 }) }"
			>
				h2
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleHeading({ level: 3 })
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('heading', { level: 3 }) }"
			>
				h3
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.setHorizontalRule()
						.run()
				"
			>
				<font-awesome-icon :icon="['fas', 'ruler-horizontal']" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.toggleCode()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.toggleCode()
						.run()
				"
				:class="{ 'btn-primary': editor.isActive('code') }"
			>
				<font-awesome-icon :icon="['fas', 'code']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.undo()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.undo()
						.run()
				"
			>
				<font-awesome-icon :icon="['fas', 'undo']" class="icon alt" />
			</button>
			<button
				class="btn"
				@click="
					editor
						.chain()
						.focus()
						.redo()
						.run()
				"
				:disabled="
					!editor
						.can()
						.chain()
						.focus()
						.redo()
						.run()
				"
			>
				<font-awesome-icon :icon="['fas', 'redo']" class="icon alt" />
			</button>
		</div>
		<my-bubble-menu
			ref="bubble-menu"
			:editor="editor"
			:onSetLink="setLink"
			:onDiscard="forceCloseBubbleMenu"
			@ai-action="handleAIAction"
			@make-longer-context-menu="$emit('make-longer-context-menu')"
			@make-shorter-context-menu="$emit('make-shorter-context-menu')"
			@describe-your-change-context-menu="$emit('describe-your-change-context-menu')"
		/>
		<editor-content :editor="editor" />
	</div>
</template>

<style lang="scss">
.editor-toolbar {
	top: 67px;
	position: sticky;
	padding: 10px;
	background-color: #f5f5f5;
	border: 1px solid #dedede;
	border-radius: 12px;
	z-index: 99;
	margin-bottom: 20px;

	.btn {
		padding: 5px 10px;
	}
}

.bubble-menu {
	padding: 10px;
	background-color: #f5f5f5;
	border: 1px solid #dedede;
	border-radius: 12px;
	.btn {
		padding: 5px 10px;
	}
}

.tiptap {
	padding: 10px 20px;
	margin-bottom: 10px;

	> * + * {
		margin-top: 0.75em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.7rem;
		margin-bottom: 0.7rem;
	}

	:first-child {
		margin-top: 0px;
	}

	blockquote {
		border-left: 4px solid #ccc;
		margin-bottom: 5px;
		margin-top: 5px;
		padding-left: 16px;
	}

	.is-editor-empty:first-child::before {
		color: #adb5bd;
		content: attr(data-placeholder);
		float: left;
		height: 0;
		pointer-events: none;
	}
}

.ProseMirror {
	outline: none;
	border: 1px solid transparent;
	border-radius: 12px;
}

.ProseMirror-focused {
	cursor: text;
	border-color: #dedede;
}
</style>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'

import MyBubbleMenu from './BubbleMenu.vue'

export default {
	components: {
		EditorContent,
		MyBubbleMenu,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return { editor: null }
	},
	watch: {
		value(value) {
			if (this.editor.getHTML() !== value) {
				this.editor.commands.setContent(value, false)
			}
		},
		placeholder(placeholder) {
			const p = this.editor.extensionManager.extensions.find(extension => extension.name === 'placeholder')
			p.options['placeholder'] = placeholder
			this.editor.view.dispatch(this.editor.state.tr)
		},
	},
	methods: {
		handleAIAction(payload) {
			this.$emit('ai-action', payload)
		},
		forceCloseBubbleMenu() {
			this.$refs['bubble-menu'].$destroy()
		},
		setLink() {
			if (this.editor.isActive('link')) {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()
				return
			}

			const { view, state } = this.editor
			const { from, to } = view.state.selection
			const text = state.doc.textBetween(from, to, '')

			if (!text) {
				alert('Please select some text to create a link.')
				return
			}

			const previousUrl = this.editor.getAttributes('link').href
			const url = window.prompt('URL', previousUrl)

			// user closed the prompt window
			if (url === null) {
				return
			}

			// user entered an empty string as a URL
			if (url === '') {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()

				return
			}

			// user entered URL, update the link
			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url })
				.run()
		},
	},
	mounted() {
		this.editor = new Editor({
			content: this.value,
			extensions: [
				StarterKit,
				Typography,
				Link,
				TaskList,
				Highlight,
				Underline,
				TaskItem.configure({ nested: true }),
				Placeholder.configure({
					placeholder: this.placeholder,
					emptyEditorClass: 'is-editor-empty has-text-primary',
				}),
			],
			autofocus: true,
			onUpdate: () => {
				this.$emit('input', this.editor.getHTML())
			},
		})
	},
	beforeDestroy() {
		this.editor.destroy()
	},
}
</script>
