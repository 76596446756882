<template>
	<div v-if="state === 'loaded'">
		<div id="pdf-preview">
			<div>
				<button class="btn btn-primary pdf-button position-absolute" @click="preparePDF()">
					PDF
				</button>

				<div v-if="formRequest && statusImages[formRequest.status]" id="request-image">
					<img
						:src="statusImages[formRequest.status]"
						alt="Status"
						class="request-status-image position-absolute w-100"
					/>
				</div>

				<div v-if="formRequest && cornerImages[formRequest.status]" id="corner-image">
					<img
						:src="cornerImages[formRequest.status]"
						alt="Status"
						class="request-corner-image position-absolute w-25 top-0"
					/>
				</div>

				<template v-for="(step, stepIndex) in form.steps">
					<div class="row" :key="stepIndex" :class="borderClass(step)">
						<div v-if="step.visible_to !== 'EVERYONE'">
							<div v-for="(department, dIndex) in departments" :key="dIndex">
								<span v-if="department.id === step.review_department_id">
									<h6 class="text-muted text-center ms-0 pt-2 pb-2">
										For {{ department.name }} use only!
									</h6>
								</span>
							</div>
						</div>

						<template v-for="field in step.fields">
							<div
								v-if="elementIsVisible(field, formFields, 'pdf')"
								:key="step.id + '-' + field.id"
								:id="`field-${field.id}`"
								:class="[
									'pdf-element form-group col-' + field.col,
									step.visible_to === 'EVERYONE' ? ' ' : ' department-' + step.review_department_id,
								]"
							>
								<div class="page_height pt-2">
									<label
										:for="'fsf-' + field.id"
										class="form-label"
										v-show="
											field.type !== 'SignatureElement' &&
												field.type !== 'PaymentElement' &&
												field.type !== 'NameInputElement' &&
												field.type !== 'TextElement' &&
												field.type !== 'TextInputElement' &&
												field.type != 'PhoneInputElement' &&
												field.type !== 'NumberInputElement' &&
												field.type !== 'AddressInputElement' &&
												field.type !== 'CheckboxElement' &&
												field.type !== 'RadioButtonElement' &&
												field.type !== 'SelectListElement' &&
												field.type !== 'EmailInputElement' &&
												field.type !== 'TimeElement' &&
												field.type !== 'YearElement' &&
												field.type !== 'DatePickerElement' &&
												field.type !== 'TableElement'
										"
									>
										{{ field.label }}
									</label>

									<component
										v-if="field.type !== 'PaymentElement' && field.type !== 'TableElement'"
										:is="field.type"
										:currentField="field"
										class="element"
										:formRequest="formRequest"
										:class="addFieldClass(field)"
										:value="field.value"
										mode="pdf"
									>
									</component>

									<!-- Signature Element -->
									<div
										v-if="field.type === 'SignatureElement'"
										class="row align-items-center rounded-0 border-0"
									>
										<label class="pdf-label form-label" for=""> {{ field.label }}</label>
										<div v-if="!field.value">
											<div class="pdf-field"></div>
										</div>
										<div v-if="field.value">
											<!-- Redacted signature -->
											<div v-if="$route.query.redacted && field.redacted">
												<div class="pdf-redacted"></div>
												<div class="pdf-redacted"></div>
												<div class="pdf-redacted"></div>
											</div>
											<div v-else>
												<div v-if="field.value.type === 'font'">
													<p class="font-cursive signature-font col-4">
														{{
															(field.value.name || signatureElement.value)
																.split(',')
																.filter(Boolean)
																.join(' ')
														}}
													</p>
												</div>
												<div v-else-if="field.value.type === 'draw'" class="col-4">
													<img :src="field.value.image" class="img-fluid" />
												</div>

												<span class="text-muted"
													>Signed electronically on
													{{
														formRequest.sent_at || formRequest.created_at | dateLocal
													}}</span
												>
											</div>
										</div>
									</div>
									<!-- End of signature element -->
									<!-- Text element -->
									<div
										v-else-if="field.type === 'TextElement'"
										class="element"
										:class="[field.class]"
										v-html="fillTextWithVariables(field.value, formFields)"
									></div>

									<!-- Payment Field -->
									<div v-if="field.type === 'PaymentElement'" class="pt-3">
										<label class="form-label pdf-label">{{ field.label }}</label>
										<h5
											v-if="field.status"
											class="
													form-control
													border-bottom-1 border-top-0 border-start-0 border-end-0
													rounded-0
													text-uppercase
												"
										>
											<span v-if="field.value || field.data.price">
												{{ paymentStatuses[field.status].name }}

												<b> {{ field.value || field.data.price | currency }}</b>
											</span>
										</h5>
									</div>
									<!-- End of payment filed -->
									<!-- Table Field -->
									<div v-if="field.type === 'TableElement'">
										<div v-if="formRequest && formRequest.answers">
											<table-element
												:currentField="{
													...field,
													value: formRequest.answers[field.id] || [],
													pdf: true,
												}"
											></table-element>
										</div>
										<!-- Empty pdf -->
										<div v-else>
											<table-element
												:currentField="{
													...field,
													value: ['0', '1', '2', '3', '4'],
													pdf: true,
												}"
											></table-element>
										</div>
									</div>
									<!-- End of table field -->
								</div>
							</div>
						</template>
					</div>
				</template>
				<div
					v-if="formRequest && (formRequest.status === 'approved' || formRequest.status === 'declined')"
					id="processed-by"
					class="row w-100"
				>
					<div
						class="processed-by mt-3 border"
						:class="formRequest.status === 'approved' ? 'border-success' : 'border-danger'"
					>
						<b class="me-2 text-uppercase">{{
							formRequest.status !== 'declined' && (form.type === 'license' || form.type === 'permit')
								? 'issued'
								: formRequest.status
						}}</b>
						<span v-if="formRequest.reviewed_by"
							>by
							<b v-if="person">{{ person.name }} </b>
						</span>
						on {{ formRequest.reviewed_at | dateLocal }}
						<!-- If valid_until is set -->
						<div v-if="formRequest && formRequest.status === 'approved' && formRequest.valid_until">
							<span>Valid until: {{ formRequest.valid_until | dateLocal }}</span>
						</div>
					</div>
				</div>
				<div v-if="formRequest && formRequest.sent_at" id="request-sent-date">
					<div class="request-sent-date mt-2">
						<div class="border border-warning">
							<b class="text-uppercase">Submited at: </b>
							{{ formRequest.sent_at | dateLocal }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="state === 'loading'" class="text-center">
		<div class="spinner-border" role="status"></div>
	</div>
	<div v-else class="alert alert-danger">
		Oops, we couldn't load the form details
	</div>
</template>

<style lang="scss">
@import '@/assets/pdf_preview';
</style>

<script>
import { mapState } from 'vuex'

import heyGovApi from '@/api.js'
import { handlePayment, fillTextWithVariables, elementIsVisible } from '../../actions/forms.js'
import { paymentStatuses } from '@/actions/payments.js'

import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import TableElement from '@/components/form-builder/TableElement'
import YearElement from '@/components/form-builder/YearElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import PaymentElement from '@/components/form-builder/PaymentElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'
import NameInputElement from '@/components/form-builder/NameInputElement'
import TimeElement from '@/components/form-builder/TimeElement'
import TextElement from '@/components/form-builder/TextElement'

export default {
	name: 'FormPdf',
	components: {
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
		CheckboxElement,
		DatePickerElement,
		FileUploadElement,
		EmailInputElement,
		PhoneInputElement,
		TableElement,
		YearElement,
		SignatureElement,
		PaymentElement,
		AddressInputElement,
		NameInputElement,
		TimeElement,
		TextElement,
	},
	data() {
		return {
			form: null,
			state: 'loading',
			formRequest: null,
			pageHeight: 0,
			statusImages: {
				draft: 'https://files.heygov.com/form-request-status/draft1.png',
				sent: 'https://files.heygov.com/form-request-status/draft1.png',
				working: 'https://files.heygov.com/form-request-status/draft1.png',
				declined: 'https://files.heygov.com/form-request-status/declined1.png',
			},
			cornerImages: {
				approved: 'https://files.heygov.com/form-request-status/approved.png',
				draft: 'https://files.heygov.com/form-request-status/draft.png',
				sent: 'https://files.heygov.com/form-request-status/draft.png',
				working: 'https://files.heygov.com/form-request-status/draft.png',
				declined: 'https://files.heygov.com/form-request-status/declined.png',
			},
			person: null,
			paymentStatuses,
		}
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadFormAndRequest()
	},
	computed: {
		...mapState(['j', 'departments']),

		formFields() {
			return this.form.steps.map(s => s.fields).flat()
		},
		// Payment
		paymentElement() {
			return this.formFields.find(item => item.type === 'PaymentElement')
		},
		signatureElement() {
			return this.formFields.find(item => item.type === 'NameInputElement')
		},
	},

	updated() {
		// Check if payment element not removed

		if (this.paymentElement && !this.paymentElement.removed) {
			handlePayment(this.paymentElement, this.formFields)
		}
	},
	methods: {
		fillTextWithVariables,
		elementIsVisible,

		loadFormAndRequest() {
			const requests = [heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`)]

			// load formRequest (if needed) in parallel	with form
			if (this.$route.query.form_request) {
				const params = {
					secret: this.$route.query.secret,
					as_person: 333,
				}

				requests.push(
					heyGovApi.get(`/${this.j.slug}/form-requests/${this.$route.query.form_request}`, { params })
				)
			}

			Promise.all(requests).then(
				([formResponse, formRequestResponse]) => {
					this.form = formResponse.data

					if (formRequestResponse) {
						this.formRequest = formRequestResponse.data

						if (this.formRequest.reviewed_by) {
							this.getPerson(this.formRequest.reviewed_by)
						}
					}

					this.form.steps.forEach(step => {
						step.fields
							.filter(field => !field.removed)
							.forEach(field => {
								//todo remove this when api returns fields data/options in JSON format
								if (!field.data || typeof field.data === 'string') {
									field.data = JSON.parse(field.data || '{}')
								}

								if (typeof field.options === 'string') {
									field.options = JSON.parse(field.options)
								}

								field.pdf = true

								if (field.type === 'YearElement') {
									field.value = null
								} else if (field.type === 'RadioButtonElement') {
									field.options.forEach(option => {
										option.selected = false
										field.value = null
									})
								} else if (field.type === 'TableElement') {
									field.placeholder = ''
								}

								// add value from formRequest answers
								if (this.formRequest?.answers[field.id]) {
									field.value = this.formRequest.answers[field.id]

									if (field.type === 'PaymentElement') {
										Object.entries(field.value).forEach(entry => {
											const [key, value] = entry

											if (key == 'amount') {
												field.amount = value / 100
											} else if (key === 'status') {
												field.status = value
											}
										})
									}
								}

								if (this.$route.query.redacted && field.redacted && !SignatureElement) {
									field.value = '########'
								}

								// If pdf empty clean prefilled & default data
								if (
									!this.formRequest &&
									(field.type === 'NameInputElement' ||
										field.type === 'EmailInputElement' ||
										field.type === 'AddressInputElement' ||
										field.type === 'PhoneInputElement' ||
										field.type === 'TextInputElement' ||
										field.type === 'TextareaInputElement' ||
										field.type === 'NumberInputElement' ||
										field.type === 'TimeElement' ||
										field.type === 'YearElement')
								) {
									field.data.prefillWithAccount = false
									field.value = ''
								}
							})
					})

					this.state = 'loaded'
				},
				error => {
					this.state = 'error'
					this.errorMessage = error.message
				}
			)
		},

		addFieldClass(field) {
			if (field && field.display !== 'form') {
				return {
					'text-input': field.type === 'TextInputElement',
					'name-input': field.type === 'NameInputElement',
					'time-input border border-0 rounded-0': field.type === 'TimeElement',
					'text-radio pt-4': field.type === 'RadioButtonElement' || field.type === 'CheckboxElement',
					'text-email border border-0 rounded-0': field.type === 'EmailInputElement',
					'text-number border border-0 rounded-0': field.type === 'NumberInputElement',
					'signature border border-bottom-1 border-top-0 border-start-0 border-end-0 rounded-0':
						field.type === 'SignatureElement' && field.value === '',
					'text-address border border-0 rounded-0': field.type === 'AddressInputElement',
					'name-redacted':
						field.redacted === 1 && this.$route.query.redacted && field.type === 'NameInputElement',
					'text-redacted':
						field.redacted === 1 && this.$route.query.redacted && field.type === 'TextInputElement',
					'checkbox-redacted':
						field.redacted === 1 && this.$route.query.redacted && field.type === 'CheckboxElement',
					'radio-redacted':
						field.redacted === 1 && this.$route.query.redacted && field.type === 'RadioButtonElement',
					'select-redacted':
						field.redacted === 1 && this.$route.query.redacted && field.type === 'SelectListElement',
				}
			}
		},
		borderClass(step) {
			if (step.visible_to !== 'EVERYONE') {
				return 'border border-secondary border-1'
			}
		},
		// Prepare PDF function prepares all elements for printing with page breaks
		// Todo: fix problem with breaking col-xx in new page if there is space on prev
		getDepartmentName(id) {
			return this.departments.find(d => d.id == id).name
		},
		preparePDF() {
			if (this.state === 'loaded') {
				let pageMaxHeight = 900
				let pdfPreview = document.getElementById('pdf-preview').innerHTML

				// Get all elements from pdf-preview
				let elements = [...document.getElementsByClassName('pdf-element')]
				let requestImage = ''
				let requestInnerHtml = ''
				if (document.getElementById('request-image') !== null) {
					requestImage = document.getElementById('request-image')
					requestInnerHtml = requestImage.innerHTML
				}
				let cornerImage = ''
				let cornerInnerHtml = ''
				if (document.getElementById('corner-image') !== null) {
					cornerImage = document.getElementById('corner-image')
					cornerInnerHtml = cornerImage.innerHTML
				}
				let formRequestSentDate = ''
				let formRequestSentDateInnerHtml = ''
				if (document.getElementById('request-sent-date') !== null) {
					formRequestSentDate = document.getElementById('request-sent-date')
					formRequestSentDateInnerHtml = formRequestSentDate.innerHTML
				}
				let processed = ''
				let processedInnerHtml = ''
				if (document.getElementById('processed-by') !== null) {
					processed = document.getElementById('processed-by')
					processedInnerHtml = processed.innerHTML
				}

				// Create new content to APP
				let pdfPlace =
					'<div class="wrapper" id="pageWrapper">' +
					'<div id="page1" class="page portrait">' +
					requestInnerHtml +
					cornerInnerHtml +
					'<div class="row" id="page1elements"></div>' +
					'</div>' +
					'</div>' +
					'<div id="stock" class="row"></div>'

				var elem = document.getElementById('app')
				elem.innerHTML = pdfPlace
				elem.innerHTML += pdfPreview
				elem.lastElementChild.classList.add('d-none')

				// Create page wrapper
				let pageWrapper = document.getElementById('pageWrapper')
				let appendElement = document.getElementById('page1elements')

				let currentPageHeight = document.getElementById('page1elements').clientHeight
				let page = 1

				// Setup additional element where we gonna mesure next element
				let appendStock = document.getElementById('stock')

				let elementCol = 12

				// Loop true all elements and add them to page if page filled out add new page
				elements.forEach(element => {
					elementCol = this.getElementCol(element)

					let classArray = element.className.split(' ')

					// Check if the element belongs to the department that needs to fill it
					// Due to the possibility of the element passing to the other side we view all the elements of one department as one
					if (classArray[classArray.length - 1].includes('department-')) {
						let department = classArray[classArray.length - 1]
						let departmentId = department.replace(/\D/g, '')
						let departmentElements = [
							...document.getElementsByClassName('d-none')[0].getElementsByClassName(department),
						]

						departmentElements.forEach(dElement => {
							appendStock.appendChild(dElement)
						})

						let stockHeight = document.getElementById('stock').clientHeight + 16

						appendStock.innerHTML = ''

						// Calculate current page height, if its bigger than maxPageHeight we create new page
						currentPageHeight =
							document.getElementById('page' + page + 'elements').clientHeight + stockHeight

						if (currentPageHeight > pageMaxHeight) {
							page++
							let newPage = document.createElement('div')
							newPage.id = 'page' + page
							newPage.classList.add('page')
							newPage.classList.add('portrait')
							newPage.innerHTML =
								'<div class="row" id="page' + page + 'elements">' + requestInnerHtml + '</div>'
							pageWrapper.appendChild(newPage)
							appendElement = document.getElementById('page' + page + 'elements')
							currentPageHeight = document.getElementById('page' + page + 'elements').clientHeight
						}

						// Adding DIV for Department elements and adding elements in that div (watching all department elements like one)
						let departmentContainer = document.createElement('div')

						departmentContainer.classList.add(
							department,
							'border',
							'border-dark',
							'border-2',
							'mt-3',
							'ms-1',
							'p-1',
							'row'
						)

						let headerElement = document.createElement('h5')
						headerElement.classList.add('text-center', 'text-decoration-underline')

						headerElement.innerText = 'For ' + this.getDepartmentName(departmentId) + ' use only'

						departmentContainer.appendChild(headerElement)

						departmentElements.forEach(dElement => {
							departmentContainer.appendChild(dElement)
							this.removeElementFromArray(elements, dElement)
						})
						appendElement.appendChild(departmentContainer)
					} else {
						// Add to temporary parent to check height of element to know can it fit to page or go to next page
						appendStock.appendChild(element)

						let stockHeight = document.getElementById('stock').clientHeight

						appendStock.removeChild(appendStock.lastElementChild)

						currentPageHeight =
							document.getElementById('page' + page + 'elements').clientHeight + stockHeight

						if (currentPageHeight > pageMaxHeight && elementCol >= 12) {
							page++
							let newPage = document.createElement('div')
							newPage.id = 'page' + page
							newPage.classList.add('page')
							newPage.classList.add('portrait')
							newPage.innerHTML =
								'<div class="row" id="page' + page + 'elements">' + requestInnerHtml + '</div>'
							pageWrapper.appendChild(newPage)
							appendElement = document.getElementById('page' + page + 'elements')
							currentPageHeight = document.getElementById('page' + page + 'elements').clientHeight
						}

						appendElement.appendChild(element)
					}
				})
				// Add reviewed by person & review status at end of page

				let lastElement = elements[elements.length - 1]
				processedInnerHtml += formRequestSentDateInnerHtml
				lastElement.innerHTML += processedInnerHtml
			}
		},
		// Remove element from Array
		removeElementFromArray(array, element) {
			array.forEach((e, i) => {
				if (e.innerHTML === element.innerHTML) {
					array.splice(i, 1)
				}
			})
		},
		// Get element col size
		getElementCol(element) {
			let colNumber = 12
			let classArray = element.className.split(' ')
			classArray.forEach(c => {
				if (c.includes('col-')) {
					colNumber = c.match(/\d+/g)[0]
				}
			})
			return parseInt(colNumber)
		},
		getPerson(id) {
			heyGovApi.get(`${this.j.slug}/people/${id}`).then(({ data }) => {
				this.person = data
			})
		},
	},
}
</script>
