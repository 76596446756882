<template>
	<div class="page-virtual-assistant-chat">
		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-9 col-xl-8">
				<div class="chat-messages bg-white border rounded-1 p-3">
					<div class="chat-messages" style="min-height: 400px;">
						<div v-for="message in messages" :key="message.content">
							<div v-if="message.role === 'user'" class="text-end mb-2">
								<span class="d-inline-block px-2 py-1 bg-neutral-50 rounded-1">{{
									message.content
								}}</span>
							</div>
							<template v-else-if="message.role === 'assistant'">
								<div
									v-if="message.content"
									class="mb-2 chat-assistant-content"
									v-html="converter.makeHtml(message.content)"
								></div>

								<template v-if="message.tool_calls">
									<div v-for="tool in message.tool_calls" :key="tool.id" class="mb-2">
										<span class="d-inline-block bg-warning-50 rounded-1 px-3 py-2"
											>Using <strong>{{ tool.function.name }}</strong> with
											<code>{{ tool.function.arguments }}</code></span
										>
									</div>
								</template>
							</template>
							<div v-else-if="message.role === 'tool' && viewToolCall" class="mb-2">
								<span class="d-inline-block bg-danger-50 rounded-1 p-2"
									><code>{{ message.content }}</code></span
								>
							</div>
						</div>

						<div v-if="state === 'loading'">
							<p class="mb-2 text-neutral-400">Thinking..</p>
						</div>
						<div v-else-if="state === 'error'">
							<p class="mb-2 text-danger-400">Oops, error</p>
						</div>
					</div>

					<form @submit.prevent="sendMessage" class="chat-input bg-neutral-50 rounded border">
						<div class="input-group">
							<input
								type="text"
								class="form-control bg-transparent border-0"
								v-model="message"
								required
								minlength="2"
								:placeholder="`Ask anything about ${j.name}`"
								:aria-label="`Ask anything about ${j.name}`"
								aria-describedby="chat-input-button"
							/>
							<button class="btn bg-transparent border-0" id="chat-input-button">
								Ask
							</button>
						</div>
					</form>
				</div>

				<div v-if="isStaff" class="card mt-5">
					<div class="card-header bg-danger-50">
						<h4 class="card-title my-0">
							Debugging
							<small class="badge bg-danger-100 text-danger-400">👀 Only for HeyGov staff</small>
						</h4>
					</div>
					<div class="card-body">
						<p>
							<a
								:href="`${apiUrl + j.slug}/chat/system-prompt${auth ? `?person_id=${auth.id}` : ''}`"
								target="_blank"
								>View system prompt</a
							>
							<span class="px-2">&middot;</span>
							<a :href="`${apiUrl + j.slug}/chat/available-tools`" target="_blank">View tools</a>
							<span class="px-2">&middot;</span>
							<span class="form-check  form-check-inline form-switch">
								<input
									class="form-check-input"
									type="checkbox"
									role="switch"
									id="tool-call-response"
									v-model="viewToolCall"
								/>
								<label class="form-check-label" for="tool-call-response">View tool call response</label>
							</span>
						</p>

						<pre class="mb-0">{{ messages }}</pre>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Converter } from 'showdown'
import { mapGetters, mapState } from 'vuex'

import heyGovApi from '@/api.js'

export default {
	name: 'Chatbot',
	data() {
		return {
			state: 'idle',
			viewToolCall: false,
			message: '',
			messages: [],
			converter: new Converter(),
		}
	},
	computed: {
		...mapState(['apiUrl', 'j']),
		...mapGetters(['auth', 'isStaff']),
	},
	created() {
		//this.loadMessages()

		this.converter.setOption('simplifiedAutoLink', true)
		this.converter.setOption('openLinksInNewWindow', true)
	},
	methods: {
		sendMessage() {
			this.state = 'loading'

			this.messages.push({
				role: 'user',
				content: this.message,
			})

			heyGovApi.post(`${this.j.slug}/chat/123/messages`, this.messages).then(
				({ data }) => {
					this.messages = data
					this.state = 'idle'
				},
				error => {
					this.state = 'error'
					this.error = error.message
				}
			)

			this.message = ''
		},
		/* scrollSmoothToBottom() {
			var lastMessage = document.getElementById('chatbox-messages')
			setTimeout(function() {
				lastMessage.scrollTop = lastMessage.scrollHeight - lastMessage.clientHeight
			}, 200)
		}, */
	},
}
</script>
