var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table-hover mb-0"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),(_vm.forms.length > 1)?_c('th',{staticClass:"d-none d-md-table-cell"},[_vm._v("Form")]):_vm._e(),_c('th',[_vm._v("Date")]),_c('th',{staticClass:"d-none d-md-table-cell"},[_vm._v("Applicant")]),_c('th',[_vm._v("Status")]),_c('th',[(_vm.forms.length === 1)?_c('span',[_vm._v(_vm._s(_vm.formInfoColumn(_vm.forms[0])))]):_c('span',[_vm._v("Info")])]),_c('th',{staticClass:"d-none d-md-table-cell"},[_vm._v("Payment status")])])]),_c('transition-group',{attrs:{"name":_vm.states.initialLoad ? 'no-transition' : 'transition-highlight',"tag":"tbody"}},_vm._l((_vm.formRequests.slice(0, 5)),function(formRequest){return _c('tr',{key:("all-form-request-" + (formRequest.pid)),staticClass:"cursor-pointer",on:{"click":function($event){_vm.$router.push(
					("/" + (_vm.j.slug) + "/forms/" + (_vm.formInfo(formRequest.form_id).slug) + "/request-review/" + (formRequest.uuid))
				)}}},[_c('td',[_vm._v(_vm._s(formRequest.application_number || formRequest.id))]),(_vm.forms.length > 1)?_c('td',{staticClass:"d-none d-md-table-cell"},[_c('div',{staticClass:"text-truncate",staticStyle:{"width":"200px"}},[_vm._v(" "+_vm._s(_vm.formInfo(formRequest.form_id).name)+" ")])]):_vm._e(),_c('td',[_c('time',{attrs:{"datetime":new Date(formRequest.sent_at).toLocaleString(),"title":new Date(formRequest.sent_at).toLocaleString(),"data-bs-toggle":"tooltip"}},[_vm._v(_vm._s(_vm._f("dateToNow")(formRequest.sent_at,true)))])]),_c('td',{staticClass:"d-none d-md-table-cell"},[(_vm.people[formRequest.person_id])?_c('person-link',{attrs:{"id":formRequest.person_id,"avatar":18}}):_vm._e()],1),_c('td',[_c('form-request-status',{attrs:{"formRequest":formRequest,"tooltip":true}})],1),_c('td',[(_vm.formInfo(formRequest.form_id).info_column1 && _vm.formInfo(formRequest.form_id).steps.length)?_c('span',[_vm._v(" "+_vm._s(_vm.displayFieldAnswer( _vm.formInfo(formRequest.form_id) .steps.map(function (s) { return s.fields; }) .flat() .find(function (f) { return f.id == _vm.formInfo(formRequest.form_id).info_column1; }), formRequest.answers[_vm.formInfo(formRequest.form_id).info_column1], true ))+" ")]):(_vm.formInfo(formRequest.form_id).info_column1)?_c('span',[_vm._v(" "+_vm._s(formRequest.answers[_vm.formInfo(formRequest.form_id).info_column1])+" ")]):_vm._e()]),_c('td',{staticClass:"d-none d-md-table-cell"},[(
						_vm.formFieldType(formRequest.form_id, 'PaymentElement') &&
							formRequest.answers[_vm.formFieldType(formRequest.form_id, 'PaymentElement').id]
					)?_c('span',[(
							['succeeded', 'requires_capture'].includes(
								formRequest.answers[_vm.formFieldType(formRequest.form_id, 'PaymentElement').id].status
							)
						)?_c('span',[_c('payment-status',{attrs:{"payment":formRequest.answers[_vm.formFieldType(formRequest.form_id, 'PaymentElement').id],"tooltip":true}}),_c('p',{staticClass:"mb-0"},[_c('payment-field-summary',{attrs:{"payment":formRequest.answers[_vm.formFieldType(formRequest.form_id, 'PaymentElement').id]}})],1)],1):_c('payment-status',{attrs:{"payment":formRequest.answers[_vm.formFieldType(formRequest.form_id, 'PaymentElement').id],"tooltip":true}})],1):_vm._e()])])}),0),_c('tfoot',[(_vm.state === 'loading')?_c('tr',[_vm._m(0)]):(_vm.state === 'idle' && !_vm.formRequests.length)?_c('tr',[_vm._m(1)]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center py-3",attrs:{"colspan":"7"}},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_c('img',{staticClass:"mb-2",attrs:{"src":"https://files.heygov.com/illustrations/illustration-form-applications.jpg","width":"200"}}),_c('p',{staticClass:"text-muted mb-1"},[_vm._v("Awaiting form submissions")])])}]

export { render, staticRenderFns }