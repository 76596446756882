<template>
	<div class="page-meetings-single">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/meetings`">ClerkMinutes</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
			Add meeting
		</h3>

		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-8 col-xl-7">
				<div class="card mb-4">
					<form @submit.prevent="createMeeting">
						<div class="card-body">
							<div class="form-group mb-3">
								<label class="form-label" for="meeting-title"
									>Meeting title <small class="text-danger-400">*</small></label
								>

								<input
									type="text"
									class="form-control"
									id="meeting-title"
									v-model="meeting.title"
									required
									placeholder="Short and descriptive title"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="meeting-date"
									>Date <small class="text-danger-400">*</small></label
								>

								<div class="row">
									<div class="col">
										<input
											type="date"
											class="form-control"
											id="meeting-date"
											v-model="meeting.starts_at_date"
											required
										/>
									</div>
									<div class="col">
										<input
											type="time"
											class="form-control"
											id="meeting-time"
											v-model="meeting.starts_at_time"
											required
										/>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="meeting-description">Description</label>

								<textarea
									class="form-control"
									id="meeting-description"
									rows="3"
									v-model="meeting.description"
									placeholder="A short text about what the meeting is about"
								></textarea>
							</div>

							<div class="row">
								<div class="col form-group mb-3">
									<label class="form-label" for="meeting-categories">Categories</label>

									<multiselect
										v-model="meeting.categories"
										:options="meetingCategories"
										:multiple="true"
										track-by="id"
										label="name"
										placeholder="Select categories"
										:hideSelected="true"
										:taggable="true"
										@tag="addCategory"
										tag-placeholder="Add this as new category"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{
											option.name
										}}</template>
									</multiselect>
								</div>

								<div class="col form-group mb-3">
									<label class="form-label" for="meeting-department">Department</label>

									<select class="form-select" id="meeting-department" v-model="meeting.department_id">
										<option :value="null">-</option>
										<option
											v-for="department in departments"
											:key="`dept-${department.id}`"
											:value="department.id"
											>{{ department.name }}</option
										>
									</select>
								</div>
							</div>
						</div>
						<div class="card-footer d-flex align-items-center justify-content-between">
							<a
								href="https://townweb.notion.site/Create-a-meeting-b7260037f1d44c0dba0280a904c3673b"
								target="clerk-minutes-help"
								>🙋 How to create meetings</a
							>
							<button class="btn btn-primary px-5">Create meeting →</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { format, addDays } from 'date-fns'
import { mapState } from 'vuex'
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { hgApi } from '@/api.js'

export default {
	name: 'MeetingsCreate',
	components: { Multiselect },
	data() {
		const tomorrow = addDays(new Date(), 1)

		return {
			states: {
				meeting: 'idle',
			},
			meeting: {
				title: '',
				description: '',
				starts_at_date: format(tomorrow, 'yyyy-MM-dd'),
				starts_at_time: '17:00',
				department_id: null,
				categories: [],
			},

			meetingCategories: [],
		}
	},
	computed: {
		...mapState(['j', 'departments']),
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadMeetingCategories()
	},
	methods: {
		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`)
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(response.statusText || 'Server error')
					}
				})
				.then(categories => {
					this.meetingCategories = categories
				})
				.catch(error => {
					Vue.toasted.error(`Couldn't load meeting categories (${error})`)
				})
		},

		createMeeting() {
			this.states.meeting = 'loading'

			const body = {
				title: this.meeting.title,
				description: this.meeting.description,
				starts_at_local: `${this.meeting.starts_at_date} ${this.meeting.starts_at_time}:00`,
				department_id: this.meeting.department_id,
				categories: this.meeting.categories.map(c => c.id),
			}

			hgApi(`${this.j.slug}/meetings`, {
				body,
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(response.statusText || 'Server error')
					}
				})
				.then(meeting => {
					Vue.toasted.success('Meeting created successfully')

					this.$router.push(`/${this.j.slug}/meetings/${meeting.pid}/agenda`)
				})
				.catch(error => {
					Vue.toasted.error(`Couldn't create meeting (${error})`)
					this.states.meeting = 'error'
				})
		},

		addCategory(name) {
			hgApi(`${this.j.slug}/meetings/categories`, {
				body: { name },
			})
				.then(response => {
					if (response.ok) {
						return response.json()
					} else {
						throw new Error(response.statusText || 'Server error')
					}
				})
				.then(category => {
					this.meetingCategories.push(category)
					this.meeting.categories.push(category)
					Vue.toasted.success('Meeting category created successfully')
				})
				.catch(error => {
					Vue.toasted.error(`Couldn't create category (${error})`)
				})
		},
	},
}
</script>
