<template>
	<div class="page-meeting-single">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/meetings`">ClerkMinutes</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					{{ meeting ? meeting.title : $route.params.meetingId }}
				</h3>
			</div>
			<div v-if="meeting && currentRole !== 'CITIZEN'" class="col-auto">
				<div class="btn-group" :class="{ 'opacity-50': $route.name === 'MeetingPublicPage' }">
					<button type="button" class="btn btn-sm btn-outline-dark" @click="editMeeting">Edit meeting</button>
					<button
						type="button"
						class="btn btn-sm btn-outline-dark dropdown-toggle dropdown-toggle-split"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<span class="visually-hidden">More meeting options</span>
					</button>
					<ul class="dropdown-menu">
						<li v-if="isStaff">
							<button class="dropdown-item" @click="moveMeeting">Move to other muni</button>
						</li>
						<li><button class="dropdown-item" @click="deleteMeeting">Delete meeting</button></li>
					</ul>
				</div>
			</div>
		</div>

		<nav
			v-if="currentRole !== 'CITIZEN' && meeting"
			class="mb-3"
			:class="{ 'opacity-50': $route.name === 'MeetingPublicPage' }"
		>
			<ul
				class="nav nav-pills nav-pills-form hide-scrollbar mb-2"
				style="flex-wrap: nowrap;min-width: 100%;overflow-x: scroll;"
			>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}`"
						class="nav-link"
						:class="{ active: $route.name === 'Meeting' }"
						>Overview</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/agenda`"
						:class="{ active: $route.name === 'MeetingAgenda' }"
						class="nav-link"
					>
						Agenda
						<span v-if="meeting.agenda_items.length" class="badge bg-neutral-100 text-success-300">
							{{ meeting.agenda_items.filter(i => !i.parent_id).length }}
						</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/transcript`"
						class="nav-link"
						:class="{ active: $route.name === 'MeetingTranscript' }"
					>
						Transcript &amp; Speakers
						<span
							v-if="meeting.transcript_job_status === 'uploading'"
							class="badge bg-neutral-100 text-success-300"
						>
							<template v-if="states.meeting_audio_video_progress">
								(<strong class="text-primary-300">
									{{ (states.meeting_audio_video_progress * 100).toFixed(1) }}% </strong
								>)
							</template>
						</span>
						<span v-else-if="meeting.transcript_job_status === 'error'">
							⚠️
						</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/minutes`"
						class="nav-link"
						:class="{ active: $route.name === 'MeetingMinutes' }"
					>
						Minutes
					</router-link>
				</li>

				<li class="nav-item ms-auto">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/info`"
						class="nav-link"
						:class="{ active: $route.name === 'MeetingPublicPage' }"
						>Public page for meeting
					</router-link>
				</li>
			</ul>
		</nav>

		<div v-if="states.meeting === 'loading'" class="text-center py-5">
			<span class="spinner-border spinner-border-sm"></span> Loading meeting details..
		</div>
		<div v-else-if="states.meeting === 'loaded' && meeting">
			<video
				v-if="meeting.video_file_path"
				controls
				preload="metadata"
				:src="getPublicFileUrl(meeting.video_file_path)"
				class="ratio ratio-16x9 rounded-1"
				:class="{ shadow: meetingPlayer.position === 'custom' }"
				ref="meetingplayer"
				draggable="true"
				:style="meetingPlayer.styles"
				@dragstart="playerDragstart"
				@timeupdate="playerTimeupdate"
			>
				<!-- <track
									label="English"
									kind="captions"
									srclang="en"
									src="http://localhost:8080/captions/me_123.vtt"
									default
								/> -->

				Your browser can't play this video format. You can
				<a :href="getPublicFileUrl(meeting.video_file_path)" download>
					download the video
				</a>
				and watch it directly on your computer.
			</video>
			<audio
				v-else-if="meeting.audio_file_path"
				controls
				preload="metadata"
				:src="getPublicFileUrl(meeting.audio_file_path)"
				class="rounded-1"
				:class="{ shadow: meetingPlayer.position === 'custom' }"
				ref="meetingplayer"
				draggable="true"
				:style="meetingPlayer.styles"
				@dragstart="playerDragstart"
				@timeupdate="playerTimeupdate"
			>
				Your browser can't play this audio format. You can
				<a :href="getPublicFileUrl(meeting.audio_file_path)" download>
					download the audio
				</a>
				and listen directly on your computer.
			</audio>

			<router-view
				:meeting="meeting"
				:meetingPlayer="meetingPlayer"
				@updateMeeting="payload => updateMeeting(payload.fields, payload.message)"
				@playerStyles="setPlayerStyles"
				@playerTimestamp="payload => setPlayerTimestamp(payload, true)"
				@uploadMeetingAudioVideo="uploadMeetingAudioVideo"
			></router-view>

			<div v-if="audioVideoFileError" class="alert alert-danger">{{ audioVideoFileError }}</div>
		</div>

		<div v-else class="text-center py-5">
			Error loading meeting info
		</div>

		<div v-if="meeting">
			<div v-if="isStaff && meeting.transcript" class="row">
				<div class="col-lg-8 col-md-10">
					<div class="card border-danger mb-4">
						<div class="card-body">
							<h5 class="mb-2">
								Search in transcript <small class="badge bg-danger-50 text-danger-400">Beta</small>
							</h5>

							<div
								v-for="(msg, index) in transcriptMessages"
								:key="index"
								class="row align-items-center hover mb-1 py-1 gx-3"
							>
								<div class="col-auto">
									<person-avatar
										v-if="msg.role === 'user'"
										:person="account"
										:size="32"
									></person-avatar>
									<img
										v-else
										src="https://files.heygov.com/assets/heygov-logo.png"
										class="rounded-circle"
										width="32"
										height="32"
									/>
								</div>
								<div class="col">
									{{ msg.content }}
									<small
										v-if="msg.timestamp"
										class="d-inline-block px-1 rounded-1 bg-neutral-50 text-primary-200 cursor-pointer"
										@click="setPlayerTimestamp(msg.timestamp)"
										>{{ timestampToMinutes(msg.timestamp) }}</small
									>
								</div>
							</div>

							<form @submit.prevent="transcriptSearch">
								<div class="input-group input-group-sm">
									<input
										type="search"
										class="form-control"
										v-model="transcriptSearchQuery"
										required
										minlength="3"
										placeholder="Ask me anything"
										:disabled="states.transcriptSearch === 'loading'"
									/>
									<button class="btn">
										<span
											v-if="states.transcriptSearch === 'loading'"
											class="spinner-border spinner-border-sm"
										></span>
										<font-awesome-icon v-else :icon="['fas', 'paper-plane']" />
									</button>
								</div>
							</form>

							<div v-if="states.transcriptSearch === 'idle'">
								<p>Go ahead and try searthing anything that was discussed in the meeting, like:</p>

								<div>
									<span
										class="badge bg-neutral-50 text-primary-200 cursor-pointer me-2 mb-2"
										@click="transcriptDoSearch('Who are the meeting participants?')"
										>Who are the meeting participants?</span
									>
									<span
										class="badge bg-neutral-50 text-primary-200 cursor-pointer me-2 mb-2"
										@click="transcriptDoSearch('What was decided about the dog licenses?')"
										>What was decided about the dog licenses?</span
									>
									<span
										class="badge bg-neutral-50 text-primary-200 cursor-pointer me-2 mb-2"
										@click="transcriptDoSearch('Next scheduled meeting')"
										>Next scheduled meeting</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-ai-settings" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div v-if="meeting" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">🤖 AI tweaks</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-6">
								<div class="form-group mb-3">
									<label class="form-label">OpenAI model</label>

									<select class="form-select form-select-sm" v-model="aiParams.openai_model" required>
										<option value="gpt-3.5-turbo">GPT-3.5 Turbo - 4k tokens</option>
										<option value="gpt-4">GPT-4 - 8k tokens</option>
									</select>
								</div>
							</div>

							<div class="col-6">
								<div class="form-group mb-3">
									<label class="form-label"
										>Sampling temp
										<code class="mx-1">{{ aiParams.openai_temperature }}</code>
										<span v-if="aiParams.openai_temperature > 1.8" class="mx-1">😬</span>
										<a
											href="https://towardsdatascience.com/how-to-sample-from-language-models-682bceb97277"
											target="_blank"
											>??</a
										></label
									>

									<input
										type="range"
										class="form-range"
										v-model="aiParams.openai_temperature"
										step="0.1"
										min="0"
										max="2"
										required
									/>

									<div class="row">
										<div class="col-6">
											<small class="text-neutral-400">safer and serious</small>
										</div>
										<div class="col-6 text-end">
											<small class="text-neutral-400">more creative (more risk)</small>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label">System prompt</label>

								<input type="text" class="form-control" v-model="aiParams.prompt_system" />
							</div>

							<div class="form-group mb-3">
								<label class="form-label">Minutes prompt</label>

								<textarea class="form-control" v-model="aiParams.prompt" rows="5"></textarea>
							</div>

							<div class="row justify-content-end">
								<div class="col-lg-8">
									<div class="bg-light rounded-1 p-2">
										<span class="badge bg-primary-50 text-dark float-start">Tokens math</span>
										<div class="text-end">
											<p class="mb-1">
												Transcription:
												<strong>~{{ transcriptTokens.toLocaleString() }}</strong>
											</p>
											<p class="mb-1">
												Agenda items:
												<strong
													>{{ meeting.agenda_items.length * 250 }} ({{
														meeting.agenda_items.length
													}}
													* 250)</strong
												>
											</p>
											<p class="mb-1">
												Prompt:
												<strong>~100</strong>
											</p>
											<p class="mb-1 lead">
												Total expected usage:
												<strong
													>~{{
														(
															meeting.agenda_items.length * 250 +
															transcriptTokens
														).toLocaleString()
													}}</strong
												>
											</p>
										</div>
									</div>
								</div>
							</div>

							<!-- <div class="col-6">
								<div class="form-group mb-3">
									<label class="form-label">Variants</label>

									<input
										type="number"
										class="form-control form-control-sm"
										v-model="aiParams.openai_variants"
										min="1"
										max="5"
										required
									/>

									<small class="text-muted"
										>How many minutes variants to create, on top of base one?</small
									>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group mb-3">
									<label class="form-label">Variants sampling temperature </label>

									<input
										type="range"
										class="form-range"
										v-model="aiParams.openai_variants_temperature"
										step="1"
										min="0"
										max="10"
										required
									/>

									<div class="row">
										<div class="col-6">
											<small class="text-neutral-400">more serious</small>
										</div>
										<div class="col-6 text-end">
											<small class="text-neutral-400">more creative (more risk)</small>
										</div>
									</div>
								</div>
							</div> -->
						</div>
					</div>
					<div class="modal-footer justify-content-end">
						<button class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">
							Save settings
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.for-file-input {
	border: 1px dashed $neutral-200;
	background-color: $neutral-50;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

	&:hover {
		background-color: $neutral-100;
		border-color: $neutral-300;
	}

	&.dragover {
		background-color: $warning-50;
		border-color: $warning-100;
		transform: scale(1.05);
	}
}

.meeting-audio-video-player {
	position: fixed;
	width: 350px;
	border: 1px solid $neutral-200;
	border-radius: 0.75rem;
	background-color: #fff;
}
</style>

<style lang="scss">
@import '@/assets/variables';

.minutes-text {
	//background-color: $neutral-50;

	p {
		margin-bottom: 0.8rem;
	}

	blockquote {
		background-color: #f0f9ff;
		border-left: 4px solid #e0f2fe;
		border-radius: 0.4rem;
		padding: 0.6rem 1rem;
		margin-bottom: 0.8rem;
	}
}
</style>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { random } from 'lodash-es'
import { format } from 'date-fns'

import heyGovApi, { hgApi } from '@/api.js'
import { getPublicFileUrl, handleResponseError, sendEvent } from '@/utils.js'

import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'Meeting',
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	components: { PersonAvatar },
	data() {
		return {
			states: {
				meeting: 'loading',
				agenda_file_path: 'idle',
				agenda_items: 'idle',

				meeting_file_type: 'file',
				meeting_audio_video_file: 'idle',
				meeting_audio_video_progress: 0,
				transcript: 'idle',

				transcriptSearch: 'idle',
				transcriptSearchVideo: false,
				transcriptSearchVideoPlayer: false,
			},
			audioVideoFileError: '',
			meeting: null,
			agendaItemSelected: null,
			agendaItemSelectedHistory: [],
			meeting_file: {
				file: null,
				link: null,
			},
			aiParams: {
				openai_model: 'gpt-4',
				prompt_system: 'You are a helpful assistant that observes meetings',
				prompt:
					"For each agenda item, create a summary in the format of a municipal meeting minutes. If something was discussed on public comments, return a summary of what was discussed in meeting minutes format. Return all summaries in markdown format. Don't change agenda item titles (keep the ID!) and make them markdown headings. If & when a motion is made for each agenda items, please include the name of the person (if known) as well as the person's name who seconded the motion (if known). If not known use [name]. Put this motion information in blockquotes at the very end of its respective agenda item.",
				openai_temperature: 0.8,

				openai_variants: 2,
				openai_variants_temperature: 8,
			},
			minutesStatusTimer: null,
			errors: [],
			transcriptionStatusTimer: null,

			$modalMeetingAgenda: null,
			$modalMeetingAudioVideo: null,

			transcriptSearchQuery: '',
			transcriptMessages: [],

			meetingPlayer: {
				currentTime: 0,
				position: 'default',
				initialX: 0,
				initialY: 0,
				styles: {
					position: 'fixed',
					width: '300px',
					right: '10px',
					bottom: '10px',
				},
			},
		}
	},
	computed: {
		...mapState(['j', 'account', 'apiUrl', 'people', 'departments']),
		...mapGetters(['currentRole', 'isStaff', 'auth']),
		transcriptTokens() {
			return Math.ceil((this.meeting?.transcript || '').trim().length / 4)
		},
	},
	created() {
		this.loadMeeting()

		if (this.auth) {
			this.$store.dispatch('loadDepartments')

			if (this.$route.query.ts) {
				this.states.transcriptSearchVideo = true
			}

			sendEvent('View meeting', {
				feature: 'ClerkMinutes',
				meeting: this.$route.params.meetingId,
			})
		} else if (this.$route.name !== 'MeetingPublicPage') {
			this.$router.replace({
				name: 'MeetingPublicPage',
				params: {
					jurisdiction: this.j.slug,
					meetingId: this.$route.params.meetingId,
				},
			})
		}
	},
	mounted() {
		if (this.$route.query.ts) {
			setTimeout(() => {
				this.setPlayerTimestamp(Number(this.$route.query.ts))
			}, 1000)
		}
	},
	methods: {
		getPublicFileUrl,

		loadMeeting() {
			this.states.meeting = 'loading'

			heyGovApi(`${this.j.slug}/meetings/${this.$route.params.meetingId}?expand=agenda_items`)
				.then(({ data }) => {
					// process data for UI
					data.starts_at = format(new Date(data.starts_at), 'yyyy-MM-dd HH:mm')
					data.agenda_items = data.agenda_items.map(item => {
						item._editing = false
						return item
					})
					data.minutes_text = data.minutes_text || ''

					this.meeting = data
					this.states.meeting = 'loaded'

					if (data.transcript_job_status === 'started') {
						this.transcriptionStatusTimer = setInterval(() => {
							this.checkTranscriptJobStatus()
						}, 5000)
					}

					if (data.minutes_status === 'generating') {
						this.minutesStatusTimer = setInterval(() => {
							this.checkMinutesJobStatus()
						}, 5000)
					}

					setTimeout(() => {
						this.startPlayerDragDrop()
					}, 500)
				})
				.catch(error => {
					handleResponseError(`Couldn't load meeting details ({error})`)(error)
					this.states.meeting = 'error'
				})
		},

		updateMeeting(fields, successMessage = 'Meeting updated') {
			return new Promise((resolve, reject) => {
				heyGovApi
					.put(`${this.j.slug}/meetings/${this.meeting.pid}`, fields)
					.then(response => {
						for (const key in fields) {
							Vue.set(this.meeting, key, fields[key])
						}
						Vue.toasted.success(successMessage)
						resolve(response)
					})
					.catch(error => {
						handleResponseError('Error updating meeting ({error})')(error)
						reject(error)
					})
			})
		},

		editMeeting() {
			Vue.toasted.error('Not implemented yet 🤷')

			sendEvent('Edit meeting', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.meeting.title,
				implemented: 'no',
			})
		},
		moveMeeting() {
			const newJurisdiction = prompt('Enter jurisdiction slug to move meeting to')

			if (newJurisdiction?.trim().length && newJurisdiction.trim() !== this.j.slug) {
				heyGovApi
					.post(`${this.j.slug}/meetings/${this.meeting.pid}/move`, {
						jurisdiction: newJurisdiction.trim(),
					})
					.then(() => {
						Vue.toasted.success('Meeting is moved')
						window.location = `/${newJurisdiction.trim()}/meetings/${this.meeting.pid}`
					}, handleResponseError('Error moving meeting ({error})'))
			}
		},

		deleteMeeting() {
			if (confirm(`Delete meeting and all its content?`)) {
				hgApi(`${this.j.slug}/meetings/${this.meeting.pid}`, {
					method: 'DELETE',
				}).then(response => {
					if (response.ok) {
						Vue.toasted.show('Meeting is deleted')
						this.$router.push(`/${this.j.slug}/meetings`)
					} else {
						alert(`Error deleting meeting (${response.statusText})`)
					}
				})
			}
		},

		playerTimeupdate(event) {
			this.meetingPlayer.currentTime = event.target.currentTime
		},
		setPlayerStyles(styles) {
			styles.zIndex = 999
			this.meetingPlayer.styles = styles
			this.meetingPlayer.position = 'default'
		},
		startPlayerDragDrop() {
			if (this.$refs.meetingplayer) {
				document.addEventListener('pointermove', this.playerDrag, {
					passive: true,
				})
				document.addEventListener('pointerup', this.playerDragend, {
					passive: true,
				})
			}
		},
		playerDragstart($event) {
			$event.preventDefault()
			this.meetingPlayer.initialX = $event.clientX
			this.meetingPlayer.initialY = $event.clientY
			this.$refs.meetingplayer.style.pointerEvents = 'none'

			// get element position
			const pos = $event.target.getBoundingClientRect()

			this.setPlayerStyles({
				position: 'fixed',
				width: `${pos.width}px`,
				top: `${pos.top}px`,
				left: `${pos.left}px`,
				right: 'auto',
			})
			this.meetingPlayer.position = 'custom'
		},
		playerDrag($event) {
			if (!this.$refs.meetingplayer || this.$refs.meetingplayer.style.pointerEvents !== 'none') {
				return
			}

			const top = parseInt(this.meetingPlayer.styles.top.slice(0, -2), 10)
			const left = parseInt(this.meetingPlayer.styles.left.slice(0, -2))

			this.meetingPlayer.styles.top = `${top + $event.clientY - this.meetingPlayer.initialY}px`
			this.meetingPlayer.styles.left = `${left + $event.clientX - this.meetingPlayer.initialX}px`

			this.meetingPlayer.initialX = $event.clientX
			this.meetingPlayer.initialY = $event.clientY
		},

		playerDragend() {
			if (!this.$refs.meetingplayer || this.$refs.meetingplayer.style.pointerEvents !== 'none') {
				return
			}
			this.$refs.meetingplayer.style.pointerEvents = 'initial'
		},

		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},

		dropMeetingAgendaFile(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				this.uploadMeetingAgenda(event.dataTransfer.files[0])
			} else {
				alert('No files dropped 🤷')
			}
		},
		handleMeetingAgendaFile($event) {
			this.uploadMeetingAgenda($event.target.files[0])
		},
		uploadMeetingAgenda(file) {
			const size = file.size / 1024 / 1024

			const allowedFiles = [
				'application/pdf', // pdf
				'application/msword', // doc
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
				'text/plain', // txt
			]

			if (size > 30) {
				alert('File size is too big (max 30MB)')
			} else if (allowedFiles.includes(file.type)) {
				this.states.agenda_file_path = 'loading'

				// prepare file data
				var form = new FormData()
				form.append('file', file)

				heyGovApi
					.post(`${this.j.slug}/meetings/${this.meeting.pid}/upload-agenda-file?await_processing=1`, form)
					.then(({ data }) => {
						this.meeting.agenda_file_path = data.agenda_file_path
						this.meeting.agenda_text = data.agenda_text
						this.meeting.agenda_items.push(...data.agenda_items)

						this.$modalMeetingAgenda.show()
					}, handleResponseError('Error processing agenda ({error})'))
					.finally(() => {
						this.states.agenda_file_path = 'idle'
					})
			} else {
				alert('Only document files (PDF, Word) are allowed 🤷')
			}
		},
		saveMeetingAgenda($event) {
			const buttons = $event.target.querySelectorAll('button')
			buttons.forEach(button => {
				button.disabled = true
			})

			this.updateMeeting(
				{
					agenda_text: this.meeting.agenda_text,
				},
				'Agenda saved'
			)
				.then(() => {
					this.$modalMeetingAgenda.hide()
				})
				.finally(() => {
					buttons.forEach(button => {
						button.disabled = false
					})
				})
		},
		meetingAgendaRemove() {
			if (confirm('All agenda items & minutes will be removed, is this ok?')) {
				heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/remove-agenda`).then(() => {
					this.meeting.agenda_file_path = null
					this.meeting.agenda_text = ''
					this.meeting.agenda_items = []

					Vue.toasted.show('Agenda is removed')
					this.$modalMeetingAgenda.hide()
				}, handleResponseError('Error removing agenda ({error})'))
			}
		},
		agendaItemEdit(agendaItem) {
			agendaItem._summary = agendaItem.summary
			agendaItem._editing = true

			sendEvent('Edit agenda item minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.$route.params.name,
				agendaitem: agendaItem.title,
			})
		},
		agendaItemEditCancel(agendaItem) {
			agendaItem.summary = agendaItem._summary
			agendaItem._editing = false
		},
		agendaItemEditSave(agendaItem) {
			if (agendaItem._summary != agendaItem.summary) {
				agendaItem.summary_history.push({
					summary: agendaItem.summary,
					created_at: new Date(),
					created_by: this.auth.id,
				})

				heyGovApi
					.put(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${agendaItem.id}`, {
						summary: agendaItem.summary,
					})
					.then(() => {
						Vue.toasted.success('Agenda item minutes updated')
					}, handleResponseError('Error saving minutes ({error})'))
			}

			agendaItem._editing = false
		},
		agendaItemRevisions(agendaItem) {
			this.agendaItemSelected = agendaItem
			this.agendaItemSelectedHistory = window.structuredClone(agendaItem.summary_history.reverse())

			sendEvent('View minutes revisions', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.$route.params.name,
				agendaitem: agendaItem.title,
			})
		},
		agendaItemRevisionsRestore(agendaItem) {
			Vue.toasted.error('not implemented yet')

			sendEvent('Restore minutes revision', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.$route.params.name,
				agendaitem: agendaItem.title,
				implemented: 'no',
			})
		},
		agendaItemMinutesAction(agendaItem, action) {
			Vue.toasted.error('not implemented yet')

			sendEvent('Minutes AI action', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.$route.params.name,
				agendaitem: agendaItem.title,
				action,
				implemented: 'no',
			})
		},
		startBlankMinutes() {
			Vue.toasted.error('not implemented yet')

			sendEvent('Start blank minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.$route.params.name,
				implemented: 'no',
			})
		},
		minutesSaveAs(as, implemented = 'yes') {
			if (implemented === 'no') {
				Vue.toasted.error('not implemented yet')
			}

			sendEvent('Save minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.$route.params.meetingId,
				meeting: this.$route.params.name,
				saveas: as,
				implemented,
			})
		},

		async uploadMeetingAudioVideo(file) {
			this.audioVideoFileError = ''

			if (file.size < 1024 * 1024 * 4) {
				alert('File is too small to be a meeting recording 🤷')
				return
			}

			if (file.size > 1024 * 1024 * 1024 * 5) {
				alert('File is too big 😬 please compress it to be under 5GB')
				return
			}

			if (!file.type.startsWith('audio/') && !file.type.startsWith('video/')) {
				alert('Only text or audio/video files are allowed 🤷')
				return
			}

			this.meeting.transcript_job_status = 'uploading'

			const beforeUnloadHandler = event => {
				event.returnValue = 'If you leave this page, the upload will be interrupted'
			}

			window.addEventListener('beforeunload', beforeUnloadHandler)

			try {
				const uploadLinkResponse = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/audio-video-link`,
					{
						name: file.name,
						size: file.size,
						type: file.type,
					}
				)

				await axios.put(uploadLinkResponse.data.uploadUrl, file, {
					headers: { 'Content-Type': file.type },
					onUploadProgress: p => {
						this.states.meeting_audio_video_progress = p.loaded / p.total
					},
				})

				const fields = {
					transcript_job_status: 'started',
				}

				if (file.type.startsWith('audio/')) {
					fields.audio_file_path = uploadLinkResponse.data.path
					Vue.toasted.success(`Audio file is uploaded`)
				} else {
					fields.video_file_path = uploadLinkResponse.data.path
					Vue.toasted.success(`Video file is uploaded`)
				}

				this.updateMeeting(fields, 'Transcription process is started')

				this.transcriptionStatusTimer = setInterval(() => {
					this.checkTranscriptJobStatus()
				}, 10000)
			} catch (error) {
				handleResponseError('Error uploading file ({error})')(error)
				this.meeting.transcript_job_status = 'not-started'
				this.audioVideoFileError = error.message
			} finally {
				window.removeEventListener('beforeunload', beforeUnloadHandler)
			}
		},

		addMeetingAudioVideo($event) {
			const buttons = $event.target.querySelectorAll('button')
			buttons.forEach(button => {
				button.disabled = true
			})

			if (this.states.meeting_file_type === 'link') {
				this.updateMeeting({
					video_public_url: this.meeting_file.link,
					transcript_job_status: 'started',
				})
					.then(() => {
						this.$modalMeetingTranscriptFile.hide()
					})
					.finally(() => {
						buttons.forEach(button => {
							button.disabled = false
						})
					})
			}
		},
		saveMeetingAudioVideo($event) {
			const buttons = $event.target.querySelectorAll('button')
			buttons.forEach(button => {
				button.disabled = true
			})

			this.updateMeeting({
				transcript: this.meeting.transcript,
			})
				.then(() => {
					this.$modalMeetingAudioVideo.hide()
				})
				.finally(() => {
					buttons.forEach(button => {
						button.disabled = false
					})
				})
		},

		removeAudioVideoTranscript() {
			this.updateMeeting(
				{
					audio_public_url: null,
					audio_file_path: null,
					video_public_url: null,
					video_file_path: null,
					transcript: null,
					transcript_job_status: 'not-started',
					transcript_job_id: null,
				},
				'Audio/video & transcript are removed'
			).then(() => {
				this.$modalMeetingAudioVideo.hide()
			})
		},

		async checkTranscriptJobStatus() {
			const { data } = await heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}`)

			try {
				if (data.transcript_job_status !== 'transcribed') {
					return
				}

				this.meeting.transcript_job_status = data.transcript_job_status
				this.meeting.transcript = data.transcript
				this.meeting.minutes_status = data.minutes_status

				if (data.minutes_status === 'generating') {
					clearInterval(this.minutesStatusTimer)
					this.minutesStatusTimer = setInterval(() => {
						this.checkMinutesJobStatus()
					}, 5000)
				}

				Vue.toasted.success('Meeting transcript is ready')
				clearInterval(this.transcriptionStatusTimer)
			} catch (err) {
				handleResponseError(`Couldn't transcript job status ({error})`)(err)
			}
		},

		checkMinutesJobStatus() {
			//console.log('checking transribing job status')

			heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}?expand=agenda_items`)
				.then(({ data }) => {
					//console.log('job status is:', data.transcript_job_status)

					if (['done', 'fresh-done'].includes(data.minutes_status)) {
						this.meeting.minutes_status = data.minutes_status
						this.meeting.agenda_items = data.agenda_items.map(item => {
							item._editing = false
							return item
						})

						Vue.toasted.success('🎉 Meeting minutes are ready')
						clearInterval(this.minutesStatusTimer)
					}
				})
				.catch(handleResponseError(`Couldn't get minutes job status ({error})`))
		},

		renameAgendaItem(item) {
			const title = prompt('Rename agenda item', item.title)

			if (title && title !== item.title) {
				item.title = title

				heyGovApi
					.put(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${item.id}`, {
						title,
					})
					.then(() => {
						Vue.toasted.success('Agenda item is renamed')
					}, handleResponseError('Error renaming agenda item ({error})'))
			}
		},

		removeAgendaItem(item) {
			const hasMinutes = item.summary_history?.length
			const msg = hasMinutes
				? '🚨 This agenda item has minutes. Are you sure you want to remove it?'
				: 'For sure remove this agenda item?'

			if (confirm(msg)) {
				//todo change to soft delete, so we can restore it
				heyGovApi.delete(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${item.id}`).then(() => {
					this.meeting.agenda_items = this.meeting.agenda_items.filter(i => i.id !== item.id)
					Vue.toasted.success('Agenda item is removed')
				}, handleResponseError('Error removing agenda item ({error})'))
			}
		},

		extractSummary(variant) {
			if (this.meeting.transcript?.length > 10 && this.meeting.agenda_items.length > 0) {
				const currentStatus = this.meeting.minutes_status
				this.meeting.minutes_status = 'generating'
				this.errors = []

				if (variant === 'more' && this.aiParams.openai_temperature == 0.8) {
					this.aiParams.openai_temperature = random(10, 12) / 10
				}

				heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/generate-minutes`, this.aiParams).then(
					({ data }) => {
						this.meeting.minutes_status = data.minutes_status
						this.meeting.agenda_items = data.agendaItems.map(item => {
							item._editing = false
							return item
						})
						this.errors.push(...data.errors)

						Vue.toasted.success('Draft minutes are generated')
					},
					error => {
						if (error.response?.data?.code === 'meeting_minutes_generating') {
							clearInterval(this.minutesStatusTimer)
							this.minutesStatusTimer = setInterval(() => {
								this.checkMinutesJobStatus()
							}, 5000)
						} else {
							this.meeting.minutes_status = currentStatus
							handleResponseError('Error generating minutes ({error})')(error)
						}
					}
				)
			} else {
				alert('Please add meeting agenda, agenda items and meeting transcript first')
			}
		},

		findMinutesText(item) {
			let text = ''

			for (const label in this.meeting.minutes) {
				//console.log(label, item)
				if (label.includes(item)) {
					text = this.meeting.minutes[label]
					break
				}
			}

			return text
		},

		transcriptDoSearch(query) {
			this.transcriptSearchQuery = query
			this.transcriptSearch()
		},

		transcriptSearch() {
			this.states.transcriptSearch = 'loading'

			// show the audio/video player
			this.states.transcriptSearchVideo = true

			heyGovApi
				.post(`${this.j.slug}/meetings/${this.meeting.pid}/transcript-search`, {
					messages: this.transcriptMessages.map(m => {
						return {
							role: m.role,
							content: m.content,
						}
					}),
					q: this.transcriptSearchQuery,
				})
				.then(({ data }) => {
					this.states.transcriptSearch = 'loaded'

					this.transcriptMessages.push({
						role: 'assistant',
						content: data.answer,
						timestamp: data.timestamp,
					})

					// set the player timestamp
					this.setPlayerTimestamp(data.timestamp)

					this.transcriptSearchQuery = ''
				})
				.catch(err => {
					handleResponseError('Error searching transcript ({error})')(err)
					this.states.transcriptSearch = 'error'
				})

			this.transcriptMessages.push({
				role: 'user',
				content: this.transcriptSearchQuery,
			})

			sendEvent('Meeting chat', {
				feature: 'ClerkMinutes',
				meeting: this.$route.params.meetingId,
				query: this.transcriptSearchQuery,
			})
		},

		timestampToMinutes(timestamp) {
			const hours = Math.floor(timestamp / 3600)
			const minutes = Math.floor(timestamp / 60) - hours * 60
			const seconds = Math.round(timestamp % 60)

			let ts = `${minutes}:${String(seconds).padStart(2, '0')}`

			if (hours > 0) {
				ts = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
			}

			return ts
		},

		setPlayerTimestamp(timestamp, pressPlay = false) {
			console.log(this.meeting.pid, 'setPlayerTimestamp', timestamp, pressPlay)

			if (!this.$refs.meetingplayer) {
				return
			}

			// check if the video is loaded
			this.$refs.meetingplayer.addEventListener(
				'loadedmetadata',
				function() {
					this.currentTime = timestamp
				},
				false
			)

			this.$refs.meetingplayer.currentTime = Math.round(timestamp, 10)

			if (this.$refs.meetingplayer.paused && pressPlay) {
				this.$refs.meetingplayer.play()
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.transcriptionStatusTimer)
		clearInterval(this.minutesStatusTimer)

		document.removeEventListener('pointermove', this.playerDrag)
		document.removeEventListener('pointerup', this.playerDragend)
	},
}
</script>
