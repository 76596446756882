<template>
	<div class="page-meeting-transcript">
		<div v-if="['not-started', 'error'].includes(meeting.transcript_job_status)" class="card mb-4">
			<div
				class="card-body text-center py-6 file-drop"
				@dragover="dragover"
				@dragleave="dragleave"
				@drop="dropMeetingAudioVideo"
			>
				<p class="lead text-neutral-500 mb-4">This meeting has no recording yet</p>

				<div v-if="meeting.transcript_job_status === 'error'" class="alert alert-danger mx-6 mb-4">
					Previous recording couldn't be uploaded or processed. Please try again.
				</div>

				<p class="card-text">
					<label for="meeting-audio-video-file" class="btn btn-sm btn-outline-primary">
						<font-awesome-icon :icon="['fas', 'file-import']" class="me-1" />
						Upload audio or video
					</label>

					<span class="mx-3">or</span>

					<button
						class="btn btn-sm btn-outline-primary"
						data-bs-toggle="modal"
						data-bs-target="#modal-recording-url"
					>
						<font-awesome-icon :icon="['fas', 'plus']" /> Add YouTube or Zoom link
					</button>
				</p>

				<input
					type="file"
					id="meeting-audio-video-file"
					class="d-none"
					@change="handleMeetingAudioVideo"
					accept="audio/*,video/*"
				/>
			</div>
			<div class="card-footer">
				🙋
				<a
					href="https://townweb.notion.site/Upload-meeting-recording-audio-or-video-559e6058f77146c8b7037e9cd5add7aa"
					target="clerk-minutes-help"
					>How to add a recording</a
				>
			</div>
		</div>

		<div v-else-if="meeting.transcript_job_status === 'uploading'" class="card mb-4">
			<div class="card-body py-6 text-center">
				<p class="my-1">
					Uploading recording..
					<template v-if="states.meeting_audio_video_progress">
						(<strong class="text-primary-300"
							>{{ (states.meeting_audio_video_progress * 100).toFixed(1) }}%</strong
						>)
					</template>
					<template v-else-if="meeting.video_public_url">
						(<strong class="text-neutral-300"
							>from {{ urlPart(meeting.video_public_url, 'hostname') }}, shouldn't take more than 10
							minutes</strong
						>)
					</template>
				</p>
			</div>
		</div>

		<div v-else-if="['started', 'transcribed'].includes(meeting.transcript_job_status)">
			<div class="row gx-3">
				<div class="col-lg-6">
					<div
						class="ratio bg-light rounded-1 mb-4"
						:class="meeting.video_file_path ? 'ratio-16x9' : 'ratio-21x9'"
						ref="meetingOverviewPlayer"
					>
						<div
							v-if="meetingPlayer.position === 'custom'"
							class="d-flex align-items-center justify-content-center"
							@click="setPlayerPosition"
						>
							<span class="text-neutral-400"
								><font-awesome-icon :icon="['fas', 'video']" class="me-1" /> Bring the video here</span
							>
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<h6 class="mb-3">Timeline</h6>

							<div class="meeting-timeline mb-3">
								<p class="mb-1">Agenda items</p>

								<div class="meeting-timeline-bar d-flex bg-neutral-100" style="gap: 2px">
									<div
										v-for="item in meeting.agenda_items.filter(i => !i.parent_id)"
										:key="`timeline-agenda-${item.id}`"
										:class="['bg-neutral-200']"
										style="flex: 1"
										:title="item.title"
									></div>
								</div>
							</div>

							<template v-if="states.speakers === 'loaded'">
								<div
									v-for="speaker in speakers"
									:key="`speaker-timeline-${speaker.id}`"
									class="meeting-timeline mb-3"
								>
									<p class="mb-1">
										<person-link :person="speaker" :avatar="16"></person-link>
									</p>

									<div class="meeting-timeline-bar d-flex bg-neutral-50">
										<template v-for="line in transcript">
											<div
												v-if="line.person_id === speaker.id"
												:key="`sp-${line.id}-${line.person_id}`"
												class="bg-primary-100"
												:title="
													`${timestampToMinutes(line.timestamp)} - ${timestampToMinutes(
														line.timestamp_end
													)}`
												"
												:style="{
													width: `${((line.timestamp_end - line.timestamp) /
														transcriptLength) *
														100}%`,
												}"
											></div>
											<div
												v-else
												:key="`sp-${line.id}`"
												:style="{
													width: `${((line.timestamp_end - line.timestamp) /
														transcriptLength) *
														100}%`,
												}"
											></div>
										</template>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div v-if="isStaff" class="card border-danger mt-4">
						<div class="card-header">
							<h6 class="my-0">Only for HeyGov staff</h6>
						</div>
						<div class="card-body">
							<p class="card-text">
								Run transcript process again:
								<a
									:href="`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-job-start`"
									target="_blank"
									>With Deepgram</a
								>
								&nbsp;&middot;&nbsp;
								<a
									:href="
										`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-job-start?service=revai`
									"
									target="_blank"
									>With RevAI</a
								>
							</p>

							<p class="card-text">
								<button class="btn btn-sm text-danger" @click="removeAudioVideo">
									Remove recording &amp; transcript
								</button>
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="card">
						<div class="card-header">
							<div class="row">
								<div class="col">
									<h6 class="my-0">Transcript</h6>
								</div>
								<div class="col-auto">
									<a
										href="https://townweb.notion.site/Assign-speakers-for-your-meeting-90f25ad01de447cabbe25545d32255f0"
										target="clerk-minutes-help"
										>🙋 How to assign speakers</a
									>
								</div>
							</div>
						</div>
						<div class="card-body p-3">
							<div v-if="meeting.transcript_speakers" class="bg-ai rounded-1 p-3 mb-3">
								<div class="row align-items-center mb-3">
									<div class="col">
										<h5 class="my-0">Assign speakers</h5>
									</div>
									<div class="col-auto">
										<button class="btn-close" @click="meeting.transcript_speakers = null"></button>
									</div>
								</div>

								<p class="mb-2">We detected that these speakers. Does this look right?</p>

								<div class="mb-3">
									<div
										v-for="speaker in meeting.transcript_speakers"
										:key="speaker.speaker"
										class="row align-items-center my-1 on-parent"
									>
										<div class="col-6 col-xl-4 text-neutral-500">
											<label :for="`for-speaker-${speaker.speaker}`">
												Speaker {{ speaker.speaker + 1 }}
												<small
													@click="emitPlaybackToSpeaker(speaker)"
													class="show-on-hover cursor-pointer"
												>
													▶️
												</small>
											</label>
										</div>
										<div class="col-6 col-xl-5 text-neutral-500">
											<select
												:id="`for-speaker-${speaker.speaker}`"
												class="form-select form-select-sm"
												v-model="speaker.id"
											>
												<option :value="null">-</option>
												<option
													v-for="member in staff"
													:key="`${speaker.speaker}-${member.id}`"
													:value="member.id"
												>
													{{ member.name }}
													<span v-if="member.title" style="color: red"
														>({{ member.title }})</span
													>
												</option>
												<option :value="null">➕ Add speaker</option>
											</select>
										</div>
									</div>
								</div>

								<p class="mb-0">
									<button
										class="btn btn-sm btn-primary me-3"
										:disabled="states.assign_speakers === 'loading'"
										@click="transcriptAssignSpeakers(meeting.transcript_speakers)"
									>
										Yes, assign speakers to transcript
									</button>
								</p>
							</div>

							<div v-if="meeting.transcript_job_status === 'started'" class="text-center py-4">
								<p class="mb-2">
									<span class="spinner-border spinner-border-sm"></span> We're transcribing and
									processing the meeting
								</p>
								<p class="card-text text-neutral-400">Shouldn't take more than 5 minutes</p>
							</div>

							<div v-else class="meeting-transcript-lines">
								<div
									v-for="line in transcript"
									:key="line.id"
									class="transcript-line mb-3 on-parent"
									:class="{
										'transcript-line-current':
											meetingPlayer.currentTime >= line.timestamp &&
											meetingPlayer.currentTime < line.timestamp_end,
									}"
								>
									<div class="transcript-line-meta mb-1">
										<span
											class="text-primary-300 cursor-pointer"
											@click="$emit('playerTimestamp', line.timestamp)"
										>
											{{ timestampToMinutes(line.timestamp) }}
										</span>
										<span class="text-neutral-300 mx-1">&middot;</span>
										<div class="d-inline-block dropdown">
											<span
												class="line-speaker"
												type="button"
												:id="`transcript-dropdown-${line.id}`"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<span
													v-if="
														states.speakers === 'loaded' &&
															line.person_id &&
															staff.find(p => p.id === line.person_id)
													"
													class="text-dark"
												>
													<img
														:src="staff.find(p => p.id === line.person_id).photo"
														width="16"
														height="16"
														class="rounded-circle"
														alt="Photo"
													/>
													{{ staff.find(p => p.id === line.person_id).name }}
													<span v-if="staff.find(p => p.id === line.person_id).title">
														({{ staff.find(p => p.id === line.person_id).title }})
													</span>
												</span>
												<code v-else-if="line.person_id" class="text-warning-400"
													>[{{ line.person_id }}]</code
												>
												<span v-else class="text-warning-400"
													>Speaker {{ line.speaker + 1 }}</span
												>
											</span>
											<ul
												class="dropdown-menu"
												:aria-labelledby="`transcript-dropdown-${line.id}`"
											>
												<li>
													<span class="dropdown-item-text text-neutral-400">
														Speaker {{ line.speaker + 1 }} |
														<small
															class="cursor-pointer underline text-primary-400"
															style="text-decoration: underline;"
															data-bs-toggle="modal"
															data-bs-target="#modal-change-speaker-for-line"
															@click="states.changeSpeakerLine = line"
														>
															change speaker number
														</small>
													</span>
												</li>
												<li><hr class="dropdown-divider bg-primary-100 mt-0" /></li>
												<li v-if="line.person_id">
													<span
														class="dropdown-item text-danger-300"
														role="button"
														@click="setTranscriptLineSpeaker($event, line, null)"
													>
														<font-awesome-icon :icon="['fas', 'times']" /> Remove assigned
														speaker
													</span>
												</li>
												<li v-for="person in staff" :key="`tr-line-${line.id}-${person.id}`">
													<span
														class="dropdown-item"
														:class="{ 'text-success-400': person.id === line.person_id }"
														role="button"
														@click="setTranscriptLineSpeaker($event, line, person.id)"
													>
														{{ person.name }}
														<span v-if="person.title" class="me-1 text-primary-400">
															({{ person.title }})
														</span>
														<small v-if="person.id === line.person_id">✓</small>
													</span>
												</li>
												<li>
													<span
														class="dropdown-item"
														role="button"
														data-bs-toggle="modal"
														data-bs-target="#modal-add-speaker"
														@click="newSpeakerNumber = line.speaker"
														>➕ Add speaker</span
													>
												</li>
											</ul>
										</div>

										<span class="show-on-hover">
											<span class="text-neutral-300 mx-1">&middot;</span>
											<button
												class="btn-icon text-danger-200"
												@click="removeTranscriptLine(line)"
											>
												<font-awesome-icon :icon="['fas', 'trash']" />
											</button>
										</span>
									</div>

									<div
										class="transcript-line-editor"
										role="button"
										data-bs-toggle="modal"
										data-bs-target="#modal-edit-line"
										@click="states.editableLine = line"
									>
										{{ line.text }}
									</div>
								</div>

								<!-- TODO add different state for not having transcript lines and loading states -->
								<p v-if="!transcript.length" class="text-center text-neutral-400 my-4">
									Loading transcript..
								</p>
							</div>

							<!-- CHANGE SPEAKER NUMBER/PERSON_ID FOR LINE MODAL -->
							<div
								class="modal fade"
								id="modal-change-speaker-for-line"
								tabindex="-1"
								aria-hidden="true"
								data-bs-backdrop="static"
								data-bs-keyboard="false"
							>
								<div class="modal-dialog">
									<ChangeSpeakerForLineModal
										v-if="states.changeSpeakerLine"
										:options="getLineSpeakerNumbersWithPersons(transcript)"
										:line="states.changeSpeakerLine"
										@save="updateTranscriptLineSpeaker"
										@close="states.changeSpeakerLine = null"
									/>
								</div>
							</div>

							<!-- EDIT TRANSCRIPT LINE MODAL -->
							<div
								class="modal fade"
								id="modal-edit-line"
								tabindex="-1"
								aria-hidden="true"
								data-bs-backdrop="static"
								data-bs-keyboard="false"
							>
								<div class="modal-dialog">
									<EditTranscriptLineForm
										v-if="states.editableLine"
										:line="states.editableLine"
										@save="updateTranscriptLineText"
										@close="states.editableLine = null"
									/>
								</div>
							</div>
						</div>

						<div v-if="transcript.length" class="card-footer justify-content-end">
							<div class="dropdown">
								<button
									class="btn btn-sm btn-outline-primary dropdown-toggle"
									type="button"
									id="download-minutes"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<font-awesome-icon :icon="['fas', 'file-export']" class="me-1" /> Download
									transcript
								</button>
								<ul class="dropdown-menu" aria-labelledby="download-minutes">
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/meetings/${
													meeting.pid
												}/transcript/export/csv?person_id=${auth.id}`
											"
											><font-awesome-icon :icon="['fas', 'file']" class="me-1" /> CSV</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/meetings/${
													meeting.pid
												}/transcript/export/txt?person_id=${auth.id}`
											"
											><font-awesome-icon :icon="['fas', 'file']" class="me-1" /> Text</a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="card">
			<div class="card-body">
				<pre>{{ meeting.transcript_job_status }}</pre>
			</div>
		</div>

		<div class="modal fade" id="modal-recording-url" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">Upload recording by link</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<form @submit.prevent="ev => addRecordingUrl(ev)">
							<p>If you have a recording on YouTube or Zoom, paste the link below.</p>

							<p>ℹ️ The recording link needs to be public, not behind a password.</p>

							<div class="form-row mb-4">
								<label class="form-label" for="recording-url">
									Recording URL
								</label>

								<input
									type="url"
									class="form-control"
									id="recording-url"
									v-model="recordingUrl"
									required
									placeholder="https://www.youtube.com/watch?v=..."
								/>
							</div>

							<p class="card-text text-center">
								<button class="btn btn-primary">Upload recording</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-add-speaker" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">Add speaker</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<form @submit.prevent="createSpeakerAccount">
							<div class="form-row mb-3">
								<label class="form-label" for="speaker-first-name">
									Speaker name <small class="text-danger-400">*</small>
								</label>
								<div class="row">
									<div class="col-6">
										<input
											type="text"
											class="form-control"
											id="speaker-first-name"
											v-model="newSpeaker.first_name"
											required
											placeholder="First name"
										/>
									</div>
									<div class="col-6">
										<input
											type="text"
											class="form-control"
											id="speaker-last-name"
											v-model="newSpeaker.last_name"
											required
											placeholder="Last name"
										/>
									</div>
								</div>
							</div>

							<div class="form-row mb-3">
								<label class="form-label" for="speaker-email">
									Email
									<small class="text-neutral-400 text-lowercase">(optional)</small>
								</label>
								<input
									type="email"
									class="form-control form-control-sm"
									id="speaker-email"
									v-model="newSpeaker.email"
									placeholder="Valid email address"
								/>
							</div>

							<div class="form-row mb-3">
								<label class="form-label" for="speaker-department">
									Department <small class="text-neutral-400 text-lowercase">(optional)</small>
								</label>

								<div class="row">
									<div class="col">
										<select
											class="form-select form-select-sm"
											id="speaker-department"
											v-model="newSpeaker.department_id"
										>
											<option :value="null">No department</option>
											<option
												v-for="department in activeDepartments"
												:key="department.id"
												:value="department.id"
											>
												{{ department.name }}
											</option>
										</select>
									</div>
									<div v-if="newSpeaker.department_id" class="col-6">
										<select
											class="form-select form-select-sm"
											id="speaker-role"
											v-model="newSpeaker.role"
											required
										>
											<option value="ADMIN">HeyGov Admin</option>
											<option value="EDITOR">Department Admin</option>
											<option value="WORKER">Department Member</option>
										</select>
									</div>
								</div>
							</div>

							<div v-if="newSpeaker.department_id" class="form-row mb-3">
								<label class="form-label" for="speaker-title">
									Title
									<small class="text-neutral-400 text-lowercase">(optional)</small>
								</label>
								<input
									type="text"
									class="form-control"
									id="speaker-title"
									v-model="newSpeaker.title"
									placeholder="Example: Mayor"
								/>
							</div>

							<p>
								We'll create a named speaker and assign it to all transcript lines with
								<code>Speaker {{ newSpeakerNumber + 1 }}</code
								>.
							</p>

							<p class="card-text text-center">
								<button class="btn btn-primary" :disabled="states.new_speaker === 'loading'">
									Add speaker
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.btn-icon {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
}

.meeting-timeline {
	.meeting-timeline-bar {
		border-radius: 0.3rem;

		div {
			height: 16px;
		}

		div:first-child {
			border-radius: 0.3rem 0 0 0.3rem;
		}

		div:last-child {
			border-radius: 0 0.3rem 0.3rem 0;
		}
	}
}

.transcript-line {
	.line-speaker {
		border-radius: 3px;
		padding: 2px 0.3rem;

		&:hover {
			background-color: $primary-50;
		}
	}

	.transcript-line-editor {
		cursor: pointer;
		border-radius: 0.5rem;
		padding: 0.5rem;
		background-color: $neutral-50;

		&:hover {
			background-color: $neutral-100;
		}
	}

	textarea {
		background-color: $neutral-100;
		padding: 0.5rem;
		border-radius: 0.5rem;
		resize: none;

		&:focus-visible {
			outline: 0;
		}
	}

	&.transcript-line-current {
		.transcript-line-editor {
			background-color: $primary-50;
		}
	}

	.transcript-edit-actions {
		border-radius: 0 0 0.5rem 0.5rem;
	}
}
</style>

<script>
import { Modal } from 'bootstrap'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { uniq, uniqBy } from 'lodash-es'

import heyGovApi, { hgApi } from '@/api.js'
import { handleResponseError, getPublicFileUrl } from '@/utils.js'
import { timestampToMinutes, validateEmail } from '@/lib/strings'

import PersonLink from '@/components/PersonLink.vue'
import EditTranscriptLineForm from './EditTranscriptLineForm/EditTranscriptLineForm.vue'
import ChangeSpeakerForLineModal from './ChangeSpeakerForLineModal/ChangeSpeakerForLineModal.vue'

export default {
	name: 'MeetingTranscript',
	components: { PersonLink, EditTranscriptLineForm, ChangeSpeakerForLineModal },
	metaInfo() {
		return {
			title: `Transcript for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			states: {
				speakers: 'loading',
				new_speaker: 'idle',
				meeting_audio_video_progress: 0,
				assign_speakers: 'idle',
				editableLine: null,
				changeSpeakerLine: null,
			},
			transcript: [],
			transcriptionStatusTimer: null,
			staff: [],
			recordingUrl: '',
			$modalRecordingUrl: null,

			$modalAddSpeaker: null,
			newSpeakerNumber: null,
			newSpeaker: this.getEmptySpeaker(),
		}
	},

	computed: {
		...mapGetters(['auth', 'isStaff', 'activeDepartments']),
		...mapState(['apiUrl', 'j']),
		speakers() {
			const linesWithPersonId = this.transcript.filter(l => l.person_id)
			const lineSpeakers = linesWithPersonId.map(line =>
				this.staff.find(speaker => speaker.id === line.person_id)
			)
			return uniq(lineSpeakers)
		},
		transcriptLength() {
			return this.transcript.at(-1).timestamp_end
		},
	},

	created() {
		this.loadJurisdictionSpeakers()

		if (this.meeting.transcript_job_status === 'transcribed') {
			this.loadTranscript()
		} else if (['started', 'uploading'].includes(this.meeting.transcript_job_status)) {
			this.transcriptionStatusTimer = setInterval(() => {
				this.checkTranscriptJobStatus()
			}, 10000)
		}
	},

	mounted() {
		if (this.meetingPlayer.position === 'default') {
			this.setPlayerPosition()
		}

		this.$modalRecordingUrl = new Modal(document.getElementById('modal-recording-url'))
		this.$modalAddSpeaker = new Modal(document.getElementById('modal-add-speaker'))
		this.$modalEditLine = new Modal(document.getElementById('modal-edit-line'))
		this.$modalChangeSpeaker = new Modal(document.getElementById('modal-change-speaker-for-line'))
	},
	methods: {
		getPublicFileUrl,
		timestampToMinutes,
		uniq,

		getLineSpeakerNumbersWithPersons(transcriptLines) {
			return uniqBy(
				transcriptLines.map(line => ({
					number: line.speaker,
					person: this.staff.find(s => s.id === line.person_id),
				})),
				'number'
			)
		},

		emitPlaybackToSpeaker(speaker) {
			const firstLine = this.transcript.find(l => l.speaker === speaker.speaker)
			if (!firstLine || !firstLine.timestamp) {
				alert('timestamp not found for speaker')
				return
			}
			this.$emit('playerTimestamp', Math.round(firstLine.timestamp, 10))
		},

		async loadJurisdictionSpeakers() {
			this.states.speakers = 'loading'
			const resp = await hgApi(`${this.j.slug}/meetings/speakers`)
			const people = await resp.json()
			this.staff = people
			this.states.speakers = 'loaded'
		},

		loadTranscript() {
			hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/transcript`)
				.then(response => response.json())
				.then(lines => {
					this.transcript.push(
						...lines.map(line => {
							line._editing = false
							line._text = line.text
							return line
						})
					)

					//this.resizeTranscriptEditors()
				})
				.catch(handleResponseError('Failed to load transcript ({error})'))
		},

		checkTranscriptJobStatus() {
			console.log('checking transribing job status')

			heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}`)
				.then(({ data }) => {
					if (data.transcript_job_status === 'transcribed') {
						this.meeting.transcript_job_status = data.transcript_job_status
						this.meeting.transcript_speakers = data.transcript_speakers
						this.meeting.video_file_path = data.video_file_path
						this.loadTranscript()

						clearInterval(this.transcriptionStatusTimer)
						Vue.toasted.success('Meeting transcript is ready')
					} else if (data.transcript_job_status === 'error') {
						this.meeting.transcript_job_status = data.transcript_job_status
						this.meeting.video_public_url = null

						clearInterval(this.transcriptionStatusTimer)
						Vue.toasted.error("Couldn't upload or process the recording")
					}
				})
				.catch(handleResponseError(`Couldn't check transcript status ({error})`))
		},

		// upload audio/video
		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},

		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},

		dropMeetingAudioVideo(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				this.$emit('uploadMeetingAudioVideo', event.dataTransfer.files[0])
			} else {
				alert('No files dropped 🤷')
			}
		},

		handleMeetingAudioVideo($event) {
			this.$emit('uploadMeetingAudioVideo', $event.target.files[0])
		},

		addRecordingUrl() {
			if (!URL.canParse(this.recordingUrl)) {
				Vue.toasted.error('Invalid URL')
				return
			}

			const parsedUrl = new URL(this.recordingUrl)

			const isYoutubeUrl = ['youtube.com', 'www.youtube.com', 'youtu.be'].includes(parsedUrl.hostname)
			const isVimeoUrl = parsedUrl.hostname.endsWith('video.com')
			const isZoomUrl = parsedUrl.hostname.endsWith('zoom.us')

			if (!isYoutubeUrl && !isVimeoUrl && !isZoomUrl) {
				Vue.toasted.error('Only YouTube, Vimeo and Zoom recordings are supported')
				return
			}

			const fields = {
				video_public_url: this.recordingUrl,
				transcript_job_status: 'uploading',
			}

			this.$emit('updateMeeting', { fields, message: 'Recording upload is started' })

			this.transcriptionStatusTimer = setInterval(() => {
				this.checkTranscriptJobStatus()
			}, 10000)

			this.$modalRecordingUrl.hide()
			this.recordingUrl = ''
		},

		removeAudioVideo() {
			if (confirm(`For sure remove all this?`)) {
				heyGovApi.delete(`${this.j.slug}/meetings/${this.meeting.pid}/audio-video-transcript`).then(() => {
					this.meeting.transcript_job_status = 'not-started'
					this.meeting.transcript_speakers = null
					this.meeting.audio_public_url = null
					this.meeting.audio_file_path = null
					this.meeting.video_public_url = null
					this.meeting.video_file_path = null
					this.transcript = []
				}, handleResponseError("Couldn't remove audio/video ({error})"))
			}
		},

		autoResizeTextarea(textarea) {
			textarea.style.height = 'auto'
			textarea.style.height = textarea.scrollHeight + 'px'
		},

		resizeTranscriptEditors() {
			this.$nextTick(() => {
				this.$el.querySelectorAll('.transcript-line-editor textarea').forEach(textarea => {
					textarea.style.height = 'auto'
					textarea.style.height = textarea.scrollHeight + 'px'
				})
			})
		},

		// transcriptAssignSpeakers assigns person id to speaker number
		async transcriptAssignSpeakers(speakers) {
			this.states.assign_speakers = 'loading'

			this.transcript.forEach(line => {
				const speaker = speakers.find(s => s.speaker === line.speaker)

				if (speaker) {
					line.person_id = speaker.id
				}
			})

			try {
				const response = await hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/assign-speakers`, {
					json: speakers,
				})
				if (response.ok) {
					this.meeting.transcript_speakers = null

					Vue.toasted.success('Speakers assigned to transcript')
				} else {
					Vue.toasted.error('Error assigning speakers to transcript')
				}
			} catch (error) {
				Vue.toasted.error('Error assigning speakers to transcript')
			} finally {
				this.states.assign_speakers = 'idle'
			}
		},

		setTranscriptLineSpeaker($event, line, personId) {
			if (line.person_id !== personId) {
				this.transcriptAssignSpeakers([{ speaker: line.speaker, id: personId }])
			}
		},

		async createSpeakerAccount() {
			if (this.newSpeaker.email) {
				try {
					this.newSpeaker.email = validateEmail(this.newSpeaker.email)
				} catch (err) {
					Vue.toasted.error(`Invalid email: ${err.message}`)
					return
				}
			}

			this.states.new_speaker = 'loading'

			const errHandler = handleResponseError(`Couldn't add speaker ({error})`)

			if (this.newSpeaker.department_id) {
				try {
					const { data } = await heyGovApi.post(`${this.j.slug}/departments/people`, this.newSpeaker)

					this.staff.push({
						id: data.person_id,
						email: data.email,
						first_name: data.first_name,
						last_name: data.last_name,
					})

					this.transcriptAssignSpeakers([
						{
							speaker: this.newSpeakerNumber,
							id: data.person_id,
						},
					])

					this.states.new_speaker = 'idle'
					this.$modalAddSpeaker.hide()
				} catch (error) {
					errHandler(error)
				}
			} else {
				try {
					const req = {
						first_name: this.newSpeaker.first_name.trim(),
						last_name: this.newSpeaker.last_name.trim(),
						email: this.newSpeaker.email,
						// we don't set title for person without department
					}

					const { data } = await heyGovApi.post(`${this.j.slug}/people`, req)

					this.staff.push(data)

					this.transcriptAssignSpeakers([
						{
							speaker: this.newSpeakerNumber,
							id: data.id,
						},
					])

					this.states.new_speaker = 'idle'
					this.$modalAddSpeaker.hide()
				} catch (error) {
					errHandler(error)
				}
			}

			this.newSpeaker = this.getEmptySpeaker()
		},

		getEmptySpeaker() {
			return {
				first_name: '',
				last_name: '',
				email: '',
				title: '',
				department_id: null,
				role: null,
			}
		},

		transcriptSearch() {
			console.log('todo', 'transcriptSearch')
		},

		updateTranscriptLineText(updatedLine) {
			const text = updatedLine.text.trim()

			if (text.length) {
				heyGovApi
					.put(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/${updatedLine.id}`, { text })
					.then(() => {
						updatedLine._text = updatedLine.text = text
						updatedLine._editing = false
					}, handleResponseError("Couldn't save transcript line ({error})"))
			} else {
				alert(`Can't be empty, maybe delete the line?`)
			}

			// @ts-ignore
			const existingLine = this.transcript.find(line => line.id === updatedLine.id)
			if (existingLine) {
				// @ts-ignore
				existingLine.text = text
			}

			this.states.editableLine = null
		},

		async updateTranscriptLineSpeaker({ line, number, person }) {
			try {
				await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/${line.id}`, {
					speaker: number,
					person_id: person?.id || null,
				})
				line.speaker = number
				line.person_id = person?.id
			} catch (error) {
				handleResponseError("Couldn't save transcript line ({error})")(error)
			} finally {
				this.states.changeSpeakerLine = null
			}
		},

		removeTranscriptLine(transcriptLine) {
			if (confirm('Are you sure you want to delete this transcript line?')) {
				heyGovApi
					.delete(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/${transcriptLine.id}`)
					.then(() => {
						this.transcript = this.transcript.filter(line => line.id !== transcriptLine.id)
					}, handleResponseError("Couldn't delete transcript line ({error})"))
			}
		},

		setPlayerPosition() {
			const $player = this.$refs.meetingOverviewPlayer

			if ($player) {
				const $playerPosition = $player.getBoundingClientRect()

				this.$emit('playerStyles', {
					zIndex: 99,
					position: 'absolute',
					width: `${$playerPosition.width}px`,
					top: `${$playerPosition.top + window.scrollY}px`,
					left: `${$playerPosition.left}px`,
					right: 'auto',
				})
			}
		},

		urlPart(url, part) {
			return URL.parse(url)[part] || ''
		},
	},
	beforeDestroy() {
		clearInterval(this.transcriptionStatusTimer)
	},
}
</script>
