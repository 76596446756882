import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
import vueDebounce from 'vue-debounce'
import GmapVue from 'gmap-vue'
import './registerServiceWorker'
import '@capacitor/core'
import { App as CapApp } from '@capacitor/app'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueEasyLightbox from 'vue-easy-lightbox'
import * as Sentry from '@sentry/vue'
import VueMeta from 'vue-meta'
import { fromUnixTime, parseISO, isValid, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import {
	faUserSecret,
	faSignOutAlt,
	faSignInAlt,
	faQuestion,
	faVoteYea,
	faChartBar,
	faCity,
	faCog,
	faKey,
	faShieldAlt,
	faCode,
	faChartPie,
	faUser,
	faUserFriends,
	faArrowLeft,
	faChevronLeft,
	faIdBadge,
	faAlignCenter,
	faTrash,
	faClone,
	faEdit,
	faArrowsAlt,
	faInfo,
	faClock,
	faMoneyBill,
	faListOl,
	faCalendarAlt,
	faFilePdf,
	faFire,
	faForward,
	faTimesCircle,
	faGavel,
	faPlus,
	faPlusCircle,
	faPiggyBank,
	faPencilAlt,
	faTimes,
	faInfoCircle,
	faTag,
	faList,
	faLock,
	faLink,
	faMicrochip,
	faUniversity,
	faVideo,
	faParagraph,
	faHeading,
	faFont,
	faCaretDown,
	faAngleRight,
	faDotCircle,
	faDollarSign,
	faCheckCircle,
	faCheckSquare,
	faPhoneAlt,
	faAt,
	faMapPin,
	faAddressCard,
	faCalendarCheck,
	faSignature,
	faTable,
	faThLarge,
	faUpload,
	faWallet,
	faCloudUploadAlt,
	faChevronDown,
	faChevronUp,
	faUserTie,
	faUndo,
	faClipboardList,
	faSearch,
	faFileAlt,
	faMoneyCheck,
	faMoneyBillWave,
	faMoneyCheckAlt,
	faPaperPlane,
	faPaw,
	faCocktail,
	faHandPaper,
	faTree,
	faBan,
	faHome,
	faBars,
	faComment,
	faCommentDollar,
	faCheck,
	faStickyNote,
	faCopy,
	faFile,
	faColumns,
	faChevronRight,
	faBox,
	faMapMarkedAlt,
	faEyeSlash,
	faEllipsisV,
	faEllipsis,
	faSailboat,
	faFilterCircleDollar,
	faImage,
	faVectorSquare,
	faHashtag,
	faPen,
	faFileExport,
	faArrowUpRightFromSquare,
	faSort,
	faFaucetDrip,
	faQrcode,
	faRoad,
	faLandmarkFlag,
	faHandshakeAngle,
	faPercent,
	faMap,
	faBurst,
	faCashRegister,
	faFileImport,
	faReceipt,
	faCalendarDays,
	faArrowsRotate,
	faCircle,
	faAnglesRight,
	faAnglesLeft,
	faArrowsLeftRight,
	faShare,
	faFileAudio,
	faFileVideo,
	faQuestionCircle,
	faT,
	faStamp,
	faBuildingColumns,
	faUserGroup,
	faHourglass,
	faHand,
	faRobot,
	faThumbsUp,
	faCheckDouble,
	faMagnifyingGlass,
	faFilter,
	faBold,
	faItalic,
	faUnderline,
	faQuoteRight,
	faStrikethrough,
	faListUl,
	faRulerHorizontal,
	faRedo,
	faMagicWandSparkles,
	faTextHeight,
	faMicrophoneLines,
	faMinimize,
	faPalette,
	faSmile,
	faBriefcase,
} from '@fortawesome/free-solid-svg-icons'
import {
	faCcVisa,
	faCcMastercard,
	faCcApplePay,
	faCcDinersClub,
	faCcAmex,
	faCcDiscover,
	faCcStripe,
	faStripeS,
	faPaypal,
	faCcJcb,
} from '@fortawesome/free-brands-svg-icons'
import { faBell, faCreditCard, faSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { formatAddress, numberToCurrency } from './utils'
library.add(
	faUserSecret,
	faPaypal,
	faSquare,
	faBell,
	faSignOutAlt,
	faSignInAlt,
	faQuestion,
	faQuestionCircle,
	faVoteYea,
	faChartBar,
	faCity,
	faCog,
	faKey,
	faBurst,
	faShieldAlt,
	faCode,
	faChartPie,
	faUser,
	faUserFriends,
	faStamp,
	faArrowLeft,
	faChevronLeft,
	faIdBadge,
	faAlignCenter,
	faReceipt,
	faTrash,
	faClone,
	faBuildingColumns,
	faEdit,
	faArrowsAlt,
	faInfo,
	faSort,
	faT,
	faRoad,
	faMicrophoneLines,
	faLandmarkFlag,
	faCheck,
	faHandshakeAngle,
	faPercent,
	faMap,
	faMagicWandSparkles,
	faImage,
	faClock,
	faMagnifyingGlass,
	faRobot,
	faShare,
	faMoneyBill,
	faListOl,
	faCalendarAlt,
	faHand,
	faFilePdf,
	faFileAudio,
	faFileVideo,
	faFire,
	faFaucetDrip,
	faFileExport,
	faUserGroup,
	faFilterCircleDollar,
	faFilter,
	faBold,
	faItalic,
	faStrikethrough,
	faCode,
	faListOl,
	faListUl,
	faUnderline,
	faQuoteRight,
	faRulerHorizontal,
	faUndo,
	faTextHeight,
	faRedo,
	faForward,
	faTimesCircle,
	faGavel,
	faArrowUpRightFromSquare,
	faPlus,
	faPlusCircle,
	faPiggyBank,
	faPencilAlt,
	faTimes,
	faTag,
	faList,
	faLock,
	faMicrochip,
	faLink,
	faUniversity,
	faCreditCard,
	faVideo,
	faQrcode,
	faUserSecret,
	faParagraph,
	faHeading,
	faFont,
	faCaretDown,
	faAngleRight,
	faDotCircle,
	faDollarSign,
	faCheckCircle,
	faCheckSquare,
	faPhoneAlt,
	faCalendarAlt,
	faAt,
	faFileImport,
	faMapPin,
	faMapMarkedAlt,
	faAddressCard,
	faCalendarCheck,
	faTable,
	faThLarge,
	faSignature,
	faUpload,
	faWallet,
	faCloudUploadAlt,
	faChevronDown,
	faChevronUp,
	faUserTie,
	faUndo,
	faClipboardList,
	faSearch,
	faFileAlt,
	faMoneyCheck,
	faMoneyBillWave,
	faMoneyCheckAlt,
	faCcVisa,
	faCcMastercard,
	faCcApplePay,
	faCcAmex,
	faCcDiscover,
	faCcJcb,
	faCcDinersClub,
	faStripeS,
	faCcStripe,
	faPaperPlane,
	faPaw,
	faCocktail,
	faHandPaper,
	faTree,
	faBan,
	faHome,
	faBars,
	faComment,
	faCommentDollar,
	faCheck,
	faStickyNote,
	faCopy,
	faColumns,
	faFile,
	faChevronRight,
	faBox,
	faEyeSlash,
	faEllipsisV,
	faEllipsis,
	faSailboat,
	faInfoCircle,
	faVectorSquare,
	faHashtag,
	faPen,
	faCashRegister,
	faCalendarDays,
	faArrowsRotate,
	faThumbsUp,
	faCircle,
	faCheckDouble,
	faAnglesRight,
	faAnglesLeft,
	faHourglass,
	faArrowsLeftRight,
	faMinimize,
	faPalette,
	faSmile,
	faBriefcase
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Sentry.init({
	Vue,
	dsn: process.env.VUE_APP_SENTRY_DSN,
	environment: process.env.VUE_APP_ENV || 'development',
	release: store.state.appVersion,
	integrations: [
		/* Sentry.feedbackIntegration({
			colorScheme: 'system',
			autoInject: false,
		}), */
		Sentry.replayIntegration({
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false,
		}),
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
})

Vue.use(Toasted, {
	duration: 4000,
	position: 'bottom-right',
	router,
})

Vue.use(vueDebounce)
Vue.use(VueEasyLightbox)
Vue.use(VueMeta)

Vue.use(GmapVue, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
		v: 'weekly',
		libraries: 'marker,places,visualization', // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)
		//map_ids: process.env.VUE_APP_GOOGLE_MAPS_MAP_ID,
		region: 'US',
		language: 'en',
	},
	autobindAllEvents: false,
	installComponents: true,
})

Vue.filter('currency', function(value, currency, locale, removeZeros) {
	if (value === undefined) return ''

	let currencyValue = numberToCurrency(value, currency, locale)

	if (removeZeros) {
		currencyValue = currencyValue.replace(/\.00$/, '')
	}

	return currencyValue
})

Vue.filter('dateLocal', function(value, locale = 'en-US', options = {}) {
	if (!value) return ''
	if (typeof value === 'number') {
		value = fromUnixTime(value)
	} else {
		value = parseISO(value)
	}
	if (!isValid(value)) return ''
	return value.toLocaleDateString(locale, options)
})

Vue.filter('dateTimeLocal', function(value, locale = 'default', options = {}) {
	if (!value) return ''

	if (typeof value === 'number') {
		value = fromUnixTime(value)
	} else if (!(value instanceof Date)) {
		value = new Date(value)
	}

	return value.toLocaleString(locale, {
		dateStyle: 'medium',
		timeStyle: 'short',
		...options,
	})
})

Vue.filter('dateToNow', function(value, strict) {
	if (!value) return ''

	if (typeof value === 'number') {
		value = fromUnixTime(value)
	} else if (!(value instanceof Date)) {
		value = new Date(value)
	}

	return strict
		? formatDistanceToNowStrict(new Date(value), { addSuffix: true })
		: formatDistanceToNow(new Date(value), { addSuffix: true })
})

Vue.filter('timeLocal', function(value, options = {}) {
	if (!value) return ''

	if (typeof value === 'number') {
		value = fromUnixTime(value)
	} else if (!(value instanceof Date)) {
		value = new Date(value)
	}

	return value.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit', ...options })
})

Vue.filter('formatAddress', function(value, options = {}) {
	if (!value) return ''

	return formatAddress(value, options)
})

Vue.directive('auto-scroll', {
	inserted(el) {
		el.scrollTop = el.scrollHeight
	},
	componentUpdated(el) {
		el.scrollTop = el.scrollHeight
	},
})

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')

// Handle app links
// Redirect to correct view on app open
CapApp.addListener('appUrlOpen', function(data) {
	// Example url: https://app.heygov.com/heyville.org/polls
	// path = /heyville.org/polls
	const path = data.url.split('app.heygov.com').pop()

	if (path) {
		router.push({ path })
	}
})
