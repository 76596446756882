<template>
	<div class="page-quick-pay-billing-account">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/quick-pay`">Payments portal</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
			<router-link :to="`/${j.slug}/quick-pay/payments`">Payments</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
			Payment details
		</h3>

		<div class="row justify-content-center mb-3">
			<div class="col-lg-8 col-xl-6">
				<div v-if="states.payment === 'error'" class="alert alert-danger">
					⚠️ An error occurred while loading the payment details. Please try again later.
				</div>
				<div v-else-if="states.payment === 'loading'" class="text-center">
					<span class="spinner-border spinner-border-sm"></span> Loading payment details..
				</div>

				<div
					v-else-if="['succeeded', 'requires_capture', 'canceled', 'processing'].includes(payment.status)"
					class="card"
				>
					<div class="card-body">
						<payment-status :payment="payment" class="float-end"></payment-status>

						<p class="mb-2">
							Date: <strong>{{ payment.created_at | dateLocal }}</strong>
						</p>
						<p v-if="payment.location" class="mb-2">
							Billing/Property address: <span>{{ payment.location | formatAddress }}</span>
						</p>
						<p>
							Payment for:
							<!-- <strong>{{ paymentDept.name }}</strong> | -->
							<entity-link v-if="payment.source.includes('_')" :entity="payment.source">{{
								payment.source
							}}</entity-link>
							<span v-else>{{ payment.description }}</span>
						</p>

						<p class="mb-2">
							Amount: <strong>{{ payment.amount | currency }}</strong>
						</p>
						<p class="mb-2">
							Payment fee: <strong>{{ payment.fee | currency }}</strong>
						</p>
						<p v-if="payment.refunded" class="mb-2 text-primary-400">
							Refunded: <strong>{{ payment.refunded | currency }}</strong>
						</p>
						<p class="lead mb-2">
							Total: <strong>{{ (payment.total_paid - payment.refunded) | currency }}</strong>
						</p>
						<p v-if="payment.payment_method" class="card-text">
							Paid with: <payment-method :payment-method="payment.payment_method" />
						</p>

						<div v-if="payment.status === 'processing'" class="alert alert-info text-darl">
							<div class="row align-items-center gx-3">
								<div class="col-auto">
									<h2 class="my-0">ℹ️</h2>
								</div>
								<div class="col">
									Thank you for your payment! We are awaiting confirmation from your bank, and you
									will be notified by email about the payment outcome.
								</div>
							</div>
						</div>
						<div v-else-if="states.justPaid" class="alert alert-success text-success-400">
							<div class="row align-items-center gx-3">
								<div class="col-auto">
									<h2 class="my-0">✅</h2>
								</div>
								<div class="col">
									Thank you for your payment! We emailed you a copy of your transaction to
									<code>{{ account.email }}</code
									>.
								</div>
							</div>
						</div>
						<div v-else-if="payment.status === 'requires_capture'" class="alert alert-info">
							The amount of <strong>{{ payment.total_paid | currency }}</strong> is only authorized from
							your card, but not captured yet.
						</div>
						<div v-else-if="payment.status === 'canceled'" class="alert alert-info">
							This payment was canceled, no amount was taken from your card.
						</div>
					</div>

					<div class="card-footer d-flex justify-content-between">
						<a
							v-if="['succeeded', 'canceled'].includes(payment.status)"
							:href="`${apiUrl}${j.slug}/payments/${payment.uuid}/receipt`"
							target="_blank"
							class="btn btn-sm"
						>
							<font-awesome-icon :icon="['fas', 'file-pdf']" class="text-danger-400" /> View receipt
						</a>
						<router-link v-else :to="`/${j.slug}/quick-pay/payments`" class="btn btn-sm"
							>← Back to payments</router-link
						>

						<router-link :to="`/${j.slug}/quick-pay`" class="btn btn-sm btn-outline-primary"
							>Make another payment</router-link
						>
					</div>
				</div>
				<form
					@submit.prevent="paymentSubmit"
					v-else-if="['requires_payment_method', 'pending'].includes(payment.status)"
				>
					<div class="card">
						<div class="card-body">
							<p v-if="payment.location" class="mb-2">
								Billing/Property address: <span>{{ payment.location | formatAddress }}</span>
							</p>
							<p>
								Payment for:
								<!-- <strong>{{ paymentDept.name }}</strong> | -->
								<entity-link v-if="payment.source.includes('_')" :entity="payment.source">{{
									payment.source
								}}</entity-link>
								<span v-else>{{ payment.description }}</span>
							</p>

							<p class="mb-2">
								Amount: <strong>{{ payment.amount | currency }}</strong>
							</p>
							<p class="mb-2">
								Payment fee:
								<span :class="payment.fee ? 'fw-bolder' : 'text-neutral-300'">{{
									payment.fee | currency
								}}</span>
								<small
									v-if="payment.fee"
									class="text-muted cursor-pointer ms-2"
									@click="states.feeInfo = !states.feeInfo"
									><span v-if="extraDisclaimers.includes(j.slug)" class="me-1">non-refundable</span>
									<font-awesome-icon :icon="['fas', 'info-circle']"
								/></small>
							</p>
							<p v-if="states.feeInfo" class="text-muted mb-2">
								Payment fees are non-refundable. This fee is paid directly to the payment processor.
								Please refer to your municipality for more info.
							</p>
							<p class="lead mb-3">
								Total: <strong class="fw-bolder">{{ payment.total_paid | currency }}</strong>
							</p>

							<div v-if="!auth">
								<hr />
								<p class="mb-3 text-center">First, what's your email address?</p>

								<login-form
									:redirectUrl="`/${j.slug}/quick-pay/payments/${payment.uuid}`"
									:allowRegistration="true"
									loginMessage="Great, logged in to your account"
									registerMessage="Thanks for filling the email, now you can pay"
								></login-form>
							</div>
							<template v-else>
								<div id="payment-element" :class="`payment-element-${payment.platform}`">
									<!--Payment Element from Stripe, JustiFi-->
								</div>

								<div v-if="payment.paymentIntent.payment_method_types.includes('us_bank_account')">
									<template v-if="paymentMethods.length">
										<p class="mb-1 subtitle">Your bank accounts:</p>

										<ul class="list-group text-start rounded-1 mb-3">
											<li
												v-for="bankAccount in paymentMethods"
												:key="bankAccount.id"
												class="list-group-item"
											>
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="radio"
														:id="`bank-account-${bankAccount.id}`"
														:value="bankAccount.id"
														v-model="paymentMethodSelected"
													/>
													<label
														class="form-check-label"
														:for="`bank-account-${bankAccount.id}`"
														>{{ bankAccount.us_bank_account.routing_number }} ****
														{{ bankAccount.us_bank_account.last4 }}
														<span class="badge bg-light text-dark">{{
															bankAccount.us_bank_account.bank_name
														}}</span>
														<span
															v-if="!bankAccount.livemode"
															class="badge bg-warning-50 text-warning ms-1"
															>Test</span
														></label
													>
												</div>
											</li>
										</ul>
									</template>

									<template v-if="setupIntent">
										<button
											v-if="setupIntent.status === 'requires_payment_method'"
											class="btn btn-sm btn-outline-primary"
											type="button"
											:disabled="!setupIntent"
											@click="addStripeBankAccount"
										>
											{{
												paymentMethods.length ? 'Add another bank account' : 'Add bank account'
											}}
										</button>
										<p v-else-if="setupIntent.status === 'requires_confirmation'">
											<span class="spinner-border spinner-border-sm"></span> Confirming your bank
											account
										</p>
										<div v-else-if="setupIntent.status === 'requires_action'">
											<div
												v-if="setupIntent.next_action.type === 'verify_with_microdeposits'"
												class="alert alert-info"
											>
												<p class="mb-2">
													<strong>This bank account needs additional confirmation.</strong>
												</p>
												<p class="mb-1">
													We sent a small deposit with a confirmation code to your bank
													account, and an email with instructions.
												</p>
												<p class="mb-0">
													Once you receive the transfer and confirm your bank account, return
													to this page to complete the payment.
												</p>
												<!-- <p>Expect a $0.01 deposit to the account ending in ••••1113 in 1-2 business days and an email with additional instructions to verify your bank account.</p> -->
											</div>
											<pre v-else>{{ setupIntent.next_action }}</pre>
										</div>
										<code v-else>{{ setupIntent.status }}</code>
									</template>

									<div v-if="paymentMethodSelected" class="alert alert-warning text-dark mt-3">
										Ensure that this account is valid and has sufficient funds. Failed ACH Direct
										Debit payments incur a $4.00 fee.
									</div>
								</div>

								<div
									v-if="paymentError"
									id="payment-element-error"
									class="alert alert-danger mt-3 mb-0"
								>
									{{ paymentError }}
								</div>

								<div
									v-if="payment.platform === 'bridgepay'"
									id="payment-element-error-bridgepay"
									class="alert alert-danger mt-3 mb-0"
									style="display: none;"
								></div>
							</template>
						</div>
						<div class="card-footer d-flex align-items-center justify-content-between">
							<router-link :to="`/${j.slug}/quick-pay/payments`" class="btn btn-sm"
								>← Back to payments</router-link
							>

							<a
								v-if="auth && payment.platform === 'municipay'"
								:href="payment.metadata.url"
								class="btn btn-primary px-5"
							>
								Pay {{ payment.total_paid | currency }}
							</a>
							<a
								v-else-if="auth && payment.platform === 'paygov'"
								:href="`#`"
								class="btn btn-primary px-5 disabled"
							>
								Pay {{ payment.total_paid | currency }}
							</a>
							<button
								v-else-if="
									auth &&
										payment.platform === 'stripe' &&
										payment.paymentIntent.payment_method_types.includes('us_bank_account')
								"
								class="btn btn-primary px-5"
								:disabled="!paymentMethodSelected"
							>
								Pay {{ payment.total_paid | currency }}
							</button>
							<button
								v-else-if="auth"
								class="btn btn-primary px-5"
								:disabled="!states.paymentElementReady"
							>
								Pay {{ payment.total_paid | currency }}
							</button>
						</div>
					</div>
				</form>

				<div v-else class="card">
					<div class="card-body">
						Invalid payment status <code>{{ payment.status }}</code>
						<pre>{{ payment }}</pre>
					</div>
				</div>

				<!-- <pre>{{ payment }}</pre> -->
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.payment-element-bridgepay {
	max-height: 120px;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { appUrl } from '@/utils.js'
import { bridgePayAccounts } from '@/actions/payments.js'

import LoginForm from '@/components/LoginForm.vue'
import EntityLink from '@/components/EntityLink.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'

export default {
	name: 'QuickPayPayment',
	metaInfo: {
		title: 'Payments',
	},
	components: {
		EntityLink,
		LoginForm,
		PaymentMethod,
		PaymentStatus,
	},
	data() {
		return {
			states: {
				payment: 'loading',
				paymentElementStarted: false,
				paymentElementReady: false,
				feeInfo: false,
				justPaid: this.$route.query.justPaid || false,
			},
			payment: null,
			paymentError: '',
			paymentMethods: [],
			paymentMethodSelected: null,
			setupIntent: null, // for adding Stripe payment methods (us_bank_account)

			stripe: null,
			stripeElements: null,
			justifi: null,
		}
	},
	computed: {
		...mapState([
			'j',
			'account',
			'apiUrl',
			'appVersion',
			'departments',
			'extraDisclaimers',
			'stripePublishableKey',
			'stripePublishableTestKey',
		]),
		...mapGetters(['auth']),
		paymentDept() {
			let dept = null

			if (this.payment && this.departments?.length) {
				dept = this.departments.find(d => d.id === this.payment.department_id)
			}

			return dept
		},
	},
	created() {
		this.$store.dispatch('loadDepartments').then(() => {
			if (this.payment && ['requires_payment_method', 'pending'].includes(this.payment.status)) {
				this.startPaymentElement()
			}
		})

		this.loadPayment()

		this.$store.dispatch('getForms')
		this.$store.dispatch('getVenues')
	},
	mounted() {
		if (!('Stripe' in window)) {
			let StripeScript = document.createElement('script')
			StripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
			document.head.appendChild(StripeScript)
		}

		if (!('JustiFiPaymentsJS' in window)) {
			let JustifiScript = document.createElement('script')
			JustifiScript.setAttribute('src', 'https://js.justifi.ai/payments/v1/payments.js')
			document.head.appendChild(JustifiScript)
		}

		if (!('TokenPay' in window)) {
			const tokenPaySrc = this.j.testmode
				? 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js'
				: 'https://www.bridgepaynetsecuretx.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js'

			let TokenPayScript = document.createElement('script')
			TokenPayScript.setAttribute('src', tokenPaySrc)
			document.head.appendChild(TokenPayScript)
		}
	},
	methods: {
		loadPayment() {
			heyGovApi.get(`/${this.j.slug}/payments/${this.$route.params.paymentUuid}?expand=paymentIntent`).then(
				({ data }) => {
					this.payment = data

					this.states.payment = 'loaded'

					if (['requires_payment_method', 'pending'].includes(data.status)) {
						this.startPaymentElement()
					}
				},
				error => {
					this.states.payment = 'error'
					Vue.toasted.error(`Error loading payment info (${error.message})`)
				}
			)
		},

		startPaymentElement() {
			if (!this.paymentDept || !this.auth || this.states.paymentElementStarted) {
				return
			}

			this.states.paymentElementStarted = true

			if (this.payment.platform === 'justifi') {
				this.justifi = window.JustiFiPaymentsJS({
					clientKey: 'test_YWwXHsShV2n2pdwzN8JdDdcdA1KJ99Gq',
					account: this.paymentDept.justifi_account,
				})

				this.justifi.on('ready', data => {
					console.log('justifi ready', data)
					this.states.paymentElementReady = true
				})
				this.justifi.on('change', data => {
					console.log('justifi change', data)

					this.paymentError = ''
				})

				setTimeout(() => {
					this.justifi.appendTo('#payment-element')
				}, 250)
			} else if (this.payment.platform === 'stripe') {
				this.stripe = new window.Stripe(
					this.j.testmode ? this.stripePublishableTestKey : this.stripePublishableKey,
					{
						stripeAccount: this.paymentDept.stripe_account,
					}
				)

				if (this.payment.paymentIntent.payment_method_types.includes('us_bank_account')) {
					this.stripeHG = new window.Stripe(
						this.j.testmode ? this.stripePublishableTestKey : this.stripePublishableKey
					)

					this.loadStripePaymentMethods('us_bank_account')
				} else {
					this.stripeElements = this.stripe.elements({
						appearance: {
							theme: 'stripe',
						},
						clientSecret: this.payment.paymentIntent.client_secret,
					})

					this.paymentElement = this.stripeElements.create('payment')

					this.paymentElement.on('ready', () => {
						this.states.paymentElementReady = true
					})

					this.paymentElement.on('focus', () => {
						this.paymentError = ''
					})

					setTimeout(() => {
						this.paymentElement.mount('#payment-element')
					}, 250)
				}

				console.log(this.payment)
			} else if (this.payment.platform === 'bridgepay') {
				this.tokenpay = window.TokenPay(bridgePayAccounts[this.paymentDept.bridgepay_account])

				const tokenPayTimer = setInterval(() => {
					if (this.tokenpay?.initialize && !this.states.paymentElementReady) {
						this.tokenpay.initialize({
							dataElement: '#payment-element',
							errorElement: '#payment-element-error-bridgepay',
							amountElement: '#amount',
							//if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
							//if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
							//if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
							useStyles: false,
							useACH: false,
							disableZip: false,
							disableCvv: false,
						})

						this.states.paymentElementReady = true
						clearInterval(tokenPayTimer)
					}
				}, 200)
			} else if (this.payment.platform === 'municipay') {
				this.states.paymentElementReady = true
			} else if (this.payment.platform === 'paygov') {
				this.states.paymentElementReady = true
			} else {
				Vue.toasted.error('No payment method available for this payment')
			}
		},

		loadStripePaymentMethods(type) {
			heyGovApi(`/account/me/payment-methods?type=${type}&setup_payment_method_types=${type}`).then(
				({ data }) => {
					this.paymentMethods = data.paymentMethods
					this.setupIntent = data.setupIntent

					if (this.paymentMethods.length) {
						this.paymentMethodSelected = this.paymentMethods[0].id
					}
				}
			)
		},

		addStripeBankAccount() {
			const name = `${this.account.first_name} ${this.account.last_name}`.trim() || this.account.name

			this.stripeHG
				.collectBankAccountForSetup({
					clientSecret: this.setupIntent.client_secret,
					params: {
						payment_method_type: 'us_bank_account',
						payment_method_data: {
							billing_details: {
								name,
								email: this.account.email,
							},
						},
					},
				})
				.then(({ setupIntent, error }) => {
					console.log('setupIntent result', setupIntent, error)

					if (error) {
						this.paymentError = error.message
						// PaymentMethod collection failed for some reason.
					} else {
						this.setupIntent = setupIntent

						if (setupIntent.status === 'requires_confirmation') {
							// We collected an account - possibly instantly verified, but possibly
							// manually-entered. Display payment method details and mandate text
							// to the customer and confirm the intent once they accept
							// the mandate.
							this.stripeConfirmUsBankAccountSetup(setupIntent)
						} else if (setupIntent.status === 'requires_payment_method') {
							// Customer canceled the hosted verification modal. Present them with other
							// payment method type options.
						}
					}
				})
		},
		stripeConfirmUsBankAccountSetup(setupIntent) {
			this.stripeHG.confirmUsBankAccountSetup(setupIntent.client_secret).then(({ setupIntent, error }) => {
				if (error) {
					Vue.toasted.error(error.message)
					// The payment failed for some reason.
				} else {
					console.log('confirm SetupIntent', setupIntent)
					this.setupIntent = setupIntent

					if (setupIntent.status === 'succeeded') {
						this.loadStripePaymentMethods('us_bank_account')
						Vue.toasted.success('Bank account added successfully')
					} else if (setupIntent.next_action?.type === 'verify_with_microdeposits') {
						// The account needs to be verified via microdeposits.
						// Display a message to consumer with next steps (consumer waits for
						// microdeposits, then enters a statement descriptor code on a page sent to them via email).

						Vue.toasted.show('Verify bank account')
					} else if (setupIntent.status === 'requires_payment_method') {
						// Confirmation failed. Attempt again with a different payment method.
					}
				}
			})
		},

		async paymentSubmit() {
			this.states.paymentElementReady = false

			if (this.payment.platform === 'justify') {
				const tokenizeResponse = await this.justifi.tokenize({
					email: this.account.email,
					name: this.payment.name,
					address_line1: this.payment.location.line1,
					address_line2: this.payment.location.line2,
					address_city: this.payment.location.city,
					address_state: this.payment.location.state,
					address_postal_code: this.payment.location.postal_code,
					address_country: this.payment.location.country,
				})

				console.log('justifi response', tokenizeResponse)

				if (tokenizeResponse.token) {
					console.log('token', tokenizeResponse.token)

					heyGovApi.post(`${this.j.slug}/payments/${this.payment.uuid}/capture`, {
						paymentMethodToken: tokenizeResponse.token,
					})

					this.states.payment = 'paid'
				} else {
					console.error('justifi error', tokenizeResponse.errors)

					this.paymentError = `Some card fields are invalid: ${tokenizeResponse.errors.join(', ')}`
					this.states.payment = 'error'
				}
			} else if (this.payment.platform === 'stripe') {
				let result

				if (this.payment.paymentIntent.payment_method_types.includes('us_bank_account')) {
					const re = await heyGovApi.post(`/account/me/payment-methods/clone/${this.paymentDept.id}`, {
						payment_method: this.paymentMethodSelected,
					})
					result = await this.stripe.confirmUsBankAccountPayment(this.payment.paymentIntent.client_secret, {
						payment_method: re.data.id,
					})
				} else {
					result = await this.stripe.confirmPayment({
						elements: this.stripeElements,
						confirmParams: {
							payment_method_data: {
								billing_details: {
									name: this.payment.name,
									email: this.account.email,
								},
							},

							// Make sure to change this to payment completion page
							return_url: appUrl(`${this.j.slug}/quick-pay/payments/${this.payment.uuid}`),
						},
						redirect: 'if_required',
					})
				}

				if (this.payment.source === 'link') {
					heyGovApi.post(`${this.j.slug}/payments/${this.payment.uuid}/complete-payment-link`)
				}

				if (result.error) {
					// Show error to in payment form
					this.paymentError = result.error.message
					this.states.paymentElementReady = true
				} else if (['succeeded', 'requires_capture', 'processing'].includes(result.paymentIntent?.status)) {
					this.payment.status = result.paymentIntent.status
					this.states.justPaid = true
				} else {
					console.log('unhandled status', result.paymentIntent?.status)
					console.log(result.paymentIntent)
					Vue.toasted.error(`Payment info in console ~ `)
				}
			} else if (this.payment.platform === 'bridgepay') {
				this.tokenpay.createToken(
					result => {
						heyGovApi
							.post(`${this.j.slug}/payments/${this.payment.uuid}/bridgepay-token`, {
								token: result.token,
							})
							.then(() => {
								this.payment.status = 'succeeded'
								this.states.justPaid = true
							})
							.catch(error => {
								const msg =
									error.response?.data?.message ||
									error.response?.data?.error ||
									error.response?.statusText ||
									error.message

								this.paymentError = `Payment failed: ${msg}`
								this.states.paymentElementReady = true
							})
					},
					error => {
						console.log('TokenPay error', error)
						this.states.paymentElementReady = true
					}
				)
			}
		},
	},
	watch: {
		auth(auth, oldAuth) {
			if (!oldAuth && auth) {
				setTimeout(() => {
					this.startPaymentElement()
				}, 100)
			}
		},
	},
}
</script>
