<template>
	<div class="hg-events-page" :class="embeded ? 'embeded' : ''" :style="generalStyle">
		<div class="row text-center mb-2">
			<div class="col">
				<span @click="toggleView" class="cursor-pointer me-2"
					><font-awesome-icon :icon="['fas', !calendarView ? 'table' : 'calendar-days']" class="fa-fw me-1"
				/></span>
				<span class="fw-bold cursor-pointer">
					<span @click="changeMonth('sub')">
						<font-awesome-icon :icon="['fa', 'angles-left']" class="fa-fw me-1"
					/></span>
					<span class="cursor-pointer d-inline">
						<span class="fs-5 mx-2">
							<span
								>{{ currentMonth }} <span>{{ year }}</span>
							</span>
						</span>
					</span>
					<span @click="changeMonth('add')">
						<font-awesome-icon :icon="['fa', 'angles-right']" class="fa-fw me-1"
					/></span>
					<span>
						<date-picker
							v-if="showDatePicker"
							value-type="YYYY-MM"
							v-model="filters.month"
							type="month"
							input-class="form-control form-control-sm fw-bold text-center"
							@change="showDatePicker = false"
							:clearable="false"
						>
						</date-picker>
						<font-awesome-icon
							@click="toggleDatePicker"
							:icon="['fa', 'calendar-check']"
							class="fa-fw me-2"
						/>
					</span>
				</span>
			</div>
		</div>
		<div class="row px-2 py-1">
			<div class="bar-filters border-0">
				<div class="row align-items-center justify-content-around g-2">
					<div class="col">
						<input
							class="form-control form-control-sm bg-light"
							:style="
								generalStyle
									? { color: generalStyle.color, borderRadius: generalStyle.borderRadius }
									: ''
							"
							type="search"
							v-model="filters.q"
							placeholder="🔍 Search..."
						/>
					</div>
					<div class="col">
						<select
							class="form-select form-select-sm bg-light"
							v-model="filters.category"
							:style="
								generalStyle
									? { color: generalStyle.color, borderRadius: generalStyle.borderRadius }
									: ''
							"
						>
							<option value="">Category</option>
							<option v-for="(cat, index) in categories" :key="`department-${index}`" :value="cat.id">{{
								cat.name
							}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="row align-items-center gx-2">
			<div class="card rounded-1 border-0">
				<!-- List view -->
				<div v-if="!calendarView" class="card-body">
					<div class="card-table mb-3">
						<table class="table table-borderless table-hover">
							<thead>
								<tr class="text-uppercase text-muted fw-normal">
									<td></td>
									<td></td>
								</tr>
							</thead>
							<tbody :style="generalStyle ? { color: generalStyle.color } : ''">
								<!--Current month events -->
								<tr class="border border-bottom border-1 border-start-0 border-end-0">
									<td class="fs-6">
										<span v-if="!currentMonthEvents.length" class="text-uppercase d-block my-2">
											No events for
										</span>
										<strong
											>{{ months[month] }} <span>{{ year }}</span></strong
										>
									</td>
								</tr>
								<tr v-for="event in currentMonthEvents" :key="`${event.pid}-event`">
									<td>
										<span class="text-uppercase d-flex">
											{{
												event.starts_at
													| dateLocal('en-US', { timeZone: j.timezone, weekday: 'short' })
											}}
										</span>
										<h3>
											{{
												event.starts_at
													| dateLocal('en-US', { timeZone: j.timezone, day: 'numeric' })
											}}
										</h3>
									</td>
									<td>
										<!-- Event date -->
										<span>
											{{
												event.starts_at
													| dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' })
											}}
											<span v-if="event.all_day && !event.multi_day"
												><strong class="text-uppercase mx-2">All day</strong></span
											>
											<span v-if="event.multi_day && !event.all_day" class="d-inline">
												{{
													event.starts_at
														| dateLocal('en-US', {
															timeZone: j.timezone,
															month: 'numeric',
															day: 'numeric',
														})
												}}

												<span v-if="event.metadata && !event.metadata.hide_end_time">
													→
													{{
														event.ends_at
															| dateLocal('en-US', {
																timeZone: j.timezone,
																month: 'numeric',
																day: 'numeric',
															})
													}}</span
												>

												<strong v-if="event.days_length > 1" class="ms-2"
													>{{ event.days_length }} days
												</strong>
											</span>
											<span v-if="!event.all_day && !event.multi_day">
												@
												{{ getTime(event.starts_at) }}

												<span v-if="event.metadata && !event.metadata.hide_end_time">
													→
													{{ getTime(event.ends_at) }}</span
												>
											</span>
										</span>
										<!-- Event icon -->
										<span
											v-if="event.parent[0] || event.type === 'recurrent'"
											class="badge bg-success-lighter text-success ms-2"
										>
											{{
												event.parent && event.parent.length > 0
													? JSON.parse(event.parent[0].recurrence).frequency
													: event.recurrence.frequency
											}}
											<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
										</span>
										<span v-else-if="event.type === 'single'" class="badge bg-light text-gray">
											single
											<font-awesome-icon :icon="['fas', 'arrows-left-right']" />
										</span>
										<!-- Event categories -->
										<span v-if="categories.length && event.categories" class="ps-3">
											<small
												v-for="cat in event.categories"
												:key="`event-category-id-${cat}`"
												class="ms-2"
												>{{ event.categories ? findCategoryValue(cat, 'name') : '' }}</small
											>
										</span>

										<h2 class="py-3">
											<router-link
												:to="`/${j.slug}/events-embed/${event.pid}`"
												class="text-decoration-none text-dark"
												><span
													class="hg-events-underline"
													:style="generalStyle ? { color: generalStyle.color } : ''"
													>{{ event.name }}
												</span></router-link
											>
										</h2>
									</td>
								</tr>
								<tr v-if="this.currentMonthEvents.length >= 5" class="border-top-0">
									<td colspan="3">
										<span class="float-end cursor-pointer" @click="toggleMonthEvents"
											>Show {{ !showMoreEvents ? ' more' : ' less' }}
											<b>...</b>
										</span>
									</td>
								</tr>
								<!-- Next month events -->

								<tr class="border border-bottom border-1 border-start-0 border-end-0">
									<td class="fs-6">
										<span v-if="!nextMonthEvents.length" class="text-uppercase d-block my-2">
											No events for
										</span>
										<span v-else class="d-block my-2 text-uppercase">Upcoming events </span>
										<strong
											>{{ months[nextMonth] }}
											<span>{{ nextMonth === 0 ? nextYear : year }}</span></strong
										>
									</td>
									<td></td>
								</tr>
								<tr v-for="event in nextMonthEvents" :key="`${event.pid}-event`">
									<td>
										<span class="text-uppercase d-flex">
											{{
												event.starts_at
													| dateLocal('en-US', { timeZone: j.timezone, weekday: 'short' })
											}}
										</span>
										<h3>
											{{
												event.starts_at
													| dateLocal('en-US', { timeZone: j.timezone, day: 'numeric' })
											}}
										</h3>
									</td>
									<td>
										<!-- Event date -->
										<span>
											{{
												event.starts_at
													| dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' })
											}}
											<span v-if="event.all_day"
												><strong class="text-uppercase mx-2">All day</strong></span
											>
											<span v-else>
												@
												{{ getTime(event.starts_at) }}

												<span v-if="event.metadata && !event.metadata.hide_end_time">
													→
													{{ getTime(event.ends_at) }}
												</span>
											</span>
											<span v-if="event.multi_day" class="d-inline">
												{{
													event.starts_at
														| dateLocal('en-US', {
															timeZone: j.timezone,
															month: 'numeric',
															day: 'numeric',
														})
												}}

												<span v-if="event.metadata && !event.metadata.hide_end_time">
													→
													{{
														event.ends_at
															| dateLocal('en-US', {
																timeZone: j.timezone,
																month: 'numeric',
																day: 'numeric',
															})
													}}</span
												>

												<strong v-if="event.days_length > 1" class="ms-2"
													>{{ event.days_length }} days</strong
												>
											</span>
										</span>
										<!-- Event icon -->
										<span
											v-if="event.parent[0] || event.type === 'recurrent'"
											class="badge bg-success-lighter text-success ms-2"
										>
											{{
												event.parent[0]
													? JSON.parse(event.parent[0].recurrence).frequency
													: event.recurrence.frequency
											}}
											<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
										</span>
										<span v-else-if="event.type === 'single'" class="badge bg-light text-gray">
											single
											<font-awesome-icon :icon="['fas', 'arrows-left-right']" />
										</span>
										<!-- Event categories -->
										<span v-if="categories.length && event.categories" class="ps-3">
											<small
												v-for="cat in event.categories"
												:key="`event-category-id-${cat}`"
												class="ms-2"
												>{{ event.categories ? findCategoryValue(cat, 'name') : '' }}</small
											>
										</span>
										<span v-else></span>
										<h2 class="py-3">
											<router-link
												:to="`/${j.slug}/events-embed/${event.pid}`"
												class="text-decoration-none text-dark"
												><span class="hg-events-underline">{{ event.name }} </span></router-link
											>
										</h2>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr v-if="states.events === 'loading'">
									<td colspan="9" class="text-center py-3">
										<div class="spinner-border spinner-border-sm" role="state"></div>
									</td>
								</tr>
								<tr
									v-else-if="
										states.events === 'loaded' &&
											!currentMonthEvents.length &&
											!nextMonthEvents.length
									"
								>
									<td colspan="9" class="text-center py-3">
										<div v-if="hasActiveFilters">
											<img
												src="https://files.heygov.com/illustrations/illustration-arms-documents.jpg"
												width="300"
												class="mb-3"
											/>
											<p class="lead">No events found</p>
										</div>
										<div v-else>
											<img
												src="https://files.heygov.com/illustrations/illustration-arms-documents.jpg"
												width="300"
												class="mb-3"
											/>
											<p class="lead">Awaiting events</p>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
				<div v-else>
					<!-- Calendar view -->
					<div class="p-4 hg-calendar">
						<div class="row row-cols-7">
							<div
								class="col hg-week-days fw-bolder text-center"
								:style="generalStyle ? { color: generalStyle.secondaryColor } : ''"
								v-for="day in weekDays"
								:key="`week-day-${day}`"
							>
								{{ day }}
							</div>
						</div>

						<div class="row hg-seven-cols">
							<div
								class="col-md-1 hg-calendar-day border border-1 text-center"
								v-for="(day, index) in calendarDays()"
								:key="`month-day-${index}`"
							>
								<div class="mt-1" @click="getEventsForDay(day)">
									<span
										:class="[
											day.class,
											day.fullDate === currentDay
												? 'current-day rounded rounded-cicle bg-info-light text-white px-1'
												: '',
										]"
									>
										{{ day.day }}
									</span>
									<!-- Display calendar day events -->

									<template v-if="day.events.length > 0">
										<div
											v-for="event in day.events.slice(0, 2)"
											:key="`hg-calendar-event-${event.pid}`"
										>
											<!--Event day-->

											<router-link
												data-bs-toggle="tooltip"
												data-bs-custom-class="hg-events-tooltip"
												data-bs-html="true"
												:title="eventTooltipText(event)"
												:to="`/${j.slug}/events-embed/${event.pid}`"
												class="hg-calendar-text-md my-1 d-none d-md-block cursor-pointer text-decoration-none hg-event"
												:class="event.class"
												:style="
													parseStyleObject(event, [
														'color',
														'backgroundColor',
														'fontSize',
														'fontStyle',
														'fontWeight',
													])
												"
											>
												<span class="hg-events-underline">
													{{ truncateString(event.name, 20) }}
													<small
														v-if="event.type === 'recurrent' || event.type === 'recurrence'"
													>
														<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
													</small>
												</span>
												<!-- Event date time -->

												<span
													v-if="event.all_day && !event.multi_day"
													class="d-lg-block d-none hg-calendar-text-sm"
													>All day</span
												>
												<span
													v-if="event.multi_day"
													class="d-lg-block d-none hg-calendar-text-sm"
												>
													{{ getDate(event.starts_at) }}

													<span v-if="event.metadata && !event.metadata.hide_end_time">
														→
														{{ getDate(event.ends_at) }}</span
													>

													<strong
														v-if="event.days_length > 1"
														class="ms-2 hg-events-days-length"
														>{{ event.days_length + 1 }} days</strong
													>
												</span>
												<span
													v-if="!event.all_day && !event.multi_day"
													class="d-lg-block d-none hg-calendar-text-sm"
												>
													{{ getTime(event.starts_at) }}
													<span v-if="event.metadata && !event.metadata.hide_end_time">
														→
														{{ getTime(event.ends_at) }}</span
													>
												</span>
											</router-link>
										</div>
										<!-- Display only colored circle if day event on phone view -->
										<small class="d-md-none cursor-pointer"
											><font-awesome-icon
												v-if="categories && day.events && day.events[0].categories"
												:icon="['fas', 'circle']"
												:style="{
													color: findCategoryAdditionalValue(
														day.events[0].categories[0],
														'style',
														'backgroundColor'
													),
												}"
											/>
										</small>

										<!-- If more than 2 events per day -->
										<span v-if="day.events.length > 2" class="cursor-pointer d-md-block d-none pt-0"
											>...
											<small class="hg-events-underline" @click="calendarView = false"
												>+ {{ day.events.length - 2 }} more</small
											></span
										>
									</template>
								</div>
							</div>
						</div>
					</div>
					<div class="d-sm-block d-md-none table-hover">
						<div v-if="choosenDayEvents.length > 0">
							<span class="mb-3 ps-2"
								>Events for
								{{
									choosenDay
										? choosenDay
										: currentDay | dateLocal('en-US', { month: 'long', day: 'numeric', timeZone: j.timezone })
								}}
							</span>
							<div v-for="dayEvent in choosenDayEvents" :key="`choosen-day-event-${dayEvent.pid}`">
								<event-details :event="dayEvent"></event-details>
							</div>
						</div>
						<div v-else-if="events.length > 0">
							<span class="d-block shadow-none text-muted mb-3 p-2 bg-light rounded-1">
								<small class="ps-2">No events on this day</small>
							</span>
							<span class="mb-3 ps-2">
								Upcoming events
								{{ currentDay | dateLocal(undefined, { dateStyle: 'long' }) }}
							</span>
							<div
								v-for="e in events.filter(e => e.starts_at_local >= currentDay).slice(0, 3)"
								:key="`upcoming-event-${e.pid}`"
							>
								<event-details :event="e"></event-details>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import {
	format,
	parseISO,
	getMonth,
	setMonth,
	startOfMonth,
	lastDayOfMonth,
	getDaysInMonth,
	getDay,
	differenceInDays,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Tooltip } from 'bootstrap'
import DatePicker from 'vue2-datepicker'

import heyGovApi from '@/api.js'
import { truncateString, getPublicFileUrl } from '@/utils.js'
import { sortTable } from '@/actions/tables'

import EventDetails from '@/components/events/EventDetails.vue'

export default {
	name: 'EventsEmbed',
	components: { EventDetails, DatePicker },
	data() {
		return {
			events: [],
			states: {
				events: 'loading',
				categories: 'loading',
			},
			filters: {
				q: this.$route.query.q || '',
				month: this.$route.query.month || format(new Date(), 'yyyy-MM'),
				category: this.$route.query.category || '',
			},
			calendarView: true,
			month: getMonth(new Date()),
			currentMonth: '',
			year: '',
			months: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			weekDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
			choosenDayEvents: [],
			choosenDay: '',
			categories: [],
			showMoreEvents: false,
			generalStyle: null,
			family: null,
			showDatePicker: false,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['activeDepartments', 'currentRole']),
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		prevMonthDays() {
			return parseInt(getDay(new Date(this.year, this.month)))
		},
		firstDayOfMonth() {
			return format(startOfMonth(new Date(this.year, this.month)), 'yyyy-MM-dd')
		},
		lastDayOfPreviousMonth() {
			let prevMonth = this.month - 1
			let lastDay = format(lastDayOfMonth(new Date(this.year, prevMonth)), 'dd')
			return lastDay
		},
		currentDay() {
			return format(new Date(), 'yyyy-MM-dd')
		},
		currentMonthEvents() {
			let monthEvents = this.events.filter(
				e => getMonth(parseISO(e.starts_at_local, 'yyyy-MM-dd')) === this.month
			)
			if (!this.showMoreEvents) {
				monthEvents = monthEvents.slice(0, 5)
			}
			return monthEvents
		},
		nextMonth() {
			return this.month !== 11 ? this.month + 1 : 0
		},
		nextYear() {
			return this.year + 1
		},
		nextMonthEvents() {
			return this.events.filter(e => getMonth(parseISO(e.starts_at_local, 'yyyy-MM-dd')) === this.nextMonth)
		},
		embeded() {
			return this.$route.meta.embed
		},
		websiteUrl() {
			return '*'
		},
	},
	created() {
		this.loadCategories()
		this.loadEvents()
		this.loadTooltips()

		// set general style
		if (this.j.style) {
			this.generalStyle = this.j.style.events || this.j.style
		}
	},
	updated() {
		this.getIframeHeight()
	},
	mounted() {
		window.addEventListener('load', this.getIframeHeight)
	},
	methods: {
		sortTable,
		truncateString,

		loadEvents() {
			this.states.events = 'loading'

			const params = {
				...this.filters,
				limit: 100,
			}

			heyGovApi.get(`/${this.j.slug}/events?expand=parent`, { params }).then(
				({ data }) => {
					this.events = data.map(event => {
						event.all_day =
							(new Date(event.starts_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone }) ==
								'12:00:00 AM' &&
								new Date(event.ends_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone })) ==
							'11:59:00 PM'

						return event
					})

					this.states.events = 'loaded'
					//this.getEventsForDay(format(new Date(), 'yyyy-MM-dd'))
				},
				error => {
					Vue.toasted.error(`Error loading events (${error.message})`)
					this.states.events = 'error'
				}
			)

			this.setCurrentMonth()
			this.updatePageUrl(this.filters)
		},
		loadCategories() {
			this.states.categories = 'loading'

			heyGovApi.get(`/${this.j.slug}/events/categories`).then(
				data => {
					this.categories = data.data
					this.states.categories = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading categories (${error.message})`)
					this.states.categories = 'error'
				}
			)
		},
		updatePageUrl(filters) {
			let query = {}

			for (const filter in filters) {
				if (filters[filter]) {
					query[filter] = filters[filter]
				}
			}

			this.$router.replace({ path: `/${this.j.slug}/events-embed`, query }).catch(() => {})
		},
		toggleView() {
			this.calendarView = !this.calendarView
		},
		changeMonth(operator) {
			if (operator === 'add') {
				this.month += 1
			} else {
				this.month -= 1
			}

			if (this.month > 11) {
				this.year += 1
				this.month = 0
			} else if (this.month < 0) {
				this.year -= 1
				this.month = 11
			}

			this.currentMonth = this.months[this.month]
			let selectedMonth = format(setMonth(new Date(), this.month), 'MM')
			this.filters.month = `${this.year}-${selectedMonth}`
			this.loadTooltips()
		},
		setCurrentMonth() {
			let dates = this.filters.month.split('-')
			this.year = parseInt(dates[0])
			this.month = parseInt(dates[1]) - 1
			this.currentMonth = this.months[this.month]
		},
		previousMonthDaysCalc(day) {
			return this.lastDayOfPreviousMonth - this.prevMonthDays + day
		},
		calendarDays() {
			const days = []
			let daysInMonth = getDaysInMonth(new Date(this.year, this.month))
			for (let i = 1; i <= this.prevMonthDays; i++) {
				days.push({
					day: this.previousMonthDaysCalc(i),
					fullDate: format(new Date(this.year, this.month - 1, this.previousMonthDaysCalc(i)), 'yyyy-MM-dd'),
					class: 'hg-prev-month-day',
				})
			}

			for (let i = 1; i <= daysInMonth; i++) {
				days.push({
					day: format(new Date(this.year, this.month, i), 'dd'),
					fullDate: format(new Date(this.year, this.month, i), 'yyyy-MM-dd'),
					class: 'hg-current-month-day',
				})
			}
			let nextMonthDays
			let prevMonth = this.prevMonthDays
			if (prevMonth + daysInMonth <= 35) {
				nextMonthDays = 35 - (prevMonth + daysInMonth)
			} else {
				nextMonthDays = 42 - (prevMonth + daysInMonth)
			}

			for (let i = 1; i <= nextMonthDays; i++) {
				days.push({
					day: format(new Date(this.year, this.month + 1, i), 'dd'),
					fullDate: format(new Date(this.year, this.month + 1, i), 'yyyy-MM-dd'),
					class: 'hg-next-month-day',
				})
			}

			days.map(d => {
				return (d.events = this.events.filter(
					e => format(utcToZonedTime(e.starts_at, this.j.timezone), 'yyyy-MM-dd') == d.fullDate
				))
			})

			this.eventsFormating(days)

			return days
		},
		eventsFormating(days) {
			days.forEach((d, index) => {
				if (d.events.length > 0) {
					for (let e of d.events) {
						if (
							new Date(e.starts_at).toLocaleDateString('en-US', {
								timeZone: this.j.timezone,
							}) !== new Date(e.ends_at).toLocaleDateString('en-US', { timeZone: this.j.timezone })
						) {
							e.multi_day = true
							e.days_length = differenceInDays(parseISO(e.ends_at_local), parseISO(e.starts_at_local))
							e.class = `hg-event-multi-day`
							if (e.multi_day && !e.is_clone) {
								this.parseMultiDay(days, index, e)
							}
						} else {
							e.multi_day = false
						}
					}
				}
			})

			return days
		},
		parseMultiDay(days, index, event) {
			if (event.days_length > 0) {
				for (let i = 0; i < event.days_length; i++) {
					event.is_clone = true
					let multiDayIndex = index + i + 1
					if (days[multiDayIndex]?.events) {
						days[multiDayIndex].events.unshift(event)
					}
				}
			}
		},
		eventTooltipText(event) {
			let eventText = `<div class="rounded rounded-0 px-2 py-1 text-start"> ${event.name} </br>`
			if (this.categories.length) {
				eventText += `<small class="text-muted mb-2"> ${
					event.categories ? this.findCategoryValue(event?.categories[0], 'name') : 'No event category'
				} </small> </br>`
			}
			if (event.image_url) {
				eventText += `<span class="card-image"><img src="${getPublicFileUrl(
					event.image_url
				)}" class="card-img-top rounded rounded-1 mb-2"/></span>`
			}
			eventText += `<small>`
			if (event.all_day) {
				eventText += `All day </small>`
			}
			if (event.multi_day) {
				if (event.days_length > 1) {
					eventText += `<small>${event.days_length + 1} days  </small></small></br>`
				}
				eventText += `<small>${this.getDate(event.starts_at)} → ${this.getDate(event.ends_at)}</small>`
				eventText += `</br><small>${this.getTime(event.starts_at)} → ${this.getTime(event.ends_at)}</small>`
			}
			if (event.metadata && event.metadata.hide_end_time) {
				eventText += `<small>${this.getTime(event.starts_at)}</small>`
			} else if (event.metadata && !event.metadata.hide_end_time && !event.all_day && !event.multi_day) {
				eventText += `<small>${this.getTime(event.starts_at)} → ${this.getTime(event.ends_at)}</small>`
			}

			eventText += `</small></br></div>`

			return eventText
		},
		getEventsForDay(day) {
			this.choosenDayEvents = []
			this.choosenDay = day.fullDate

			if (this.events.length) {
				for (let event of this.events) {
					if (
						format(utcToZonedTime(parseISO(event.starts_at), this.j.timezone), 'yyyy-MM-dd') === format(utcToZonedTime(parseISO(day.fullDate), this.j.timezone), 'yyyy-MM-dd') ||
						(day.events[0]?.class == 'hg-event-multi-day' &&
							format(utcToZonedTime(parseISO(day.events[0].starts_at), this.j.timezone), 'yyyy-MM-dd') ==
								format(utcToZonedTime(parseISO(event.starts_at), this.j.timezone), 'yyyy-MM-dd'))
					) {
						this.choosenDayEvents.push(event)
					}
				}
			}
		},
		resetPostMessage() {
			const targetWindow = window.opener || window.parent

			return targetWindow.postMessage(
				{
					heygov_action: 'url-params',
					heygov_url_params: {
						view: 'heygov-events-month',
						year: this.year,
						month: format(setMonth(new Date(), this.month), 'MM'),
						category: this.filters.category,
						q: this.filters.q,
						jurisdiction: this.j.slug,
					},
				},
				this.websiteUrl
			)
		},
		getIframeHeight() {
			const targetWindow = window.opener || window.parent
			return targetWindow.postMessage(
				{
					heygov_action: 'set-element-style',
					element: '.heygov-events',
					styles: {
						height: `${document.body.scrollHeight}px`,
					},
				},
				this.websiteUrl
			)
		},
		toggleMonthEvents() {
			this.showMoreEvents = !this.showMoreEvents
		},
		getTime(time) {
			return new Date(time).toLocaleTimeString(undefined, {
				timeZone: this.j.timezone,
				hour: 'numeric',
				minute: 'numeric',
			})
		},
		getDate(date) {
			return new Date(date).toLocaleDateString(undefined, {
				timeZone: this.j.timezone,
				month: 'numeric',
				day: 'numeric',
			})
		},
		loadTooltips() {
			setTimeout(() => {
				document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
					new Tooltip(el)
				})
			}, 500)
		},
		findCategoryValue(cat, value) {
			const category = this.categories.find(category => category.id == cat)
			return category?.[value]
		},
		findCategoryAdditionalValue(cat, value, additionalValue) {
			return this.findCategoryValue(cat, value)?.[additionalValue]
		},
		parseStyleObject(event, properties) {
			let styleObject = null
			if (event.categories && event.categories.length && this.categories) {
				for (let category of this.categories) {
					if (category.id == event?.categories[0]) {
						properties.forEach(property => {
							styleObject = {
								...styleObject,
								[property]: category.style[property],
							}
						})
					}
				}
			} else {
				styleObject = {
					color: '#212529',
				}
			}

			return styleObject
		},
		toggleDatePicker() {
			this.showDatePicker = !this.showDatePicker
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.events = []
				this.loadEvents()
				this.resetPostMessage()
			},
		},
	},
	beforeDestroy() {
		const tooltips = document.querySelectorAll('.hg-events-tooltip')

		tooltips.forEach(el => {
			el.classList.add('d-none')
		})
	},
}
</script>

<style scoped>
.hg-events-page {
	padding-top: 80px;
}

.hg-calendar-day {
	font-weight: 500;
	font-stretch: condensed;
	line-height: 1.1rem;
}
.hg-calendar-day:hover {
	background-color: #f8f9fa;
}

.hg-calendar-text-sm {
	font-size: 0.6rem;
	border-radius: 5px;
}
.hg-calendar-text-md {
	font-size: 0.8rem;
	border-radius: 5px;
	padding: 2px 0;
}
.current-day {
	padding: 2px;
	color: white !important;
}
.hg-events-underline:hover {
	text-decoration: underline;
}
.hg-prev-month-day,
.hg-next-month-day {
	color: rgb(139, 131, 131, 0.5);
}

.hg-seven-cols .col-md-1 {
	width: calc(100% / 7);
	height: 50px;
}

@media (max-width: 575.98px) {
	.hg-calendar-day div span,
	.hg-week-days {
		font-weight: normal;
		font-size: 0.4rem;
	}
	.current-day {
		padding: 2px 1px !important;
	}
}

@media (min-width: 576px) {
	.hg-seven-cols .col-md-1 {
		height: 90px;
	}
}

@media (min-width: 768px) {
	.hg-seven-cols .col-md-1 {
		height: 110px;
	}
}

@media (min-width: 992px) {
	.hg-seven-cols .col-md-1 {
		height: 130px;
	}
}

@media (min-width: 1200px) {
	.hg-seven-cols .col-md-1 {
		height: 150px;
	}
}
@media (min-width: 1400px) {
	.hg-seven-cols .col-md-1 {
		height: 170px;
	}
}
</style>
