<template>
	<div class="page-payments-list">
		<div class="row align-items-center mb-2">
			<div class="col-6">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}`">{{
						selectedDepartment ? selectedDepartment.name : '...'
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					Payments
				</h3>
			</div>
			<div class="col-6 text-end">
				<button
					class="btn btn-sm btn-outline-dark"
					:disabled="!pag.total"
					data-bs-toggle="modal"
					data-bs-target="#payments-export"
					@click="startPaymentsExport"
				>
					<font-awesome-icon :icon="['fas', 'file-export']" class="me-1" /> Export
				</button>
			</div>
		</div>

		<div class="row gap-0 align-items-center mb-2">
			<div class="col">
				<div class="bar-filters bar-filters-payments border rounded bg-white p-1">
					<div class="row align-items-center g-2">
						<div class="col-sm-6 col-lg">
							<select class="form-select form-select-sm" v-model="filters.status">
								<option :value="null">{{ filters.status ? 'All taken payments' : 'Status' }}</option>
								<option value="succeeded">Paid</option>
								<option value="requires_capture">Not captured (On hold)</option>
								<option value="requires_payment_method">Not paid</option>
								<option value="canceled">Canceled</option>
							</select>
						</div>

						<div class="col-sm-6 col-lg">
							<people-search
								:childClass="'form-control-sm'"
								placeholder="🔍 Person"
								:limit="10"
								:fields="['name', 'email']"
								@person="setPersonFilter"
							></people-search>
						</div>

						<div class="col-sm-6 col-lg">
							<select class="form-select form-select-sm" v-model="filters.source">
								<option :value="null">Source</option>
								<option value="quick-pay">QuickPay</option>
								<option value="card-reader">Card Reader</option>
								<option value="link">Payment link</option>
								<option value="fr_">Form submissions</option>
								<option value="vb_">Venue reservations</option>
							</select>
						</div>

						<div class="col-sm-6 col-lg">
							<select class="form-select form-select-sm" v-model="filters.payment_method">
								<option :value="null">{{
									filters.payment_method ? 'All payment methods' : 'Payment method'
								}}</option>
								<option value="card">Card - online</option>
								<option value="card_present">Card - in person</option>
								<option value="cash">Cash</option>
								<option value="check">Check</option>
								<option value="us_bank_account">ACH</option>
							</select>
						</div>

						<div class="col-sm-6 col-lg">
							<date-picker
								input-class="form-control form-control-sm"
								range
								:formatter="dateFormatter"
								:editable="false"
								:shortcuts="datePickerShortcuts"
								range-separator="  -  "
								v-model="dateRange"
								placeholder="Select date range"
							>
							</date-picker>
						</div>
					</div>
				</div>
			</div>
			<div class="col-auto ps-0">
				<div class="dropdown">
					<button
						class="btn btn-sm btn-outline-dark px-2"
						type="button"
						data-bs-toggle="dropdown"
						title="Customize table columns"
					>
						<font-awesome-icon :icon="['fas', 'columns']" />
					</button>
					<ul class="dropdown-menu">
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="captured_at" /> Captured at</label
							>
						</li>
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="deposit_date" /> Deposit
								date</label
							>
						</li>
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="payment_method" /> Payment
								method</label
							>
						</li>
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="person_id" /> Payer</label
							>
						</li>
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="description" /> Note</label
							>
						</li>
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="address" /> Address</label
							>
						</li>
						<li>
							<label class="dropdown-item"
								><input type="checkbox" v-model="tableColumns" value="created_by" /> Payment taken
								by</label
							>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover table-borderless rounded bg-white">
						<thead>
							<tr>
								<th>Amount</th>
								<th>Fee</th>
								<th>Date</th>
								<th>Status</th>
								<th v-if="tableColumns.includes('captured_at')"><small>Captured at</small></th>
								<th v-if="tableColumns.includes('deposit_date')"><small>Deposit date</small></th>
								<th v-if="tableColumns.includes('payment_method')"><small>Payment method</small></th>
								<th>Payer</th>
								<th>Note</th>
								<th v-if="tableColumns.includes('address')">Address</th>
								<th v-if="tableColumns.includes('created_by')"><small>Taken by</small></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="payment in payments" :key="payment.id" class="on-parent">
								<td>
									{{ payment.amount | currency }}
								</td>
								<td>
									<span :class="{ 'text-muted': !payment.fee }">{{ payment.fee | currency }}</span>
								</td>
								<td>
									<date-timezones :date="payment.created_at" />
								</td>
								<td>
									<payment-status :payment="payment" :tooltip="true" />
								</td>
								<td v-if="tableColumns.includes('captured_at')">
									<date-timezones v-if="payment.captured_at" :date="payment.captured_at" />
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td v-if="tableColumns.includes('deposit_date')">
									<span v-if="payment.deposit_status === 'paid'">
										{{ payment.deposit_date | dateLocal }}</span
									>
									<span v-else-if="payment.deposit_status" class="badge bg-light text-gray">{{
										payment.deposit_status
									}}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td v-if="tableColumns.includes('payment_method')">
									<payment-method
										v-if="payment.payment_method"
										:payment-method="payment.payment_method"
									/>
								</td>
								<td>
									<person-link v-if="payment.person_id" :id="payment.person_id" :avatar="18" />
									<span v-else>{{ payment.email_or_phone || '-' }}</span>
								</td>
								<td>
									<entity-link
										v-if="payment.source && payment.source.includes('_')"
										:entity="payment.source"
									></entity-link>
									<span v-else>
										<font-awesome-icon
											v-if="payment.source === 'link'"
											:icon="['fas', 'comment-dollar']"
										/>
										<font-awesome-icon
											v-else-if="payment.source === 'quick-pay'"
											icon="fa-solid fa-forward"
										/>
										<font-awesome-icon
											v-else-if="payment.source === 'card-reader'"
											:icon="['far', 'credit-card']"
										/>
										<pre v-else>{{ payment.source }}</pre>
										{{ payment.description }}
									</span>
								</td>
								<td v-if="tableColumns.includes('address')">
									<span v-if="payment.location">
										{{ payment.location | formatAddress({ country: false }) }}
									</span>
								</td>
								<td v-if="tableColumns.includes('created_by')">
									<person-link v-if="payment.created_by" :id="payment.created_by" :avatar="18" />
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td class="px-0">
									<div class="dropdown">
										<span
											class="hover p-1 px-2 rounded-1"
											type="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<font-awesome-icon :icon="['fas', 'ellipsis-h']" />
										</span>
										<ul class="dropdown-menu">
											<template
												v-if="
													['succeeded', 'requires_capture', 'canceled'].includes(
														payment.status
													) && payment.platform !== 'paygov'
												"
											>
												<li>
													<a
														class="dropdown-item"
														:href="`${apiUrl + j.slug}/payments/${payment.uuid}/receipt`"
														target="_blank"
														>🧾 View receipt</a
													>
												</li>
												<li>
													<a
														class="dropdown-item"
														:href="
															`${apiUrl + j.slug}/payments/${
																payment.uuid
															}/receipt-html?print=1`
														"
														target="_blank"
														>🖨️ Print receipt</a
													>
												</li>
											</template>

											<li v-if="payment.platform === 'paygov'">
												<a
													href="https://reports2.paygov.us/login.aspx"
													target="_blank"
													class="dropdown-item"
												>
													⏎ Refund
												</a>
											</li>
											<li
												v-else-if="
													['heygov', 'stripe', 'municipay', 'bridgepay'].includes(
														payment.platform
													)
												"
											>
												<button
													class="dropdown-item"
													:disabled="
														payment.status !== 'succeeded' || [4479].includes(auth.id)
													"
													data-bs-toggle="modal"
													data-bs-target="#refund-payment"
													@click="startPaymentRefund(payment)"
												>
													⏎ Refund
												</button>
											</li>

											<li v-if="isStaff && payment.platform === 'stripe'">
												<a
													:href="
														`https://dashboard.stripe.com/${selectedDepartment.stripe_account}/payments/${payment.platform_id}`
													"
													target="_blank"
													class="dropdown-item text-danger-100"
												>
													View in Stripe
												</a>
											</li>
										</ul>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.payments === 'loading'">
								<td colspan="10">
									<p class="text-center">loading..</p>
								</td>
							</tr>
							<tr v-else-if="states.payments === 'loaded' && payments.length" class="bg-light text-muted">
								<th>
									<small>{{ sumAmount | currency }}</small>
								</th>
								<th>
									<small>{{ sumFee | currency }}</small>
								</th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th>
									<small v-if="uniq(payments.map(p => p.person_id)).length > 1"
										>{{ uniq(payments.map(p => p.person_id)).length }} people</small
									>
								</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
							<tr v-else-if="states.payments === 'loaded' && !payments.length">
								<td colspan="10" class="text-center py-3">
									<div v-if="hasActiveFilters">
										<img
											src="https://files.heygov.com/illustrations/illustration-arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">No payments found. Try changing the filters</p>
									</div>
									<div v-else>
										<img
											src="https://files.heygov.com/illustrations/illustration-form-applications.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">Awaiting payments</p>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div v-if="pag.total" class="row align-items-center">
					<div class="col-lg-4">
						<span class="me-2 text-muted">Per page</span>
						<div class="d-inline-flex">
							<select class="form-select form-select-sm" v-model="pag.perPage">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div class="col-4 text-center">
						Showing {{ pag.perPage * (pag.page - 1) + 1 }}-{{
							Math.min(pag.perPage * pag.page, pag.total)
						}}
						of {{ pag.total }}
					</div>
					<div class="col-lg-4">
						<nav v-if="pag.pages > 1" class="float-end">
							<ul class="pagination mb-0">
								<li class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>
								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="p == pag.page ? 'active' : ''"
								>
									<span v-if="Math.abs(pag.page - p) < 3">
										<a class="page-link" @click="pag.page = p">
											{{ p }}
										</a>
									</span>
								</li>
								<li class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>

		<!-- Refund Modal -->
		<div class="modal fade" id="refund-payment" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Refund payment</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p class="card-text ps-2">
							ℹ️ Refunds take 5-10 days to appear on a customers' bank statement.
						</p>
						<form v-if="refundPayment" @submit.prevent="sendRefundPayment">
							<div class="bg-light rounded-1 px-3 py-2 mb-3">
								<p class="mb-1">
									Amount paid:
									<strong>{{ refundPayment.amount | currency }}</strong>
								</p>
								<p class="mb-1">
									Payment fee: <strong>{{ refundPayment.fee | currency }}</strong>
								</p>
								<p class="mb-1">
									Total paid: <strong>{{ refundPayment.total_paid | currency }}</strong>
								</p>
								<hr class="my-2" />
								<p v-if="refundPayment.refunded" class="mb-1">
									Already refunded: <strong>{{ refundPayment.refunded | currency }}</strong>
								</p>
								<p class="lead mb-0">
									Available to refund:
									<strong class="text-info">{{
										(refundPayment.total_paid - refundPayment.refunded) | currency
									}}</strong>
								</p>
							</div>

							<div v-if="refundPayment.total_paid - refundPayment.refunded > 0">
								<div class="form-group mb-3">
									<label for="payment-refund-amount" class="form-label">Amount to refund</label>
									<div class="input-group">
										<span class="input-group-text">$</span>
										<input
											type="number"
											class="form-control"
											id="payment-refund-amount"
											v-model="refundData.amount"
											step="0.01"
											:max="refundPayment.total_paid"
										/>
									</div>
								</div>

								<div
									v-if="refundData.amount > refundPayment.total_paid - refundPayment.refunded"
									class="alert alert-danger mb-3"
								>
									There are no available funds to refund this amount.
								</div>
								<div
									v-else-if="
										Number(refundData.amount) + Number(refundPayment.refunded) >
											refundPayment.amount
									"
									class="alert alert-warning mb-3"
								>
									Careful! You've entered an amount that exceeds the amount that was originally
									collected. Refunding the non-refundable Stripe transaction fee will deduct money
									from the {{ j.name }} bank balance.
								</div>

								<div class="form-group mb-3">
									<label class="form-label">Reason</label>
									<select class="form-select" v-model="refundData.reason">
										<option value="requested_by_customer"
											>Customer request (amount mistake, deposit, etc)</option
										>
										<option value="duplicate">Duplicate</option>
										<option value="fraudulent">Fraudulent</option>
									</select>
								</div>

								<p class="card-text text-center mt-4 mb-2">
									<button class="btn btn-primary">Refund this payment</button>
								</p>
							</div>
							<div v-else class="alert alert-info">
								This payment is fully refunded
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="payments-export" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Export payments
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p v-if="hasActiveFilters">
							Export a <abbr title="Comma-separated values">CSV</abbr> list of
							<strong>{{ pag.total }}</strong> payments, based on selected filters.
						</p>
						<p v-else>
							Export a <abbr title="Comma-separated values">CSV</abbr> list of all
							<strong>{{ pag.total }}</strong> payments.
						</p>

						<div
							v-if="!filters.date_start && !filters.date_end && pag.total > 50"
							class="alert alert-warning mb-4 text-dark"
						>
							ℹ️ Do you want to export payments for a specific interval? The date range filter location
							has moved. Close this popup and use the date range filter at top right of the page.
						</div>

						<div class="form-group mb-3">
							<label for="export-columns-all" class="form-label">Template</label>

							<div class="row row-cols-2 g-3">
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter': exportSettings.template === 'heygov',
										}"
										@click="exportSettings.template = 'heygov'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text">
												No template, export all payment columns
											</p>
										</div>
									</div>
								</div>
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter': exportSettings.template === 'banyon',
										}"
										@click="exportSettings.template = 'banyon'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text mb-2">
												<img
													src="https://edge.heygov.com/integrations/banyon-logo.jpg"
													height="30"
												/>
											</p>
											<p class="card-text">
												For Banyon Accounting
											</p>
										</div>
									</div>
								</div>
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter': exportSettings.template === 'workhorse',
										}"
										@click="exportSettings.template = 'workhorse'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text mb-2">
												<img
													src="https://edge.heygov.com/integrations/workhorse-logo.jpg"
													height="30"
												/>
											</p>
											<p class="card-text mb-0">
												For Workhorse Utility Billing
											</p>
										</div>
									</div>
								</div>
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter': exportSettings.template === 'bsa',
										}"
										@click="exportSettings.template = 'bsa'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text mb-2">
												<img
													src="https://edge.heygov.com/integrations/bsa-logo.png"
													height="30"
												/>
											</p>
											<p class="card-text mb-0">
												For BS&amp;A Software
											</p>
										</div>
									</div>
								</div>
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter': exportSettings.template === 'pelorus',
										}"
										@click="exportSettings.template = 'pelorus'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text mb-2">
												<img
													src="https://edge.heygov.com/integrations/pelorus-logo.jpg"
													height="30"
												/>
											</p>
											<p class="card-text mb-0">
												For Pelorus Financial
											</p>
										</div>
									</div>
								</div>
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter':
												exportSettings.template === 'southern-software',
										}"
										@click="exportSettings.template = 'southern-software'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text mb-2">
												<img
													src="https://edge.heygov.com/integrations/southern-software-logo.png"
													height="30"
												/>
											</p>
											<p class="card-text mb-0">
												For Southern Software
											</p>
										</div>
									</div>
								</div>
								<div class="col">
									<div
										class="card h-100 rounded-1 cursor-pointer border"
										:class="{
											'border-primary bg-info-lighter':
												exportSettings.template === 'tylertech-munis',
										}"
										@click="exportSettings.template = 'tylertech-munis'"
									>
										<div class="card-body py-2 px-3">
											<p class="card-text mb-2">
												<img
													src="https://edge.heygov.com/integrations/tyler-tech-logo.jpg"
													height="30"
												/>
											</p>
											<p class="card-text mb-0">
												For Munis (Tyler ERP)
												<small class="text-neutral-400"
													>Canceled and On Hold payments are not included.</small
												>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selectedDepartment" class="modal-footer justify-content-end">
						<a
							:href="exportUrl"
							class="btn btn-primary"
							:disabled="states.export === 'loading'"
							@click="doExport"
						>
							Export payments
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { uniq } from 'lodash'
import { Modal } from 'bootstrap'
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, sub } from 'date-fns'
import 'vue2-datepicker/index.css'

import heyGovApi from '@/api.js'
import { tracker } from '@/utils.js'

import DateTimezones from '@/components/DateTimezones.vue'
import EntityLink from '@/components/EntityLink.vue'
import PersonLink from '@/components/PersonLink.vue'
import PeopleSearch from '@/components/PeopleSearch.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'
import DatePicker from 'vue2-datepicker'

export default {
	name: 'PaymentsList',
	metaInfo() {
		return {
			title: `${this.selectedDepartment?.name} payments list`,
		}
	},
	components: { DateTimezones, EntityLink, PersonLink, PeopleSearch, PaymentMethod, PaymentStatus, DatePicker },
	data() {
		return {
			states: {
				payments: 'loading',
				export: 'idle',
			},
			filters: {
				status: this.$route.query.status || null,
				source: this.$route.query.source || null,
				payment_method: this.$route.query.payment_method || null,
				person_id: this.$route.query.person_id || null,
				date_start: this.$route.query.date_start || null,
				date_end: this.$route.query.date_end || null,
			},
			payments: [],
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				perPage: localStorage.getItem('payments-per-page') || 25,
			},

			// date range picker
			dateRange: [],
			dateFormatter: {
				stringify(date) {
					return date.toLocaleDateString('default', {
						dateStyle: 'short',
					})
				},
			},
			datePickerShortcuts: [
				{ text: 'Today', onClick: () => [new Date(), new Date()] },
				{
					text: 'Past week',
					onClick: () => {
						const lastWeek = sub(new Date(), { weeks: 1 })

						return [startOfWeek(lastWeek), endOfWeek(lastWeek)]
					},
				},
				{
					text: 'Past month',
					onClick: () => {
						const lastMonth = sub(new Date(), { months: 1 })

						return [startOfMonth(lastMonth), endOfMonth(lastMonth)]
					},
				},
			],

			// Refunds
			$modalRefundPayment: null,
			refundPayment: null,
			refundData: {
				amount: 0,
				reason: 'requested_by_customer',
			},

			// Export
			exportSettings: {
				template: 'heygov',
			},

			tableColumns: (
				localStorage.getItem('hg-payments-columns') ||
				'person_id,deposit_date,payment_method,description,address'
			).split(','),
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'people', 'departments']),
		...mapGetters(['auth', 'currentRole', 'isStaff', 'activeDepartments']),
		selectedDepartment() {
			return (this.departments || []).find(d => d.id == this.$route.params.departmentId)
		},
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		sumAmount() {
			return this.payments.map(p => p.amount).reduce((a, b) => a + b, 0)
		},
		sumFee() {
			return this.payments.map(p => p.fee).reduce((a, b) => a + b, 0)
		},
		exportUrl() {
			const params = new URLSearchParams({
				department_id: this.selectedDepartment.id,
				format: 'csv',
				template: this.exportSettings.template,
			})

			for (const [key, value] of Object.entries(this.filters)) {
				if (value) {
					params.append(key, value)
				}
			}

			return `${this.apiUrl + this.j.slug}/payments/export-payments?${params.toString()}`
		},
	},

	mounted() {
		this.$modalRefundPayment = new Modal(document.getElementById('refund-payment'))
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay`)
		} else {
			this.$store.dispatch('getForms')
			this.$store.dispatch('getVenues')
			this.$store.dispatch('loadDepartments').then(() => {
				this.loadPayments()
			})
		}
	},
	methods: {
		uniq,

		loadPayments() {
			this.states.payments = 'loading'
			this.payments = []

			const params = {
				department_id: this.selectedDepartment.id,
				...this.filters,
				page: this.pag.page,
				per_page: this.pag.perPage,
			}

			if (this.paymentsReqController) {
				this.paymentsReqController.abort()
			}

			this.paymentsReqController = new AbortController()

			heyGovApi
				.get(`/${this.j.slug}/payments`, {
					params,
					signal: this.paymentsReqController.signal,
				})
				.then(
					({ data, headers }) => {
						this.pag.total = parseInt(headers['x-total'], 10)
						this.pag.pages = Math.ceil(this.pag.total / this.pag.perPage)

						this.payments.push(...data)
						this.$store.dispatch('loadPeople', data.map(p => [p.person_id, p.created_by]).flat())

						this.states.payments = 'loaded'

						// if a refund request is in url, start refund
						if (this.$route.query.refund) {
							this.startPaymentRefund(data.find(p => p.uuid === this.$route.query.refund))
						}
					},
					error => {
						if (error.message !== 'canceled') {
							this.states.payments = 'error'
							Vue.toasted.error(`Error loading payments (${error.message})`)
						}
					}
				)
		},

		setPersonFilter(person) {
			this.filters.person_id = person?.id || null
		},

		startPaymentRefund(payment) {
			tracker.event('payment-refund', 'payments')
			this.refundPayment = payment

			this.refundData = {
				amount: Math.max(payment.amount - payment.refunded, 0).toFixed(2),
				reason: 'requested_by_customer',
			}

			this.$modalRefundPayment.show()
		},
		sendRefundPayment() {
			heyGovApi
				.post(`/${this.j.slug}/payments/${this.refundPayment.uuid}/refund`, {
					amount: this.refundData.amount,
					reason: this.refundData.reason,
				})
				.then(
					({ data }) => {
						console.log(data)
						Vue.toasted.info('Payment successfully refunded')
						this.loadPayments()
						this.$modalRefundPayment.hide()
					},
					error => {
						Vue.toasted.error(error.response?.data?.message || `Error refunding payment (${error})`)
					}
				)
		},

		startPaymentsExport() {
			tracker.event('payments-export', 'payments-list')
		},
		doExport() {
			console.log('start export')
			// make the export button disabled for a few seconds
			this.states.export = 'loading'

			setTimeout(() => {
				this.states.export = 'idle'
			}, 3000)
		},
	},
	watch: {
		'pag.page'() {
			this.payments = []
			this.loadPayments()
		},
		'pag.perPage'() {
			this.payments = []
			this.loadPayments()
			localStorage.setItem('payments-per-page', this.pag.perPage)
		},
		filters: {
			deep: true,
			handler() {
				if (this.pag.page === 1) {
					this.payments = []
					this.loadPayments()
				} else {
					this.pag.page = 1
				}
			},
		},
		dateRange() {
			const dateStart = this.dateRange?.[0]
			const dateEnd = this.dateRange?.[1]

			this.filters = {
				...this.filters,
				date_start: dateStart ? format(dateStart, 'yyyy-MM-dd') : null,
				date_end: dateEnd ? format(dateEnd, 'yyyy-MM-dd') : null,
			}
		},
		tableColumns() {
			localStorage.setItem('hg-payments-columns', this.tableColumns.join(','))
		},
	},
}
</script>
