var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editor)?_c('div',{staticClass:"editor-toolbar"},[_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('bold') },attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.toggleBold()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleBold()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'bold']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('italic') },attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.toggleItalic()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleItalic()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'italic']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('underline') },attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.toggleUnderline()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleUnderline()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'underline']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('blockquote') },attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.toggleBlockquote()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleBlockquote()
					.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'quote-right']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('strike') },attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.toggleStrike()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleStrike()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'strikethrough']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('bulletList') },on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleBulletList()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'list-ul']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('orderedList') },on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleOrderedList()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'list-ol']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('link') },on:{"click":_vm.setLink}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'link']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('heading', { level: 1 }) },on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleHeading({ level: 1 })
					.run()}}},[_vm._v(" h1 ")]),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('heading', { level: 2 }) },on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleHeading({ level: 2 })
					.run()}}},[_vm._v(" h2 ")]),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('heading', { level: 3 }) },on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleHeading({ level: 3 })
					.run()}}},[_vm._v(" h3 ")]),_c('button',{staticClass:"btn",on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.setHorizontalRule()
					.run()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'ruler-horizontal']}})],1),_c('button',{staticClass:"btn",class:{ 'btn-primary': _vm.editor.isActive('code') },attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.toggleCode()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.toggleCode()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'code']}})],1),_c('button',{staticClass:"btn",attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.undo()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.undo()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'undo']}})],1),_c('button',{staticClass:"btn",attrs:{"disabled":!_vm.editor
					.can()
					.chain()
					.focus()
					.redo()
					.run()},on:{"click":function($event){_vm.editor
					.chain()
					.focus()
					.redo()
					.run()}}},[_c('font-awesome-icon',{staticClass:"icon alt",attrs:{"icon":['fas', 'redo']}})],1)]):_vm._e(),_c('my-bubble-menu',{ref:"bubble-menu",attrs:{"editor":_vm.editor,"onSetLink":_vm.setLink,"onDiscard":_vm.forceCloseBubbleMenu},on:{"ai-action":_vm.handleAIAction,"make-longer-context-menu":function($event){return _vm.$emit('make-longer-context-menu')},"make-shorter-context-menu":function($event){return _vm.$emit('make-shorter-context-menu')},"describe-your-change-context-menu":function($event){return _vm.$emit('describe-your-change-context-menu')}}}),_c('editor-content',{attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }