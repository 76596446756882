<template>
	<nav class="navbar navbar-expand-lg sticky-top pt-3 pb-2 mb-3 row justify-content-between no-gutters">
		<div v-if="j" class="col-auto d-lg-none ps-1" style="min-width: 64px">
			<a
				v-if="device.platform === 'ios' && $route.meta.backTo"
				@click="$router.go(-1)"
				class="btn btn-sm text-primary"
				><font-awesome-icon :icon="['fas', 'chevron-left']"
			/></a>
			<span v-else class="d-inline-block p-2" @click="$store.commit('toggleMenu')">
				<font-awesome-icon :icon="['fas', 'bars']" />
			</span>
		</div>

		<div v-if="j" class="col col-md-auto pe-md-0">
			<h1 class="text-secondary text-center text-md-start my-0 mt-1">
				<router-link to="/">{{ j.name }}</router-link>
			</h1>
		</div>

		<div v-if="j && $route.meta.sidebar !== false" class="col main-search position-relative pe-4 d-none d-md-block">
			<!-- 	<input
				class="form-control form-control-lg form-control-search bg-white"
				type="search"
				placeholder="🔍 Search..."
				autocomplete="off"
				autocorrect="off"
				autocapitalize="off"
				maxlength="512"
			/> -->
		</div>

		<div class="col-auto" :class="{ 'ms-auto': !auth }">
			<ul class="navbar-nav flex-row">
				<li v-if="auth" class="nav-item d-md-none" :class="{ 'has-unseen': unseenNotifications.length }">
					<span
						@click="toggleNotifications"
						class="nav-link rounded-circle text-center btn-notifications-bell"
						:data-unseen="unseenNotifications.length"
						><font-awesome-icon :icon="['far', 'bell']"
					/></span>
				</li>
				<li class="nav-item d-md-none">
					<router-link to="/account" class="nav-link nav-link-account rounded-circle">
						<img
							v-if="auth"
							class="rounded-circle"
							:src="account.photo || `https://secure.gravatar.com/avatar/${md5(auth.email)}?s=50&d=mm`"
							:alt="auth.name"
							width="32"
							height="32"
						/>
						<span v-else>👤</span>
					</router-link>
				</li>
				<li
					v-if="currentRole !== 'CITIZEN' && $route.meta.sidebar !== false"
					class="nav-item dropdown d-none d-lg-inline-block"
				>
					<span
						class="nav-link rounded-circle text-center btn-support-bell"
						data-bs-toggle="dropdown"
						role="button"
						aria-expanded="false"
						><font-awesome-icon :icon="['fas', 'question']" />
					</span>
					<ul class="dropdown-menu dropdown-menu-center">
						<li>
							<router-link v-if="j" :to="`/${j.slug}/invited`" class="dropdown-item"
								>Getting started</router-link
							>
							<a
								href="https://townweb.notion.site/HeyGov-help-c40fb61f70a54abb9fe0ba9f6c5ea1b8"
								target="_blank"
								class="dropdown-item"
								>Help articles</a
							>
							<a href="https://heygov.com/webinars" target="_blank" class="dropdown-item"
								>Join the next webinar</a
							>
							<a href="https://calendly.com/heygov_app/onboarding" target="_blank" class="dropdown-item"
								>Book a training call</a
							>
							<a href="mailto:support@heygov.com" target="_blank" class="dropdown-item"
								>Contact support</a
							>
						</li>
					</ul>
				</li>
				<li
					v-if="auth && $route.meta.sidebar !== false"
					class="nav-item dropdown d-none d-lg-inline-block ms-2"
					:class="{ 'has-unseen': unseenNotifications.length }"
				>
					<span
						class="nav-link rounded-circle text-center btn-notifications-bell"
						data-bs-toggle="dropdown"
						role="button"
						aria-expanded="false"
						:data-unseen="unseenNotifications.length"
						@click="openNotifications"
						><font-awesome-icon :icon="['far', 'bell']"
					/></span>
					<div class="dropdown-menu dropdown-menu-end" style="min-width:450px; max-width: 600px">
						<notification
							v-for="notification in notifications.slice(0, 6)"
							:key="`navbar-notification-${notification.id}`"
							class="dropdown-item"
							:notification="notification"
						></notification>

						<div v-if="!notifications.length" class="dropdown-item text-center text-muted">
							<i>Hmm, no notifications 🤔</i>
						</div>

						<div v-if="notifications.length" class="dropdown-divider"></div>

						<router-link v-if="notifications.length" class="dropdown-item text-center" to="/notifications">
							<small>See all notifications</small>
						</router-link>
					</div>
				</li>
				<li v-if="auth" class="nav-item dropdown ms-2 d-none d-md-block">
					<span
						class="nav-link dropdown-account dropdown-toggle active"
						data-bs-toggle="dropdown"
						role="button"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<img
							class="rounded-circle me-1"
							:src="account.photo || `https://secure.gravatar.com/avatar/${md5(auth.email)}?s=50&d=mm`"
							:alt="auth.name"
							width="34"
							height="34"
						/>
						{{
							this.account
								? this.account.name || this.account.first_name || auth.name || auth.email
								: auth.name || auth.email
						}}
						<!-- <span v-if="roles[currentRole]" class="badge ms-1" :class="roles[currentRole].class">{{
							roles[currentRole].name.replace('HeyGov', '')
						}}</span> -->
					</span>
					<div class="dropdown-menu dropdown-menu-end">
						<router-link class="dropdown-item" to="/your-account">Account settings</router-link>

						<template v-if="j && currentRole !== 'CITIZEN'">
							<div class="dropdown-divider"></div>
							<li>
								<h6 class="dropdown-header mt-2 mb-1">
									{{ j.name }}
									<span v-if="roles[currentRole]" class="badge" :class="roles[currentRole].class">{{
										roles[currentRole].name
									}}</span>
								</h6>
							</li>

							<router-link class="dropdown-item" to="/departments">Departments</router-link>
							<router-link
								v-if="currentRole === 'ADMIN'"
								class="dropdown-item"
								:to="`/${j.slug}/settings`"
								><span class="text-capitalize">{{ j.type || 'municipality' }}</span>
								settings</router-link
							>
							<router-link v-if="currentRole === 'ADMIN'" class="dropdown-item" :to="`/${j.slug}/billing`"
								>Plan &amp; Billing</router-link
							>
						</template>

						<div class="dropdown-divider"></div>

						<router-link v-if="isStaff" class="dropdown-item text-neutral-400" to="/heygov-admin"
							>Admin</router-link
						>

						<button class="dropdown-item" @click="$store.dispatch('authLogout')">Logout</button>
					</div>
				</li>
				<li v-else class="nav-item d-none d-lg-block">
					<router-link to="/account/login" class="nav-link active py-1 px-2">Log in</router-link>
				</li>
			</ul>
		</div>
	</nav>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
	z-index: 100;
}

.navbar h1 {
	font-size: 24px;
	font-weight: 700;

	a {
		color: #000 !important;
		text-decoration: none;
	}
}

.form-control-search {
	height: 42px;
	border-radius: 21px;
	font-size: 18px;
}

.btn-notifications-bell {
	position: relative;
	width: 42px;
	height: 42px;
	line-height: 42px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	background-color: #fff;
	font-size: 18px;
}

.btn-support-bell {
	position: relative;
	width: 42px;
	height: 42px;
	line-height: 42px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	background-color: #fff;
	font-size: 18px;
}

.has-unseen .btn-notifications-bell {
	background-color: var(--bs-warning);

	&:before {
		content: attr(data-unseen);
		position: absolute;
		top: 1px;
		right: 1px;
		background-color: var(--bs-danger);
		color: white;
		line-height: 16px;
		width: 16px;
		font-size: 10px;
		border-radius: 50%;
	}
}

.dropdown-account {
	padding: 4px 8px 4px 4px !important;
	border-radius: 21px !important;
	background: #ffffff !important;
	font-size: 18px;
	font-weight: 700;
	line-height: 34px;
	color: #000 !important;

	&:after {
		color: rgba(0, 0, 0, 0.3);
	}

	&:hover {
		&:after {
			color: rgba(0, 0, 0, 0.6);
		}
	}
}

@media (max-width: 575.98px) {
	.navbar {
		top: 1rem !important;
		background-color: #fff;
		border-radius: 26px;
		padding: 4px !important;
		margin: 1rem 0 !important;

		.btn-notifications-bell {
			margin: 7px 7px 7px 0;
			width: 34px;
			height: 34px;
			line-height: 34px;
		}

		.has-unseen .btn-notifications-bell:before {
			top: -3px;
			right: -4px;
		}

		h1 {
			font-weight: 700;
			font-size: 18px;
		}

		.nav-link-account {
			padding-top: 0;
			padding-bottom: 0;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
		}
	}
}

.app-ios .navbar {
	top: 2.6rem !important;
	margin-top: 2.6rem !important;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import md5 from 'md5'

import Notification from '@/components/Notification.vue'

export default {
	name: 'Navbar',
	components: { Notification },
	data() {
		return {}
	},
	computed: {
		...mapGetters(['auth', 'unseenNotifications', 'isStaff', 'currentRole']),
		...mapState(['j', 'roles', 'jurisdictions', 'notifications', 'account', 'device']),
	},
	created() {
		this.$store.dispatch('loadNotifications')
		this.$store.dispatch('accountInfo')

		setInterval(() => {
			this.$store.dispatch('loadNotifications')
		}, 100000)
	},
	methods: {
		md5,

		openNotifications() {
			this.$store.commit('markNotificationsAsSeen')
		},
		toggleNotifications() {
			if (this.$route.path === '/notifications') {
				window.history.back()
			} else {
				this.$router.push('/notifications')
			}
		},
	},
}
</script>
