<template>
	<div class="form-detail">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/forms`">HeyLicense</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/forms/${$route.params.formSlug}`">{{
						form ? form.name : $route.params.formSlug
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/form-requests?form_id=${form ? form.id : ''}`"
						>Submissions</router-link
					>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					<span class="text-capitalize">
						{{
							formRequest ? `${form.type} ${formRequest.application_number || formRequest.pid}` : '...'
						}}</span
					>
				</h3>
			</div>

			<div v-if="isStaff && state === 'loaded'" class="col-lg-auto">
				<div class="dropdown">
					<button
						class="btn btn-sm btn-outline-danger dropdown-toggle"
						id="form-request-hg-actions"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						⠇
					</button>
					<ul class="dropdown-menu" aria-labelledby="form-request-hg-actions">
						<li>
							<a
								class="dropdown-item"
								:href="
									`https://console.cloud.google.com/storage/browser/super-secure-vault.heygov.com/${j.slug}/forms/${form.slug}/form-requests/${formRequest.uuid}`
								"
								target="_blank"
								>Files storage</a
							>
						</li>
						<li>
							<button class="dropdown-item" @click="deleteFormRequest">
								Delete submission
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div v-if="state === 'loaded'">
			<div
				class="card mb-4"
				:class="{
					'border-danger': formRequest.status === 'declined',
					'border-success': formRequest.status === 'approved',
				}"
			>
				<div class="card-body">
					<h5 class="mb-3">Application workflow</h5>

					<p>
						<span class="text-capitalize">{{ form.type }}</span> application received on
						<strong>{{ new Date(formRequest.sent_at).toLocaleString() }}</strong
						><span v-if="form.department_id">
							and assigned to
							<strong v-if="departments && departments.length">{{
								departments.find(d => d.id == form.department_id).name
							}}</strong></span
						>.
					</p>

					<div v-if="form.steps.filter(stepsWithReview).length">
						<p class="mb-1">Required tasks to approve application:</p>

						<div class="card-table">
							<table class="table table-hover mb-3">
								<thead>
									<tr>
										<th class="text-capitalize text-muted">Task</th>
										<th class="text-capitalize text-muted">Department</th>
										<th class="text-capitalize text-muted">Status</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="step in form.steps.filter(stepsWithReview)">
										<tr
											:key="`fs-workflow-${step.id}`"
											class="cursor-pointer"
											@click="scrollToStep(step)"
										>
											<td>
												<strong>{{ step.name }}</strong>
											</td>
											<td>
												<span v-if="step.review_department_id && departments">
													{{ departments.find(d => d.id == step.review_department_id).name }}
													<department-people
														v-if="!formRequest.steps[step.id]"
														:department="
															departments.find(d => d.id == step.review_department_id)
														"
													></department-people>
												</span>
											</td>
											<td>
												<span
													v-if="
														formRequest.steps[step.id] &&
															['approved', 'declined'].includes(
																formRequest.steps[step.id].status
															)
													"
												>
													<span
														class="badge badge-sm me-2"
														:class="[
															formRequestStatuses[formRequest.steps[step.id].status]
																.class,
														]"
														>{{
															formRequestStatuses[formRequest.steps[step.id].status].name
														}}</span
													>
													<person-avatar
														:person="people[formRequest.steps[step.id].reviewer_id]"
														:size="18"
														class="me-1"
													></person-avatar>
													<span v-if="formRequest.steps[step.id].message"
														>"{{ formRequest.steps[step.id].message }}"</span
													>
													<small v-else class="text-neutral-300">(no message)</small>
												</span>
												<span
													v-else-if="step.review_department_id"
													class="badge bg-warning-lighter text-warning"
													>Waiting</span
												>
											</td>
										</tr>
										<tr
											v-if="step.name.toLowerCase().includes('payment')"
											:key="`fs-workflow-sub-${step.id}`"
											class="cursor-pointer"
											@click="scrollToField(fieldType('PaymentElement'))"
										>
											<td>↳ {{ fieldType('PaymentElement').label }}</td>
											<td><i>Applicant</i></td>
											<td>
												<span
													v-if="
														fieldTypeAnswer('PaymentElement') &&
															fieldTypeAnswer('PaymentElement').status === 'succeeded'
													"
													class="badge bg-success-lighter text-success"
													>Paid</span
												>
												<span v-else class="badge bg-warning-lighter text-warning"
													>Waiting</span
												>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>

					<p
						v-if="['approved', 'declined'].includes(formRequest.status)"
						class="d-flex justify-content-between align-items-center card-text"
					>
						<span>
							<form-request-status :formRequest="formRequest" :tooltip="true" />
							<span v-if="formRequest.reviewed_by">
								by <person-link :id="formRequest.reviewed_by" :avatar="18"></person-link
							></span>
							on {{ new Date(formRequest.reviewed_at).toLocaleString() }}.
						</span>
						<button
							v-if="formRequest.status === 'approved'"
							class="btn btn-sm btn-outline-danger"
							data-bs-toggle="modal"
							data-bs-target="#modal-form-request-revoke"
						>
							Revoke
						</button>
					</p>
					<div
						v-else-if="['sent', 'working'].includes(formRequest.status)"
						class="bg-white mb-3"
					>
						<div v-if="!canApproveForm" class="bg-warning-lighter px-3 py-2 rounded-1 mb-2">
							⚠️ <span v-html="canApproveInstruction"></span>
						</div>

						<div
							v-if="Object.keys(formRequest.required_changes).length"
							class="bg-warning-lighter px-3 py-2 rounded-1 mb-2"
						>
							⚠️ This {{ form.type }} contains
							{{ pluralize('field', Object.keys(formRequest.required_changes).length, true) }} that need
							to be corrected.
						</div>

						<div
							v-if="
								(form.department_id && personPartOfDepartment(form.department_id)) ||
									!form.department_id
							"
						>
							<button
								class="btn btn-primary me-2 px-5 my-1"
								:disabled="!canApproveForm"
								data-bs-toggle="modal"
								data-bs-target="#form-request-approve"
								@click="formRequestMessage = form.approvedMessage"
							>
								{{ approveLabel }}
							</button>

							<button
								class="btn btn-outline-danger me-2 px-4 my-1"
								data-bs-toggle="modal"
								data-bs-target="#form-request-decline"
								@click="formRequestMessage = form.deniedMessage"
							>
								Decline
							</button>
						</div>
						<p v-else class="card-text">
							This {{ form.type }} application can be approved or declined only by
							<strong>{{ departments.find(d => d.id == form.department_id).name }} </strong>
							<department-people
								:department="departments.find(d => d.id === form.department_id)"
							></department-people>
						</p>
					</div>
					<div v-else-if="formRequest.status === 'revoked'" class="card-text alert alert-danger">
						❗️ This {{ form.type }} was revoked
					</div>
					<p v-else class="card-text">This form application status is {{ formRequest.status }}</p>

					<p v-if="formRequest.reviewed_at && formRequest.valid_until" class="card-text">
						<span class="badge bg-warning-lighter text-warning"
							>Expires in {{ formRequest.valid_until | dateToNow }}</span
						>
					</p>

					<div
						v-if="
							formsWithLicenseTemplates.includes(form.slug) &&
								['approved', 'sent', 'working'].includes(formRequest.status)
						"
						class="dropdown"
					>
						<button
							class="btn btn-sm btn-outline-success dropdown-toggle"
							type="button"
							id="dropdownCertificateButton"
							data-bs-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<font-awesome-icon :icon="['fas', 'stamp']" class="text-dark me-2" />
							<span class="text-capitalize">License/Certificate</span>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownCertificateButton">
							<a
								:href="
									`${apiUrl + j.slug}/forms/${form.slug}/license-template?frs=${
										formRequest.pid
									}&print=1`
								"
								class="dropdown-item"
								target="_blank"
								>Print {{ form.type }}</a
							>
							<a
								:href="
									`${apiUrl + j.slug}/forms/${form.slug}/license-template-pdf?frs=${formRequest.pid}`
								"
								class="dropdown-item"
								:download="`${formRequest.application_number}-license.pdf`"
								>Download {{ form.type }}</a
							>
						</div>
					</div>
				</div>
			</div>

			<div v-if="formRequest" class="row mb-4">
				<div class="col-lg-7">
					<div class="card mb-4">
						<div class="card-header py-2">
							<div class="row align-items-center">
								<div class="col">
									<h5 class="mb-0">Form application</h5>
								</div>
								<div class="col-auto">
									<div class="dropdown">
										<button
											class="btn btn-sm dropdown-toggle"
											type="button"
											id="dropdownMenuButton"
											data-bs-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<font-awesome-icon :icon="['fas', 'file-pdf']" class="text-danger" />
											Download PDF
										</button>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a
												:href="
													`${apiUrl + j.slug}/form-requests/${
														formRequest.pid
													}/pdf?appUrl=${url}`
												"
												class="dropdown-item"
												target="_blank"
												>Filled PDF</a
											>
											<a
												:href="
													`${apiUrl + j.slug}/form-requests/${
														formRequest.pid
													}/pdf?redacted=1&appUrl=${url}`
												"
												class="dropdown-item"
												target="_blank"
												>Redacted PDF</a
											>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div
								v-for="(step, index) in form.steps"
								:key="`fs-${step.id}`"
								class="form-step-answers border-bottom mb-3 pb-2"
								:class="{ 'step-required': step.review_required }"
								:id="`form-step-${step.id}`"
							>
								<div class="row align-items-center mb-2">
									<div class="col">
										<h3 class="text-neutral-500 my-0">{{ step.name }}</h3>
									</div>
									<div class="col-auto">
										<span class="badge text-info bg-info-lighter">Step {{ index + 1 }}</span>
									</div>
								</div>

								<p v-if="step.description">{{ step.description }}</p>

								<div
									v-for="field in step.fields.filter(f => elementIsVisible(f, formFieldsWithValues))"
									:key="`fsf-${field.id}`"
									:id="`form-field-${field.id}`"
								>
									<div
										v-if="field.type === 'TextElement'"
										class="element"
										:class="[field.class]"
										v-html="fillTextWithVariables(field.value, formFieldsWithValues)"
									></div>
									<div
										v-else-if="
											['FileUploadElement', 'SignatureElement', 'TableElement'].includes(
												field.type
											)
										"
										class="form-field-answer row gx-3 align-items-center rounded py-2 on-parent"
									>
										<div class="col mb-2">
											<p class="mb-0">{{ field.label }}:</p>
										</div>

										<div v-if="step.visible_to === 'EVERYONE'" class="col-auto mb-2">
											<div
												class="dropdown"
												:class="{ 'show-on-hover': !formRequest.required_changes[field.id] }"
											>
												<button
													class="btn btn-sm dropdown-toggle"
													:class="
														formRequest.required_changes[field.id]
															? 'btn-outline-warning'
															: 'btn-outline-primary'
													"
													id="form-request-hg-actions"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													⠇
												</button>
												<ul class="dropdown-menu" aria-labelledby="form-request-hg-actions">
													<li v-if="formRequest.required_changes[field.id]">
														<span class="dropdown-item-text text-warning"
															>Change requested</span
														>
													</li>
													<li v-if="formRequest.required_changes[field.id]">
														<button
															class="dropdown-item"
															@click="fieldRequestChangeCancel(field)"
														>
															Cancel change request
														</button>
													</li>
													<li v-else>
														<button
															class="dropdown-item"
															data-bs-toggle="modal"
															data-bs-target="#form-request-field-change"
															@click="fieldToEdit = field"
														>
															Request change
														</button>
													</li>

													<template v-if="field.type === 'FileUploadElement'">
														<li><hr class="dropdown-divider" /></li>
														<li>
															<label
																class="dropdown-item"
																for="form-request-file-upload"
																@click="fieldToEdit = field"
															>
																Upload file
															</label>
														</li>
													</template>
												</ul>
											</div>
										</div>

										<div class="col-12">
											<file-upload-element
												v-if="field.type === 'FileUploadElement'"
												:currentField="{
													...field,
													value: formRequest.answers[field.id],
												}"
												:formRequest="formRequest"
												mode="preview"
											></file-upload-element>
											<signature-element
												v-else-if="field.type === 'SignatureElement'"
												:currentField="{
													...field,
													value: {
														name: (fieldTypeAnswer('NameInputElement') || '')
															.split(',')
															.filter(Boolean)
															.join(' '),
														...formRequest.answers[field.id],
													},
												}"
												mode="preview"
											></signature-element>
											<table-element
												v-else-if="field.type === 'TableElement'"
												:currentField="{
													...field,
													value: formRequest.answers[field.id] || [],
													pdf: true,
												}"
											></table-element>
										</div>
									</div>
									<div
										v-else-if="field.type === 'PaymentElement'"
										class="form-field-answer row align-items-center rounded py-2 on-parent"
									>
										<div class="col">
											<p class="mb-2">
												{{ field.label }}:

												<strong v-if="formRequest.answers[field.id]">{{
													formRequest.answers[field.id].price | currency
												}}</strong>
											</p>

											<div
												v-if="
													formRequest.answers[field.id] &&
														['succeeded', 'requires_capture', 'canceled'].includes(
															formRequest.answers[field.id].status
														)
												"
											>
												<p class="my-2">
													<payment-status
														:key="
															`${formRequest.answers[field.id].payment_id}-${
																formRequest.answers[field.id].status
															}`
														"
														:payment="formRequest.answers[field.id]"
														:tooltip="true"
														class="me-1"
													/>
													<payment-field-summary
														:payment="formRequest.answers[field.id]"
														class="me-1"
													></payment-field-summary>
													<span v-if="field.data.paymentDepartment"
														>to
														<router-link
															:to="`/${j.slug}/payments/${field.data.paymentDepartment}`"
														>
															<strong v-if="departments && departments.length">{{
																departments.find(
																	d => d.id == field.data.paymentDepartment
																).name
															}}</strong
															><span v-else
																>[department {{ field.data.paymentDepartment }}]</span
															></router-link
														></span
													>
												</p>

												<p v-if="formRequest.answers[field.id].fee" class="my-2">
													Payment fee: {{ formRequest.answers[field.id].fee | currency }}
												</p>

												<div
													v-if="formRequest.answers[field.id].status === 'requires_capture'"
													class="alert alert-info"
												>
													💸 {{ approveLabel }} to capture the payment, or Decline to release
													back to card
												</div>
											</div>
											<div
												v-else-if="
													formRequest.answers[field.id] &&
														['requires_payment_method', 'pending'].includes(
															formRequest.answers[field.id].status
														)
												"
											>
												<p class="mb-2 text-muted">⚠️ Not paid yet</p>
												<div class="bg-warning-lighter p-3 rounded-1">
													<p class="mb-2">💰 Collect payment now:</p>
													<p class="mb-0">
														<button
															class="btn btn-sm btn-outline-dark me-2"
															data-bs-toggle="modal"
															data-bs-target="#payment-terminal"
															@click="startPaymentTerminal(field)"
														>
															💳 Send to card reader
														</button>
														<button
															class="btn btn-sm btn-outline-dark me-2"
															data-bs-toggle="modal"
															data-bs-target="#modal-payment-offline"
															@click="
																fieldPayment = field
																paymentOffline.amount =
																	formRequest.answers[field.id].price
															"
														>
															<font-awesome-icon :icon="['fas', 'money-bill-wave']" />
															Cash or Check
														</button>
													</p>
												</div>
											</div>
											<p v-else class="my-1">
												{{ formRequest.answers[field.id] }}
											</p>
										</div>
									</div>
									<div v-else class="form-field-answer row align-items-center rounded py-2 on-parent">
										<div class="col">
											{{ field.label.trim()
											}}<span v-if="!field.label.trim().endsWith('?')">: </span>
											<strong class="ms-1"
												>{{ displayFieldAnswer(field, formRequest.answers[field.id]) }}
											</strong>
											<small
												v-if="
													field.type === 'DatePickerElement' && formRequest.answers[field.id]
												"
												class="text-neutral-500"
												>({{ formRequest.answers[field.id] | dateToNow }})</small
											>
											<template
												v-else-if="
													field.type === 'AddressInputElement' &&
														j.location.polygon &&
														formRequest.answers[field.id] &&
														formRequest.answers[field.id].lat
												"
												class="text-neutral-500"
											>
												<span
													v-if="
														isLatLngInPolygon(
															formRequest.answers[field.id].lat,
															formRequest.answers[field.id].lng,
															j.location.polygon
														)
													"
													class="badge bg-success-50 text-success-400"
													>✓ Inside {{ j.type }} limits</span
												>
												<span v-else class="badge bg-danger-50 text-danger-400"
													>🚫 Outside {{ j.type }} limits</span
												>
											</template>
										</div>

										<div v-if="step.visible_to === 'EVERYONE'" class="col-auto mb-2">
											<div
												class="dropdown"
												:class="{ 'show-on-hover': !formRequest.required_changes[field.id] }"
											>
												<button
													class="btn btn-sm dropdown-toggle"
													:class="
														formRequest.required_changes[field.id]
															? 'btn-outline-warning'
															: 'btn-outline-primary'
													"
													id="form-request-hg-actions"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													⠇
												</button>
												<ul class="dropdown-menu" aria-labelledby="form-request-hg-actions">
													<li v-if="formRequest.required_changes[field.id]">
														<span class="dropdown-item-text text-warning"
															>Change requested</span
														>
													</li>
													<li v-if="formRequest.required_changes[field.id]">
														<button
															class="dropdown-item"
															@click="fieldRequestChangeCancel(field)"
														>
															Cancel change request
														</button>
													</li>
													<li v-else>
														<button
															class="dropdown-item"
															data-bs-toggle="modal"
															data-bs-target="#form-request-field-change"
															@click="fieldToEdit = field"
														>
															Request change
														</button>
													</li>
													<li><hr class="dropdown-divider" /></li>
													<li>
														<button
															class="dropdown-item"
															data-bs-toggle="modal"
															data-bs-target="#form-request-field-edit"
															@click="formRequestEditField(field)"
														>
															Edit answer
														</button>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div v-if="step.review_required && departments && departments.length">
									<div
										v-if="
											formRequest.steps[step.id] &&
												['approved', 'declined'].includes(formRequest.steps[step.id].status)
										"
									>
										<div
											class="alert mb-0 mt-1"
											:class="[
												formRequest.steps[step.id].status === 'approved'
													? 'alert-success'
													: 'alert-danger',
											]"
										>
											<div class="row align-items-center">
												<div class="col">
													<p class="mb-0">
														Section {{ formRequest.steps[step.id].status }} by
														<person-link
															:id="formRequest.steps[step.id].reviewer_id"
															:avatar="18"
														></person-link>
														<span v-if="formRequest.steps[step.id].message" class="ms-1"
															>with message "{{
																formRequest.steps[step.id].message
															}}"</span
														>
													</p>
												</div>
												<div
													v-if="personPartOfDepartment(step.review_department_id)"
													class="col-auto"
												>
													<button
														class="btn btn-sm"
														data-bs-toggle="modal"
														data-bs-target="#modal-form-request-step-review"
														@click="
															currentStepToReviewStatus =
																formRequest.steps[step.id].status
															currentStepToReviewMessage =
																formRequest.steps[step.id].message
															currentStepToReview = step
														"
													>
														Edit review
													</button>
												</div>
											</div>
										</div>
									</div>
									<div
										v-else-if="!personPartOfDepartment(step.review_department_id)"
										class="alert alert-info mt-1"
									>
										⚠️ This section is awaiting review from
										<strong>{{
											departments.find(d => d.id === step.review_department_id).name
										}}</strong
										>. Notifications were sent to
										<department-people
											:department="departments.find(d => d.id === step.review_department_id)"
										></department-people>
									</div>
									<div v-else class="bg-warning-lighter rounded p-3 mt-1">
										<p class="mb-2">
											👋🏼 You're part of "{{
												departments.find(d => d.id === step.review_department_id).name
											}}", so this step needs your review.
										</p>
										<p class="mb-0">
											<button
												class="btn btn-sm btn-outline-success me-2"
												data-bs-toggle="modal"
												data-bs-target="#modal-form-request-step-review"
												@click="
													currentStepToReviewStatus = 'approved'
													currentStepToReview = step
												"
											>
												Approve "{{ step.name }}"
											</button>
											<button
												class="btn btn-sm btn-outline-danger me-2"
												data-bs-toggle="modal"
												data-bs-target="#modal-form-request-step-review"
												@click="
													currentStepToReviewStatus = 'declined'
													currentStepToReview = step
												"
											>
												Reject "{{ step.name }}"
											</button>
										</p>
									</div>
								</div>
							</div>

							<input
								type="file"
								multiple
								class="d-none"
								id="form-request-file-upload"
								@change="uploadFile($event)"
							/>
						</div>
					</div>
				</div>

				<div class="col position-relative">
					<div class="card mb-4">
						<div class="card-header">
							<h5 class="mb-0">Applicant info</h5>
						</div>
						<div v-if="formRequest" class="card-body">
							<person-card
								v-if="people[formRequest.person_id]"
								:person="people[formRequest.person_id]"
								:avatarSize="80"
							></person-card>
						</div>
					</div>

					<div v-if="isStaff" class="card border-danger mb-4">
						<div class="card-header">
							<h5 class="mb-0">Payments</h5>
						</div>
						<div v-if="formRequest" class="card-body">
							<p>All payments linked to this submission.</p>

							<div v-if="payments.length" class="card-table mb-3">
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th>Amount + Fee</th>
											<th>Status</th>
											<th>Date</th>
											<th>Method</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="payment in payments" :key="payment.id">
											<td>{{ payment.amount | currency }} + {{ payment.fee | currency }}</td>
											<td>
												<payment-status :payment="payment" :tooltip="true"></payment-status>
											</td>
											<td>{{ payment.created_at | dateLocal }}</td>
											<td>
												<payment-method
													v-if="payment.payment_method"
													:paymentMethod="payment.payment_method"
												></payment-method>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<p v-else class="text-center text-neutral-400"><i>There are no payments..</i></p>

							<p class="card-text text-center">
								<button class="btn btn-sm btn-outline-primary mx-2" disabled>Add payment</button>
								<button class="btn btn-sm btn-outline-primary mx-2" disabled>Request payment</button>
							</p>
						</div>
					</div>

					<div v-if="mapLocations.length > 1" class="card mb-4">
						<div class="card-header">
							<h5 class="mb-0">Locations on map</h5>
						</div>
						<div v-if="formRequest" class="card-body pt-0">
							<div class="card-table mb-3">
								<muni-map
									:height="350"
									:markers="mapLocations"
									:adjustZoom="-1"
									:includeVenues="false"
								></muni-map>
							</div>

							<p
								v-for="mapLocation in mapLocations.filter(m => m.label !== 'I')"
								:key="mapLocation.address"
								class="card-text"
							>
								{{ mapLocation.title }}: <strong>{{ mapLocation.address }}</strong>
								<span class="ms-1 text-neutral-400"
									>({{
										Number(mapLocation.distance).toLocaleString('default', {
											style: 'unit',
											unit: 'mile',
										})
									}}
									away)</span
								>
							</p>
						</div>
					</div>

					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">Application timeline</h5>
						</div>
						<div v-if="formRequest" class="card-body pt-0 px-3">
							<activity-timeline :activities="formRequestActivity"></activity-timeline>
						</div>
						<div class="card-footer text-center">
							<button
								class="btn btn-sm btn-outline-warning me-2 my-1"
								data-bs-toggle="modal"
								data-bs-target="#form-request-send-message"
								@click="messageType = 'note'"
							>
								Add internal note <font-awesome-icon :icon="['fas', 'sticky-note']" />
							</button>
							<button
								class="btn btn-sm btn-outline-info my-1"
								data-bs-toggle="modal"
								data-bs-target="#form-request-send-message"
								@click="messageType = 'message'"
							>
								Send a message <font-awesome-icon :icon="['fas', 'paper-plane']" />
							</button>
						</div>
					</div>
				</div>
			</div>

			<div v-if="formRequest" class="card mb-5">
				<div class="card-header">
					<h5 class="my-0">🛡 Risk insights</h5>
				</div>
				<div class="card-body">
					<p class="mb-4">
						HeyGov provides all the information to help you detect fraudulent submissions.
					</p>

					<div v-if="risks.ip" class="alert alert-warning text-dark d-flex align-items-center mb-4">
						<font-awesome-icon :icon="['fas', 'info-circle']" class="me-2" />
						<div>
							This application is sent from {{ flag(formRequest.ip_info.country) }}
							{{ formRequest.ip_info.city }}, {{ formRequest.ip_info.country }}, which is
							{{
								distanceBetweenCoordinates(
									formRequest.ip_info.latLng[0],
									formRequest.ip_info.latLng[1],
									j.location.lat,
									j.location.lng,
									'miles'
								)
							}}mi away from {{ j.name }}.
						</div>
					</div>

					<div class="row">
						<div v-if="people[formRequest.person_id]" class="col-lg-6">
							<h6 class="subtitle">Applicant</h6>

							<p class="mb-1">
								Name: <strong>{{ people[formRequest.person_id].name }}</strong>
							</p>
							<p class="mb-1">
								Email: <strong>{{ people[formRequest.person_id].email }}</strong>
							</p>
							<p class="mb-1">
								Email verified: ✅
								<small class="text-muted">Confirmed by clicking a link in email</small>
							</p>
							<p v-if="people[formRequest.person_id].name" class="mb-1">
								How similar are applicant name and email?
								<span
									v-if="
										compareTwoStrings(
											people[formRequest.person_id].name,
											people[formRequest.person_id].email.split('@').shift()
										) < 0.25
									"
									class="me-1"
									>⚠️</span
								>
								<strong
									>{{
										(
											compareTwoStrings(
												people[formRequest.person_id].name,
												people[formRequest.person_id].email.split('@').shift()
											) * 100
										).toFixed(2)
									}}%</strong
								>
							</p>
							<p class="mb-1">Approval rate for forms associated with this email: <strong>60%</strong></p>
							<p>
								Device:
								<strong
									>🖥
									{{
										[
											formRequest.ua.device ? formRequest.ua.device.name : null,
											formRequest.ua.os.name,
											formRequest.ua.browser.name,
										]
											.filter(Boolean)
											.join(' / ')
									}}</strong
								>
							</p>

							<div v-if="fieldTypeAnswer('SignatureElement')">
								<h6 class="subtitle mt-4">Signature</h6>
								<p class="mb-1">
									Signature type:
									<strong>{{ fieldTypeAnswer('SignatureElement').type }} signature</strong>
								</p>
								<p class="mb-1">
									Related signatures: <strong><u>3 signatures on other forms</u></strong>
								</p>
								<p class="mb-1">Signatures similarity: <strong>95% ✅</strong></p>
							</div>
						</div>

						<div class="col-lg-6">
							<h6 class="subtitle">Locations</h6>

							<muni-map
								class="mb-3"
								:height="350"
								:markers="mapLocations"
								:adjustZoom="-1"
								:includeVenues="false"
							></muni-map>

							<ul class="nicer-list">
								<li v-for="mapLocation in mapLocations" :key="mapLocation.address">
									<strong class="text-secondary">{{ mapLocation.label }}</strong>
									{{ mapLocation.title }}: <strong>{{ mapLocation.address }}</strong>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="state === 'error'" class="alert alert-danger">
			⚠️ Oops, we couldn't find this form application.
		</div>

		<div v-else class="text-center">
			<span class="spinner-border spinner-border-sm" role="status"></span> Loading form application
		</div>

		<div class="modal fade" id="modal-form-request-step-review" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form v-if="currentStepToReview" @submit.prevent="updateStepStatus" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Review step</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="form-row mb-3">
							<label class="form-label d-block">Review outcome:</label>

							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="radio"
									v-model="currentStepToReviewStatus"
									id="form-step-review-approved"
									value="approved"
								/>
								<label class="form-check-label text-success" for="form-step-review-approved"
									>Approved</label
								>
							</div>
							<div class="form-check form-check-inline">
								<input
									class="form-check-input"
									type="radio"
									v-model="currentStepToReviewStatus"
									id="form-step-review-rejected"
									value="declined"
								/>
								<label class="form-check-label text-danger" for="form-step-review-rejected"
									>Rejected</label
								>
							</div>
						</div>

						<div v-if="currentStepToReview.visible_to === 'DEPARTMENT'">
							<div
								v-for="field in currentStepToReview.fields.filter(f => f.type !== 'TextElement')"
								:key="`fr-review-field-${field.id}`"
								class="form-row mb-3"
							>
								<label class="form-label" :for="`fsf-${field.id}`"
									>{{ field.label }}
									<strong v-if="field.required" class="text-danger">*</strong></label
								>

								<component
									:is="field.type"
									:currentField="field"
									:formRequest="formRequest"
									class="element"
								></component>
							</div>
						</div>

						<div class="form-row mb-3">
							<label class="form-label">Review notes</label>

							<textarea
								class="form-control"
								v-model="currentStepToReviewMessage"
								:placeholder="
									currentStepToReviewStatus === 'approved'
										? 'Why are you approving this?'
										: 'Why are you declining this?'
								"
							></textarea>
						</div>
					</div>
					<div class="modal-footer justify-content-between">
						<button type="button" class="btn btn-link text-dark" data-bs-dismiss="modal">Cancel</button>
						<button class="btn btn-primary px-5">Save</button>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="payment-terminal" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="fieldPayment && departments && departments.length" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Collect payment with card reader</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p>
							Payment will be sent to
							<router-link
								v-if="fieldPayment.data.paymentDepartment"
								:to="`/${j.slug}/payments/${fieldPayment.data.paymentDepartment}`"
							>
								{{ departments.find(d => d.id == fieldPayment.data.paymentDepartment).name }}
								account</router-link
							>
							<i v-else>(unknown account)</i>
						</p>
						<p class="mb-1">
							Subtotal:
							<strong>{{
								formRequest.answers[fieldPayment.id].price.toLocaleString('en-US', {
									style: 'currency',
									currency: 'usd',
								})
							}}</strong>
						</p>
						<p class="mb-1">
							Payment fee:
							<strong>{{
								formRequest.answers[fieldPayment.id].fee.toLocaleString('en-US', {
									style: 'currency',
									currency: 'usd',
								})
							}}</strong>
						</p>
						<p class="lead mb-3">
							Total:
							<strong>{{
								(formRequest.answers[fieldPayment.id].amount / 100).toLocaleString('en-US', {
									style: 'currency',
									currency: 'usd',
								})
							}}</strong>
						</p>

						<hr />

						<div
							v-if="formRequest.answers[fieldPayment.id].status === 'succeeded'"
							class="text-center py-5"
						>
							<h4>✅ Payment successful</h4>
						</div>
						<div v-else>
							<p class="subtitle mb-2 cursor-pointer" @click="discoverReaderHandler">
								Available card readers:
							</p>
							<p class="mb-4">
								<button
									v-for="reader in terminalReaders"
									:key="`reader-${reader.id}`"
									class="btn btn-sm btn-outline-dark"
								>
									<span v-if="reader.id === terminalReaderConnected">✅</span>
									<span
										v-else-if="terminalReaderState === 'connecting'"
										class="spinner-border spinner-border-sm"
										role="status"
									></span>
									{{ reader.label }} ({{ reader.device_type }})
								</button>
							</p>

							<div v-if="terminalReaderConnected">
								<div v-if="0" class="form-group form-check mb-3">
									<input
										type="checkbox"
										class="form-check-input"
										checked
										id="form-request-check-receipt"
										v-model="paymentSendReceipt"
									/>
									<label class="form-check-label" for="form-request-check-receipt"
										>Send payment receipt at
										<code>{{ people[formRequest.person_id].email }}</code> on successful
										payment</label
									>
								</div>

								<p class="text-center">
									<button
										class="btn btn-primary"
										@click="collectPayment(formRequest.answers[fieldPayment.id].client_secret)"
										:disabled="paymentState === 'loading'"
									>
										<span
											v-if="paymentState === 'loading'"
											class="spinner-border spinner-border-sm"
											role="status"
										></span>
										Start payment in terminal
									</button>
								</p>
							</div>

							<div v-if="paymentError" class="alert alert-danger" v-html="paymentError"></div>
						</div>
					</div>
					<div v-if="formRequest.answers[fieldPayment.id].status === 'succeeded'" class="modal-footer">
						<button class="btn btn-outline-primary" data-bs-dismiss="modal">Continue</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-payment-offline" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="formRequest && fieldPayment" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">In person payment</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div
							v-if="formRequest.answers[fieldPayment.id].status === 'succeeded'"
							class="text-center py-5"
						>
							<h4>✅ Payment successful</h4>
						</div>

						<div v-else>
							<p>Record payment with cash or check.</p>

							<div class="form-group mb-3">
								<label class="form-label" for="payment-offline-amount"
									>Amount paid <small class="text-danger">*</small></label
								>

								<div class="input-group mb-2">
									<span class="input-group-text">$</span>
									<input
										type="number"
										class="form-control"
										id="payment-offline-amount"
										decimals="2"
										v-model="paymentOffline.amount"
									/>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="payment-offline-type-cash"
									>Payment method <small class="text-danger">*</small></label
								>

								<div>
									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											id="payment-offline-type-cash"
											type="radio"
											v-model="paymentOffline.type"
											value="cash"
										/>
										<label class="form-check-label" for="payment-offline-type-cash">Cash</label>
									</div>
									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											id="payment-offline-type-check"
											type="radio"
											v-model="paymentOffline.type"
											value="check"
										/>
										<label class="form-check-label" for="payment-offline-type-check">Check</label>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="payment-offline-note">Payment details</label>

								<textarea
									class="form-control"
									id="payment-offline-note"
									v-model="paymentOffline.note"
									placeholder="Check number, cash note or any other detail"
								></textarea>
							</div>

							<div v-if="0" class="form-group form-check">
								<input
									type="checkbox"
									class="form-check-input"
									checked
									id="form-request-check-receipt"
									v-model="paymentOffline.receipt"
								/>
								<label class="form-check-label" for="form-request-check-receipt"
									>Send payment receipt at
									<code>{{ people[formRequest.person_id].email }}</code></label
								>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							v-if="formRequest.answers[fieldPayment.id].status === 'succeeded'"
							class="btn btn-outline-primary"
							data-bs-dismiss="modal"
						>
							Continue
						</button>
						<button
							v-else-if="fieldPayment"
							class="btn btn-primary"
							type="button"
							@click.prevent="paymentOfflineRegister(fieldPayment)"
						>
							Register payment
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="form-request-field-change" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="fieldRequestChangeSend">
					<div v-if="formRequest && fieldToEdit" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Request answer change</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p class="mb-2">
								Request a change for <strong>"{{ fieldToEdit.label }}"</strong>
							</p>
							<p>
								The request will be sent to
								<person-link :id="formRequest.person_id" :avatar="16"></person-link> by in-app
								notification, email and text.
							</p>

							<div class="form-group mb-2">
								<label class="form-label" for="form-request-change-message"
									>Why does this need editing?</label
								>

								<textarea
									class="form-control"
									id="form-request-change-message"
									v-model="formRequestMessage"
									required
									placeholder="Short and clear message about the change"
								></textarea>
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary" :disabled="fieldEditStatus === 'loading'">
								Send change request
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="form-request-field-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="formRequestFieldSaveAnswer">
					<div v-if="formRequest && fieldToEdit" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Edit answer</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>
								Edit the answer for <strong>"{{ fieldToEdit.label }}"</strong>. The change will be
								documented in the submission timeline.
							</p>

							<div class="form-group">
								<label :for="`fsf-${fieldToEdit.id}`" class="form-label">{{ fieldToEdit.label }}</label>

								<component
									:is="fieldToEdit.type"
									:currentField="fieldToEdit"
									:formRequest="formRequest"
									class="element"
									v-on:change="$emit('change', $event.target.value)"
								></component>
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary" :disabled="fieldEditStatus === 'loading'">
								Update answer
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Send message modal -->
		<div class="modal fade" id="form-request-send-message" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="formRequestSendMessage">
					<div v-if="formRequest" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">
								<span v-if="messageType === 'message'">Send a message</span>
								<span v-else-if="messageType === 'note'">Add internal note</span>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p v-if="messageType === 'message'">
								The message will be delivered to applicant by email and app notification.
							</p>
							<div v-else>
								<p class="mb-2">ℹ️ Internal notes can be seen only by {{ j.type }} staff.</p>
								<p>
									The note will be delivered by email and app notification to members of departmets
									assigned to this application.
								</p>
							</div>

							<div class="form-group mb-2">
								<label class="form-label" v-if="messageType === 'message'">What's your message?</label>
								<label class="form-label" v-else-if="messageType === 'note'">Internal note</label>

								<textarea
									required
									v-model="formRequestMessage"
									placeholder="Type a nice message"
									class="form-control"
								></textarea>
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-primary">
								Send
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="form-request-decline" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="formRequestDecline">
					<div v-if="formRequest" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Decline application</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>So you're declining this application..</p>

							<div
								v-if="fieldType('PaymentElement') && fieldTypeAnswer('PaymentElement')"
								class="bg-light rounded-1 p-2 mb-3"
							>
								<div v-if="fieldTypeAnswer('PaymentElement').status === 'succeeded'">
									<p class="mb-2">
										<payment-status
											:payment="fieldTypeAnswer('PaymentElement')"
											:tooltip="true"
											class="me-1"
										/>
										<payment-field-summary
											:payment="fieldTypeAnswer('PaymentElement')"
											class="me-1"
										></payment-field-summary>
									</p>
									<p class="mb-0">
										Money is already in {{ j.type }} balance. It can be refunded from HeyGov Pay if
										needed
									</p>
								</div>
								<div v-else-if="fieldTypeAnswer('PaymentElement').status === 'requires_capture'">
									<p class="mb-2">
										<payment-status
											:payment="fieldTypeAnswer('PaymentElement')"
											:tooltip="true"
											class="me-1"
										/>
										<payment-field-summary
											:payment="fieldTypeAnswer('PaymentElement')"
											class="me-1"
										></payment-field-summary>
									</p>
									<p class="mb-0">The total amount will be released back to applicant's card.</p>
								</div>
								<div
									v-else-if="
										['requires_payment_method', 'pending'].includes(
											fieldTypeAnswer('PaymentElement').status
										)
									"
								>
									<p class="mb-0">{{ fieldType('PaymentElement').label }}: No payment was made</p>
								</div>
								<pre v-else>{{ fieldTypeAnswer('PaymentElement') }}</pre>
							</div>
							<div v-else-if="fieldType('PaymentElement')">
								<p>We couldn't find any info about the payment..</p>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="form-decline-msg"
									>Why are you rejecting this application?</label
								>

								<textarea
									class="form-control"
									id="form-decline-msg"
									v-model="formRequestMessage"
									required
									placeholder="Type a nice message"
								></textarea>
							</div>

							<p class="mb-0">A confirmation will be sent to applicant by email and app notification.</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-danger">
								Decline application
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Approve Form Modal -->
		<div class="modal fade" id="form-request-approve" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="formRequestApprove">
					<div v-if="formRequest" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">
								{{ approveLabel }} {{ formRequest.application_number || formRequest.pid }}
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>So you're approving this application 😀</p>

							<div
								v-if="fieldType('PaymentElement') && fieldTypeAnswer('PaymentElement')"
								class="bg-light rounded-1 p-2 mb-3"
							>
								<div v-if="fieldTypeAnswer('PaymentElement').status === 'succeeded'">
									<p class="mb-2">
										<payment-status
											:payment="fieldTypeAnswer('PaymentElement')"
											:tooltip="true"
											class="me-1"
										/>
										<payment-field-summary
											:payment="fieldTypeAnswer('PaymentElement')"
											class="me-1"
										></payment-field-summary>
									</p>
									<p class="mb-0">Money is already in {{ j.type }} balance.</p>
								</div>
								<div v-else-if="fieldTypeAnswer('PaymentElement').status === 'requires_capture'">
									<p class="mb-2">
										<payment-status
											:payment="fieldTypeAnswer('PaymentElement')"
											:tooltip="true"
											class="me-1"
										/>
										<payment-field-summary
											:payment="fieldTypeAnswer('PaymentElement')"
											class="me-1"
										></payment-field-summary>
									</p>
									<p class="mb-0">Money will be moved from card to {{ j.type }} balance.</p>
								</div>
								<pre v-else>{{ fieldTypeAnswer('PaymentElement') }}</pre>
							</div>
							<div v-else-if="fieldType('PaymentElement')">
								<p>We couldn't find any info about the payment..</p>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="form-request-approve-msg"
									>Why are you approving this application?</label
								>

								<textarea
									class="form-control"
									id="form-request-approve-msg"
									v-model="formRequestMessage"
									required
									placeholder="Type a nice message"
								></textarea>
							</div>

							<div v-if="form.expiry_date" class="form-group mb-3">
								<label class="form-label" for="form-approve-expiry">How long is this valid for?</label>
								<input
									type="date"
									class="form-control"
									id="form-approve-expiry"
									v-model="formRequest.valid_until"
								/>
							</div>

							<p class="mb-0">A confirmation will be sent to applicant by email and app notification.</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary px-5">
								{{ approveLabel }}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="modal-form-request-revoke" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="formRequestRevoke">
					<div v-if="formRequest" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Revoke approved {{ form.type }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>😬 So you want to revoke this {{ form.type }}..</p>

							<div class="form-group mb-3">
								<label class="form-label" for="form-revoke-msg"
									>Why are you revoking this {{ form.type }}?</label
								>

								<textarea
									class="form-control"
									id="form-revoke-msg"
									v-model="formRequestMessage"
									required
									placeholder="Type a nice message"
								></textarea>
							</div>

							<p class="mb-0">
								A notification will be sent to
								<person-link :id="formRequest.person_id" :avatar="16"></person-link> by email, text and
								app notification.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-danger">
								Revoke application
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.hover {
	&:hover {
		background: rgba(0, 0, 0, 0.02);
	}

	&.hover-success {
		background: rgba(141, 211, 132, 0.1);
		border-left: 2px solid var(--success);

		&:hover {
			background: rgba(141, 211, 132, 0.2);
		}
	}

	&.hover-danger {
		background: #ffe3de;
		border-left: 2px solid var(--danger);

		//color: #853c2e;
		//background-color: #ffe3de;
		//border-color: #ffd8d1;

		&:hover {
			background: #ffd8d1;
		}
	}

	&.hover-warning {
		background: #fff7e1;
		border-left: 2px solid var(--warning);

		&:hover {
			background: rgb(254, 245, 215);
		}
	}
}

.form-field-answer {
	border: 1px solid transparent;

	&:hover {
		border-color: rgba(0, 0, 0, 0.05);
		background: rgba(0, 0, 0, 0.02);
	}
}

.form-new-message {
	border-top: 1px solid #ccc;

	.nav-message-type {
		margin-bottom: 0.5rem;

		.nav-link {
			border-bottom: 2px solid transparent;
			padding-left: 2px;
			padding-right: 2px;
			margin-right: 1rem;
		}

		.nav-link-text.active {
			color: var(--primary);
			border-color: var(--primary);
		}
		.nav-link-note.active {
			color: var(--secondary);
			border-color: var(--secondary);
		}
	}

	textarea {
		border: 0;
		padding: 2px;

		&:focus {
			box-shadow: none;
		}
	}

	&.form-new-message-note textarea {
		background-color: #ffb8001f;
	}
	.input-tel__input {
		border: none !important;
	}
}
</style>

<script>
import { Modal } from 'bootstrap'
import { add, format, parseISO } from 'date-fns'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { flag } from 'country-emoji'
import { compareTwoStrings } from 'string-similarity'
import pluralize from 'pluralize'

import heyGovApi from '@/api.js'
import {
	elementIsVisible,
	fillTextWithVariables,
	formRequestStatuses,
	formsWithLicenseTemplates,
} from '@/actions/forms.js'
import { displayFieldAnswer } from '@/actions/form-builder.js'
import { stripeFee } from '@/actions/payments.js'
import { distanceBetweenCoordinates, isAddressObject, isLatLngInPolygon } from '@/lib/geo.js'
import { urlIsImage, urlIsDocument, urlIsVideo, formatAddress, tracker, handleResponseError } from '@/utils.js'

import MuniMap from '@/components/MuniMap.vue'
import DepartmentPeople from '@/components/DepartmentPeople.vue'
import PersonAvatar from '@/components/PersonAvatar.vue'
import PersonCard from '@/components/PersonCard.vue'
import PersonLink from '@/components/PersonLink.vue'
import PaymentFieldSummary from '../../components/forms/PaymentFieldSummary.vue'
import PaymentStatus from '../../components/payments/PaymentStatus.vue'
import PaymentMethod from '../../components/payments/PaymentMethod.vue'
import FormRequestStatus from '@/components/forms/FormRequestStatus.vue'
import ActivityTimeline from '@/components/ActivityTimeline.vue'

import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import TimeElement from '@/components/form-builder/TimeElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import TableElement from '@/components/form-builder/TableElement'
import YearElement from '@/components/form-builder/YearElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'
import NameInputElement from '@/components/form-builder/NameInputElement'
import SignatureElement from '@/components/form-builder/SignatureElement'

export default {
	name: 'FormRequestReview',
	metaInfo() {
		return {
			title: `${this.formRequest?.application_number || this.$route.params.formRequestId} form submission`,
		}
	},
	components: {
		ActivityTimeline,
		MuniMap,
		FormRequestStatus,
		PaymentMethod,
		PaymentStatus,
		PersonAvatar,
		PersonCard,
		PersonLink,
		DepartmentPeople,
		NameInputElement,
		AddressInputElement,
		YearElement,
		TableElement,
		PhoneInputElement,
		EmailInputElement,
		FileUploadElement,
		TimeElement,
		DatePickerElement,
		CheckboxElement,
		RadioButtonElement,
		SelectListElement,
		PaymentFieldSummary,
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SignatureElement,
	},
	data() {
		return {
			formRequestStatuses,

			state: 'loading',
			form: null,
			formFields: [],
			formRequest: null,
			formRequestActivity: [],

			// edit or request change for a field
			fieldToEdit: null,
			fieldEditStatus: 'idle',
			formRequestMessage: '',
			$modalFieldChangeRequest: null,
			$modalFieldEdit: null,
			filesUploading: 0,

			fieldPayment: null,
			currentStepToReview: null,
			currentStepToReviewStatus: null,
			currentStepToReviewMessage: '',

			// payments linked to this form submission
			payments: [],

			// collect payment
			terminal: null,
			terminalReaders: null,
			terminalReaderConnected: null,
			terminalReaderState: 'idle',
			paymentState: 'idle',
			paymentError: '',
			paymentOffline: {
				amount: 0,
				type: 'cash',
				note: '',
				receipt: true,
			},
			paymentSendReceipt: true,

			url: window.location.protocol + '//' + window.location.hostname,
			$modalSendMessage: null,
			$modalStepReview: null,
			$modalReviewApprove: null,
			$modalReviewDecline: null,
			$modalReviewRevoke: null,
			messageType: '',

			risks: {
				ip: false,
				signature: false,
				email: false,
			},

			mapLocations: [],

			formsWithLicenseTemplates,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'people', 'departments']),
		...mapGetters(['currentRole', 'auth', 'isStaff']),

		stepsRequiringReview() {
			return this.form.steps.filter(step => {
				let approved = false

				if (
					this.formRequest.steps[step.id] &&
					this.formRequest.steps[step.id].status &&
					this.formRequest.steps[step.id].status === 'approved'
				) {
					approved = true
				}

				return step.review_required && !approved
			})
		},
		needsPayment() {
			let needsPayment = false

			// check if this form includes a payment field
			const paymentField = this.fieldType('PaymentElement')

			if (paymentField && !paymentField.removed) {
				//console.log('pp', this.formRequest[paymentField.id])
				if (
					!this.formRequest.answers[paymentField.id] ||
					!['succeeded', 'requires_capture'].includes(this.formRequest.answers[paymentField.id].status)
				) {
					needsPayment = true
				}
			}

			return needsPayment
		},
		canApproveInstruction() {
			const payment = this.needsPayment ? '<strong>payment</strong>' : ''
			let steps = ''

			if (this.stepsRequiringReview.length) {
				steps = this.stepsRequiringReview.map(s => `<strong>${s.name}</strong>`).join(', ')
			}

			let text = `This ${this.form.type} requires `
			text += [payment, steps].filter(Boolean).join(' and ')
			text += ` before it can be ${['license', 'permit'].includes(this.form.type) ? 'issued' : 'approved'}.`

			return text
		},
		canApproveForm() {
			let ok = true

			if (this.stepsRequiringReview.length) {
				ok = false
			}

			if (this.needsPayment) {
				ok = false
			}

			return ok
		},
		approveLabel() {
			let label = 'Approve form'

			if (this.form.type === 'license') {
				label = 'Issue license'
			} else if (this.form.type === 'permit') {
				label = 'Issue permit'
			} else if (this.form.type === 'application') {
				label = 'Approve application'
			}

			return label
		},
		formFieldsWithValues() {
			return this.formFields.map(field => {
				if (this.formRequest && this.formRequest.answers[field.id]) {
					field.value = this.formRequest.answers[field.id]
				}

				return field
			})
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/forms/${this.$route.params.formSlug}/send`)
		} else {
			tracker.event('form-request-review', this.$route.params.formSlug)
			this.loadFormAndRequest()
			this.$store.dispatch('loadDepartments')
		}
	},
	mounted() {
		setTimeout(() => {
			this.$modalSendMessage = new Modal(document.getElementById('form-request-send-message'))
			this.$modalStepReview = new Modal(document.getElementById('modal-form-request-step-review'))
			this.$modalReviewApprove = new Modal(document.getElementById('form-request-approve'))
			this.$modalReviewDecline = new Modal(document.getElementById('form-request-decline'))
			this.$modalReviewRevoke = new Modal(document.getElementById('modal-form-request-revoke'))
			this.$modalFieldChangeRequest = new Modal(document.getElementById('form-request-field-change'))
			this.$modalFieldEdit = new Modal(document.getElementById('form-request-field-edit'))
		}, 600)

		if (!('StripeTerminal' in window)) {
			let stripeScript = document.createElement('script')
			stripeScript.setAttribute('src', 'https://js.stripe.com/terminal/v1/')
			document.head.appendChild(stripeScript)
		}
	},
	methods: {
		elementIsVisible,
		fillTextWithVariables,
		flag,
		compareTwoStrings,
		displayFieldAnswer,
		urlIsImage,
		urlIsDocument,
		urlIsVideo,
		distanceBetweenCoordinates,
		pluralize,
		isLatLngInPolygon,

		// TODO move to helpers/actions files
		personPartOfDepartment(department_id) {
			return this.isStaff || this.j.roles?.find(d => d.department_id === department_id || d.role === 'ADMIN')
		},

		loadFormAndRequest() {
			this.state = 'loading'

			Promise.all([
				heyGovApi.get(`/${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`),
				heyGovApi.get(`/${this.j.slug}/form-requests/${this.$route.params.formRequestId}`),
			]).then(
				([formResponse, formRequestResponse]) => {
					// fields are JSON data, needs parsing
					formResponse.data.steps = formResponse.data.steps.map(step => {
						//todo remove data&options when server returns JSON
						step.fields = step.fields.map(field => {
							if (!field.data || typeof field.data === 'string') {
								field.data = JSON.parse(field.data || '{}')
							}
							if (field.options && typeof field.options === 'string') {
								field.options = JSON.parse(field.options)
							}

							return field
						})

						this.formFields.push(...step.fields)

						return step
					})

					this.form = formResponse.data
					this.formRequest = formRequestResponse.data

					this.$store.dispatch('loadPerson', this.formRequest.person_id)
					this.calculateExpiryDate()

					if (
						this.formRequest.ip_info &&
						this.formRequest.ip_info.loc &&
						this.formRequest.ip_info.country !== (this.j.location.country || 'US')
					) {
						this.risks.ip = true
					}

					this.addMapLocations()
					this.loadPayments()

					// load for request activity
					heyGovApi.get(`/${this.j.slug}/form-requests/${this.formRequest.pid}/activity`).then(({ data }) => {
						this.formRequestActivity.push(...data)

						this.$store.dispatch(
							'loadPeople',
							data.map(a => a.person_id)
						)

						//this.scrollToLastActivityMessage()
					})

					this.state = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading form application (${error.message})`)
					this.state = 'error'
				}
			)
		},

		loadPayments() {
			heyGovApi(`${this.j.slug}/payments?source=${this.formRequest.pid}&status=any`)
				.then(({ data }) => {
					this.payments.push(...data)
				})
				.catch(error => {
					console.warn('Error loading payments', error.message)
				})
		},

		addMapLocations() {
			// marker for applicant IP
			if (this.formRequest.ip_info) {
				this.mapLocations.push({
					title: 'Applicant IP',
					address: `${flag(this.formRequest.ip_info.country)} ${[
						this.formRequest.from_ip,
						this.formRequest.ip_info.city,
						this.formRequest.ip_info.region,
						this.formRequest.ip_info.country,
					]
						.filter(Boolean)
						.join(', ')}`,
					label: 'I',
					position: {
						lat: this.formRequest.ip_info.latLng[0],
						lng: this.formRequest.ip_info.latLng[1],
					},
					distance: distanceBetweenCoordinates(
						this.formRequest.ip_info.latLng[0],
						this.formRequest.ip_info.latLng[1],
						this.j.location.lat,
						this.j.location.lng,
						'miles'
					),
				})
			}

			this.formFields
				.filter(f => f.type === 'AddressInputElement')
				.forEach(addressField => {
					const address = this.formRequest.answers[addressField.id]

					if (isAddressObject(address) && address.lat && address.lng) {
						this.mapLocations.push({
							title: addressField.label,
							address: `${flag(address.country)} ${formatAddress(address)}`,
							label: 'A',
							position: {
								lat: address.lat,
								lng: address.lng,
							},
							distance: distanceBetweenCoordinates(
								address.lat,
								address.lng,
								this.j.location.lat,
								this.j.location.lng,
								'miles'
							),
						})
					}
				})
		},

		addActivityMessage(message) {
			return heyGovApi
				.post(`${this.j.slug}/form-requests/${this.formRequest.id}/activity`, message)
				.then(({ data }) => {
					this.formRequestActivity.unshift(data)
				})
				.catch(handleResponseError(`Couldn't add activity message ({error})`))
		},

		canMarkAsWorking(newData) {
			let working = false

			// mark application as in progress
			// if status is sent, and any change is made but status
			if (this.formRequest.status === 'sent' && !['approved', 'declined'].includes(newData?.status)) {
				this.formRequest.status = 'working'
				working = true
			}

			return working
		},
		markAsWorking() {
			if (this.canMarkAsWorking()) {
				this.updateFormRequest({ status: 'working' })
			}
		},

		updateFormRequest(data, success = () => {}) {
			if (this.canMarkAsWorking(data)) {
				data.status = 'working'
			}

			return heyGovApi
				.put(`${this.j.slug}/form-requests/${this.formRequest.id}`, data)
				.then(success)
				.catch(handleResponseError(`Couldn't update form submission ({error})`))
		},
		updateFormRequestAnswers() {
			this.updateFormRequest({
				answers: this.formRequest.answers,
			})
		},
		updateFormRequestFieldAnswer(field, answer, successMessage) {
			return new Promise((resolve, reject) => {
				heyGovApi
					.put(`${this.j.slug}/form-requests/${this.formRequest.pid}/field-answer/${field.id}`, {
						answer,
					})
					.then(
						() => {
							// update answer in formRequest
							this.$set(this.formRequest.answers, field.id, answer)

							// remove from required changes
							delete this.formRequest.required_changes[field.id]

							Vue.toasted.success(successMessage || `Answer for "${field.label}" is updated`)
							resolve()
						},
						error => {
							handleResponseError('Error updating field answer ({error})')(error)
							reject(error)
						}
					)
			})
		},

		updateFormRequestStatus(status, updateAnswers) {
			this.formRequest.status = status
			this.formRequest.reviewed_at = new Date()
			this.formRequest.reviewed_by = this.auth.id
			this.formRequest.reviewed_message = this.formRequestMessage
			this.formRequest.pdf_url = null
			this.formRequest.pdf_redacted = null

			const updatedFormRequest = {
				status,
				pdf_url: null,
				pdf_redacted: null,
				reviewed_message: this.formRequestMessage,
				reviewed_by: this.auth.id,
			}

			if (updateAnswers) {
				updatedFormRequest.answers = this.formRequest.answers
			}

			if (status === 'approved') {
				this.updateFormRequest(
					{
						...updatedFormRequest,
						valid_until: this.formRequest.valid_until,
					},
					() => {
						Vue.toasted.success(`🎉 ${this.form.type} is approved/issued`)
						this.$modalReviewApprove.hide()
					}
				)
			} else {
				this.updateFormRequest(
					{
						...updatedFormRequest,
						required_changes: {},
					},
					() => {
						Vue.toasted.show(`Application declined`)
						this.$modalReviewDecline.hide()
					}
				)
			}

			// create activity message
			//todo move in backend
			this.addActivityMessage(
				{
					message: `${this.auth.name} ${status} application with message "${this.formRequestMessage}"`,
					type: status === 'approved' ? 'success' : 'danger',
				},
				false
			)
			this.formRequestMessage = ''
		},
		formRequestDecline() {
			let updateAnswers = false
			const paymentField = this.fieldType('PaymentElement')

			if (paymentField) {
				const paymentData = this.fieldTypeAnswer('PaymentElement')

				// cancel payment
				if (paymentData?.status === 'requires_capture') {
					this.formRequest.answers[paymentField.id].status = 'canceled'
					updateAnswers = true

					heyGovApi.post(`/${this.j.slug}/payments/${paymentData.payment_uuid}/cancel`)
				}
			}

			this.updateFormRequestStatus('declined', updateAnswers)
		},
		formRequestRevoke() {
			this.formRequest.status = 'revoked'
			this.formRequest.reviewed_message = this.formRequestMessage

			const updatedFormRequest = {
				status: 'revoked',
				reviewed_message: this.formRequestMessage,
				pdf_url: null,
				pdf_redacted: null,
			}

			this.updateFormRequest(updatedFormRequest, () => {
				Vue.toasted.show(`${this.form.type} is revoked`)
				this.$modalReviewRevoke.hide()
			})

			// create activity message
			this.addActivityMessage(
				{
					message: `${this.auth.name} revoked ${this.form.type} with message "${this.formRequestMessage}"`,
					type: 'danger',
				},
				false
			)
			this.formRequestMessage = ''
		},
		formRequestApprove() {
			let instant = true
			const paymentField = this.fieldType('PaymentElement')

			const reviewWords = this.formRequestMessage
				.trim()
				.split(' ')
				.filter(Boolean)

			if (!reviewWords.length) {
				Vue.toasted.error(`Please provide a reason for approving the application`)
				return
			} else if (reviewWords.length < 2) {
				Vue.toasted.error(`A few more words for approval reason please`)
				return
			}

			if (paymentField) {
				const paymentData = this.fieldTypeAnswer('PaymentElement')

				// capture payment
				if (paymentData?.status === 'requires_capture') {
					instant = false

					heyGovApi.post(`/${this.j.slug}/payments/${paymentData.payment_uuid}/capture`).then(() => {
						this.formRequest.answers[paymentField.id].status = 'succeeded'
						this.updateFormRequestStatus('approved', true)
					}, handleResponseError(`Payment capture failed ({error})`))
				}
			}

			if (instant) {
				this.updateFormRequestStatus('approved')
			}
		},
		formRequestSendMessage() {
			this.markAsWorking()

			if (this.messageType === 'message') {
				this.addActivityMessage({
					message: `${this.auth.name} sent a message: "${this.formRequestMessage}"`,
					type: 'info',
					privacy: 'public',
					sendEmail: true,
				})
				Vue.toasted.success(`The message was sent to the applicant`)
			} else {
				this.addActivityMessage({
					message: `${this.auth.name} added a note: "${this.formRequestMessage}"`,
					type: 'warning',
					privacy: 'private',
					sendEmail: true,
				})
				Vue.toasted.success(`The note was sent to department members`)
			}

			this.formRequestMessage = ''
			this.$modalSendMessage.hide()
		},
		stepsWithReview(s) {
			let paymentStepId = null
			const paymentStep = this.fieldType('PaymentElement')

			if (paymentStep && !paymentStep.removed) {
				paymentStepId = paymentStep.form_step_id
			}

			return s.review_required || s.id === paymentStepId
		},

		updateStepStatus() {
			const step = this.currentStepToReview
			const stepInfo = this.formRequest.steps[step.id] || {}

			stepInfo.status = this.currentStepToReviewStatus
			stepInfo.message = this.currentStepToReviewMessage
			stepInfo.reviewer_id = this.auth.id

			this.$set(this.formRequest.steps, step.id, stepInfo)

			// save step info in database
			heyGovApi
				.put(`${this.j.slug}/form-requests/${this.formRequest.pid}/steps/${step.id}`, stepInfo)
				.then(({ data }) => {
					this.$set(this.formRequest, 'steps', data)
					Vue.toasted.info(`"${step.name}" is now ${stepInfo.status}`)
				}, handleResponseError('Error saving step ({error})'))

			if (step.visible_to === 'DEPARTMENT') {
				step.fields.forEach(field => {
					if (field.type !== 'TextElement') {
						this.formRequest.answers[field.id] = field.value
					}
				})

				this.updateFormRequestAnswers()
			}

			// create activity message
			let message = `${this.auth.name} ${stepInfo.status} "${step.name}"`
			if (stepInfo.message) {
				message += ` with message "${stepInfo.message}"`
			}
			this.addActivityMessage(
				{
					message,
					privacy: 'private',
					type: stepInfo.status === 'approved' ? 'success' : 'danger',
				},
				false
			)

			this.$modalStepReview.hide()
		},

		scrollToField(field) {
			const $field = document.querySelector(`#form-field-${field.id}`)

			$field.scrollIntoView({ behavior: 'smooth', block: 'center' })
		},
		scrollToStep(step) {
			const $step = document.querySelector(`#form-step-${step.id}`)

			$step.scrollIntoView({ behavior: 'smooth', block: 'center' })
		},

		fieldRequestChangeSend() {
			this.markAsWorking()
			this.fieldEditStatus = 'loading'

			heyGovApi
				.post(
					`${this.j.slug}/form-requests/${this.formRequest.pid}/field-change-request/${this.fieldToEdit.id}`,
					{
						message: this.formRequestMessage,
					}
				)
				.then(
					({ data }) => {
						this.$set(this.formRequest.required_changes, this.fieldToEdit.id, data)

						this.addActivityMessage({
							message: `${this.auth.name} requested change for "${this.fieldToEdit.label}" with message "${this.formRequestMessage}"`,
						})

						this.formRequestMessage = ''
						this.fieldEditStatus = 'idle'
						this.$modalFieldChangeRequest.hide()
					},
					error => {
						this.fieldEditStatus = 'error'
						handleResponseError('Error sending change request ({error})')(error)
					}
				)
		},

		fieldRequestChangeCancel(field) {
			if (!confirm('Are you sure you want to cancel this change request?')) {
				return
			}

			heyGovApi
				.delete(`${this.j.slug}/form-requests/${this.formRequest.pid}/field-change-request/${field.id}`)
				.then(() => {
					delete this.formRequest.required_changes[field.id]

					this.addActivityMessage({
						message: `${this.auth.name} canceled the change request for "${field.label}"`,
					})
				}, handleResponseError('Error canceling the change request ({error})'))
		},

		uploadFile($event) {
			$event.target.files.forEach(file => {
				if (file.size < 1) {
					Vue.toasted.error(`File "${file.name}" is empty`)
				} else if (file.size / 1024 / 1024 > 30) {
					Vue.toasted.error(`File "${file.name}" is too large (max 30MB)`)
				} else {
					this.filesUploading++

					// prepare file data
					var formFile = new FormData()
					formFile.append('file', file)

					Vue.toasted.show(`Uploading "${file.name}"`)

					// upload the file
					heyGovApi
						.post(`/${this.j.slug}/form-requests/${this.formRequest.id}/file`, formFile)
						.then(({ data }) => {
							if (data.path) {
								const files = this.formRequest.answers[this.fieldToEdit.id] || []
								files.push(data.path)

								this.updateFormRequestFieldAnswer(
									this.fieldToEdit,
									files,
									`"${file.name}" uploaded`
								).then(() => {
									this.addActivityMessage({
										message: `${this.auth.name} uploaded "${file.name}" for "${this.fieldToEdit.label}"`,
									})
								})
							}
						})
						.catch(handleResponseError(`Error uploading "${file.name}" ({error})`))
						.finally(() => {
							this.filesUploading--
						})
				}
			})
		},
		formRequestEditField(field) {
			this.fieldToEdit = window.structuredClone(field)
		},
		formRequestFieldSaveAnswer() {
			this.markAsWorking()
			this.fieldEditStatus = 'loading'
			const oldAnswer = displayFieldAnswer(this.fieldToEdit, this.formRequest.answers[this.fieldToEdit.id], true)

			this.updateFormRequestFieldAnswer(this.fieldToEdit, this.fieldToEdit.value).then(
				() => {
					const newAnswer = displayFieldAnswer(this.fieldToEdit, this.fieldToEdit.value, true)

					this.addActivityMessage({
						message: `${this.auth.name} updated the answer for "${this.fieldToEdit.label}" from "${oldAnswer}" to "${newAnswer}"`,
					})

					this.fieldEditStatus = 'idle'
					this.$modalFieldEdit.hide()
				},
				() => {
					this.fieldEditStatus = 'error'
				}
			)
		},

		startPaymentTerminal(field) {
			tracker.event('payment-card-reader', `form/${this.form.slug}`)
			this.fieldPayment = field
			console.log('start payment terminal')

			// fetch payment intent
			this.formRequest.answers[field.id].fee = stripeFee(this.formRequest.answers[field.id].price, 'in-person')
			this.formRequest.answers[field.id].amount =
				(this.formRequest.answers[field.id].price * 1 + this.formRequest.answers[field.id].fee * 1) * 100

			heyGovApi
				.post(`${this.j.slug}/payments?by=staff`, {
					department_id: this.fieldPayment.data.paymentDepartment,
					amount: this.formRequest.answers[field.id].price,
					fee: this.formRequest.answers[field.id].fee,
					source: this.formRequest.pid,
					payment_method_types: 'card_present',
					capture_method: 'manual',
					person_id: this.formRequest.person_id,
				})
				.then(({ data }) => {
					console.log('loaded Reader payment intent', data)
					this.formRequest.answers[field.id].client_secret = data.paymentIntent.client_secret
				})

			if (!this.terminal) {
				this.terminal = window.StripeTerminal.create({
					onFetchConnectionToken: this.fetchConnectionToken,
					onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
					onConnectionStatusChange(status) {
						console.log('terminal status', status)
					},
				})

				this.discoverReaderHandler()
			}
		},
		unexpectedDisconnect() {
			// In this function, your app should notify the user that the reader disconnected.
			// You can also include a way to attempt to reconnect to a reader.

			alert('Disconnected from reader')
		},
		async fetchConnectionToken() {
			// Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
			const re = await heyGovApi(
				`${this.j.slug}/departments/${this.fieldPayment.data.paymentDepartment}/stripe-terminal-connection-token`
			)

			return re.data.secret
		},
		discoverReaderHandler() {
			var config = {
				simulated: window.location.hostname === 'localhost',
			}

			Vue.toasted.show(`Discovering card readers`)
			this.paymentError = ''

			this.terminal.discoverReaders(config).then(discoverResult => {
				if (discoverResult.error) {
					Vue.toasted.error('Failed to discover: ', discoverResult.error)
				} else if (discoverResult.discoveredReaders.length === 0) {
					this.paymentError =
						'No available card readers. Please contact someone at HeyGov to acquire or pair one.'
				} else {
					this.terminalReaders = discoverResult.discoveredReaders
					this.connectReaderHandler(this.terminalReaders)

					console.log('terminal.discoverReaders', this.terminalReaders)
				}
			})
		},
		connectReaderHandler(readers) {
			var selectedReader = readers[0]
			Vue.toasted.show(`Attempting to connect to "${selectedReader.label}"`)
			this.terminalReaderState = 'connecting'

			this.terminal.connectReader(selectedReader).then(connectResult => {
				if (connectResult.error) {
					this.terminalReaderState = 'error'
					this.paymentError = `Error connecting to "${selectedReader.label}". ${connectResult.error.message}`
					//Vue.toasted.error(`Failed to connect: ${connectResult.error}`)
				} else {
					this.terminalReaderState = 'connected'
					this.terminalReaderConnected = selectedReader.id
					console.log('terminal.connectReader', connectResult)
					Vue.toasted.success(`Connected to reader "${connectResult.reader.label}"`)
				}
			})
		},
		collectPayment(paymentIntent) {
			//this.terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' })
			this.paymentError = ''
			this.paymentState = 'loading'

			this.terminal.collectPaymentMethod(paymentIntent).then(result => {
				if (result.error) {
					console.log(result.error)
					alert(`Payment error ~ ${result.error}`)
					this.paymentState = 'error'
				} else {
					console.log('terminal.collectPaymentMethod', result.paymentIntent)

					this.terminal.processPayment(result.paymentIntent).then(result => {
						if (result.error) {
							console.log(result.error)
							let errorCode = ''

							if (result.error.doc_url && result.error.decline_code) {
								errorCode = ` <a href="${result.error.doc_url}" target="_blank">(${result.error.decline_code})</a>`
							} else if (result.error.decline_code) {
								errorCode = ` (${result.error.decline_code})`
							}

							this.paymentError = result.error.message + errorCode
							this.paymentState = 'error'
						} else if (result.paymentIntent) {
							this.formRequest.answers[this.fieldPayment.id].status = result.paymentIntent.status
							this.capturePayment(result.paymentIntent.id)
							console.log('terminal.processPayment', result.paymentIntent)
						}
					})
				}
			})
		},
		capturePayment(payment_intent_id) {
			const field = this.fieldPayment

			heyGovApi.post(`${this.j.slug}/payments/${payment_intent_id}/capture`).then(
				({ data }) => {
					console.log('payment capture done', data)
					this.$set(this.formRequest.answers[field.id], 'status', data.status)
					this.$set(this.formRequest.answers[field.id], 'payment_method', 'card_present')
					this.updateFormRequestAnswers()
					this.paymentState = 'idle'
				},
				error => {
					Vue.toasted.error(error.message)
					this.paymentState = 'error'
				}
			)
		},
		paymentOfflineRegister(field) {
			if (field.data.paymentDepartment) {
				const payment_method = {
					type: this.paymentOffline.type,
				}
				payment_method[this.paymentOffline.type] = {
					note: this.paymentOffline.note,
				}

				//todo use new POST /payments endpoint
				heyGovApi
					.post(`${this.j.slug}/payments?by=staff`, {
						platform: 'heygov',
						department_id: field.data.paymentDepartment,
						person_id: this.formRequest.person_id,
						amount: this.paymentOffline.amount,
						description: this.paymentOffline.note,
						source: this.formRequest.pid,
						payment_method,
					})
					.then(
						({ data }) => {
							this.formRequest.answers[field.id].type = 'offline'
							this.formRequest.answers[field.id].payment_method = this.paymentOffline.type
							this.formRequest.answers[field.id].status = 'succeeded'
							this.formRequest.answers[field.id].fee = 0
							this.formRequest.answers[field.id].amount = this.paymentOffline.amount * 100
							this.formRequest.answers[field.id].note = this.paymentOffline.note
							this.formRequest.answers[field.id].payment_id = data.id
							delete this.formRequest.answers[field.id].payment_intent_id
							delete this.formRequest.answers[field.id].client_secret
							this.updateFormRequestAnswers()

							this.addActivityMessage(
								{
									message: `${this.auth.first_name || this.auth.name} took a ${
										this.paymentOffline.type
									} of $${this.paymentOffline.amount}. ${this.paymentOffline.note}`,
									person_id: this.auth.id,
									type: 'success',
								},
								false
							)
						},
						error => {
							Vue.toasted.error(`Error storing payment (${error.message})`)
						}
					)
			} else {
				Vue.toasted.error(`No department is assigned to this form!`)
			}
		},

		// TODO move some of these functions to actions/forms.js
		fieldType(fieldType) {
			return this.formFields.find(f => f.type === fieldType)
		},
		fieldTypeAnswer(fieldType) {
			const field = this.fieldType(fieldType)
			return field && this.formRequest.answers[field.id] ? this.formRequest.answers[field.id] : null
		},
		calculateExpiryDate() {
			console.log(this.form.expiry_date)
			
			// TODO is it possible?
			if (!this.form.expiry_date) {
				return
			}

			if (!['sent', 'working'].includes(this.formRequest.status)) {
				return
			}

			let date = new Date()

			// if expire is a number
			if (/^\d+$/.test(this.form.expiry_date)) {
				this.formRequest.valid_until = format(
					add(date, { days: parseInt(this.form.expiry_date) }),
					'yyyy-MM-dd'
				)
			} else {
				// or a date
				let nextExpiry
				const hasYear = this.form.expiry_date.split('-').length === 3
				if (hasYear) {
					nextExpiry = parseISO(this.form.expiry_date)
				} else {
					const year = date.getFullYear()
					nextExpiry = parseISO(`${year}-${this.form.expiry_date}`)
					if (nextExpiry < date) {
						// if nextExpiry is in the past, add a year
						nextExpiry = add(nextExpiry, { years: 1 })
					}
				}
				this.formRequest.valid_until = format(nextExpiry, 'yyyy-MM-dd')
			}
		},

		deleteFormRequest() {
			if (confirm('Are you sure you want to delete this form application?')) {
				heyGovApi.delete(`/${this.j.slug}/form-requests/${this.formRequest.pid}`).then(() => {
					Vue.toasted.info(`Application is irreversibly deleted from the mainframe`)
					this.$router.push(`/${this.j.slug}/form-requests`)
				}, handleResponseError('Error deleting form submission ({error})'))
			}
		},
	},
}
</script>
