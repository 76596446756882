import { parse, formatDistanceToNowStrict, differenceInYears } from 'date-fns'
import evaluate from 'safe-evaluate-expression'

import { displayFieldAnswer } from './form-builder.js'
import { numberToCurrency } from '../utils.js'

const formStatuses = {
	draft: {
		name: 'Draft',
		class: 'bg-gray text-dark',
		text: 'This form is in draft mode. Add steps & fields, then publish so it can accept applications.',
	},
	public: {
		name: 'Public',
		class: 'bg-success-lighter text-success',
		text: 'This form is public, and applications are being accepted.',
	},
	archived: {
		name: 'Archived',
		class: 'bg-danger-lighter text-danger',
		text:
			'This form is hidden from public view, no new submissions are allowed, but previous submissions are still available',
	},
}

const formRequestStatuses = {
	draft: {
		name: 'Draft',
		class: 'bg-gray text-dark',
		text: 'Applicant has started the form, but has not submitted it yet',
	},
	sent: {
		name: 'Received',
		class: 'bg-warning-lighter text-warning',
		text: 'Applicant has submitted the form, awaiting review',
	},
	working: {
		name: 'In progress',
		class: 'bg-info-lighter text-info',
		text: 'A clerk is currently working on the form',
	},
	approved: {
		name: 'Approved',
		class: 'bg-success-lighter text-success',
		text: 'The application has been approved',
	},
	declined: {
		name: 'Declined',
		class: 'bg-danger-lighter text-danger',
		text: 'The application has been declined',
	},
	expired: {
		name: 'Expired',
		class: 'bg-danger-lighter text-danger',
		text: 'This application/license/permit has expired',
	},
	revoked: {
		name: 'Revoked',
		class: 'bg-danger-lighter text-danger',
		text: 'This applicatoin is no longer valid',
	},
}

const formRequestStatusesApplicant = {
	...formRequestStatuses,
	sent: {
		name: 'Sent',
		class: 'bg-warning-lighter text-warning',
	},
}

const getConditionElementValue = conditionElement => {
	// get final value for condition element
	let conditionElementValue = conditionElement.value || ''

	if (conditionElementValue && conditionElement.type === 'CheckboxElement') {
		conditionElementValue = conditionElementValue.values
	} else if (conditionElementValue && conditionElement.type === 'RadioButtonElement') {
		conditionElementValue = conditionElementValue.value
	}

	// remove whitespace from condition value
	if (typeof conditionElementValue === 'string') {
		conditionElementValue = conditionElementValue.trim()
	}

	return conditionElementValue
}

// Element visibility  - CONDITIONS BUILDER
const elementIsVisible = (element, formElements, placement = 'form') => {
	let isVisible = true
	let condArray = []

	if (element.removed) {
		return false
	}

	// check visibility based on display
	isVisible = element.display === 'both' || element.display === placement
	// || element.display === (placement || 'pdf')

	// check visibility based on conditions
	if ('conditions' in element.data) {
		element.data.conditions.forEach(cond => {
			if (cond.element !== '') {
				// Condition logic goes here
				const conditionElement = formElements.find(field => field.id === cond.element)

				// get final value for condition element
				const conditionElementValue = getConditionElementValue(conditionElement)

				switch (cond.condition) {
					case 'equal':
						if (Array.isArray(conditionElementValue)) {
							condArray.push(conditionElementValue.includes(cond.conditionValue.trim()))
						} else {
							condArray.push(conditionElementValue == cond.conditionValue.trim())
						}
						break
					case 'notEqual':
						if (Array.isArray(conditionElementValue)) {
							condArray.push(!conditionElementValue.includes(cond.conditionValue.trim()))
						} else {
							condArray.push(conditionElementValue != cond.conditionValue.trim())
						}
						break
					case 'notEmpty':
						if (Array.isArray(conditionElementValue)) {
							condArray.push(conditionElementValue.length > 0)
						} else {
							condArray.push(Boolean(conditionElementValue))
						}
						break
					case 'empty':
						if (Array.isArray(conditionElementValue)) {
							condArray.push(!conditionElementValue.length)
						} else {
							condArray.push(!conditionElementValue)
						}
						break
				}
			}
		})
	}

	condArray.forEach(e => {
		if (!e) {
			isVisible = false
		}
	})

	return isVisible
}

// Payment conditions - handlePayment function generating payment price based on conditions or if there is no conditions based on entered price
const handlePayment = (element, formElements) => {
	let paymentValue = 0
	let itemsNumber = 1

	// Check is there priceConditions, if not just use price from data.price
	if (element && 'priceConditions' in element.data && element.data.priceConditions.length > 0) {
		// We have conditions for payment
		// In first pass trough conditions we checking for price if
		let priceIfCount = 0
		element.data.priceConditions.forEach(condition => {
			// Find element from condition - condition.element contains only id of element, we need object
			let conditionElement = formElements.find(item => item.id === condition.element)

			if (!conditionElement) {
				return
			}

			const conditionElementValue = getConditionElementValue(conditionElement)

			// If condition type is price-if and conditionElement type is TableElement
			if (condition?.type === 'price-if' && conditionElement.type === 'TableElement') {
				if (condition.condition === 'equal') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value
						itemsNumber = tableData.length
						tableData.forEach(item => {
							let multipleConditions =
								'"' + item[condition.column] + '" === "' + condition.conditionValue + '" '

							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += Number(condition.price)
								item.price = Number(condition.price)
								priceIfCount += 1
							}
						})
					}
				}

				if (condition.condition === 'notEqual') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value

						tableData.forEach(item => {
							let multipleConditions =
								'"' + item[condition.column] + '" !== "' + condition.conditionValue + '" '
							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += Number(condition.price)
								item.price = Number(condition.price)
								priceIfCount += 1
							}
						})
					}
				}

				if (condition.condition === 'empty') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value

						tableData.forEach(item => {
							let multipleConditions = item[condition.column].length + ' === 0 '
							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += Number(condition.price)
								item.price = Number(condition.price)
								priceIfCount += 1
							}
						})
					}
				}

				if (condition.condition === 'notEmpty') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value

						tableData.forEach(item => {
							let multipleConditions = item[condition.column].length + ' > 0 '
							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += Number(condition.price)
								item.price = Number(condition.price)
								priceIfCount += 1
							}
						})
					}
				}
			} else if (condition?.type === 'price-if' && conditionElement.type !== 'TableElement') {
				if (condition.condition === 'equal') {
					if (conditionElementValue == condition.conditionValue) {
						paymentValue = Number(condition.price)
						priceIfCount += 1
					}
				}

				if (condition.condition === 'notEqual') {
					if (conditionElementValue != condition.conditionValue) {
						paymentValue = Number(condition.price)
						priceIfCount += 1
					}
				}

				if (condition.condition === 'empty') {
					if (conditionElementValue.length === 0) {
						paymentValue = Number(condition.price)
						priceIfCount += 1
					}
				}

				if (condition.condition === 'notEmpty') {
					if (conditionElementValue.length > 0) {
						paymentValue = Number(condition.price)
						priceIfCount += 1
					}
				}
			}
		})

		// If there is no price-if conditions use price from price field
		if (priceIfCount === 0) {
			paymentValue = Number(element?.data?.price)
		}

		// In second pass trough conditions we checking for add price if (adding price on price if)
		element.data.priceConditions.forEach(condition => {
			// Find element from condition - condition.element contains only id of element, we need object
			let conditionElement = formElements.find(item => item.id === condition.element)

			if (!conditionElement) {
				return
			}

			const conditionElementValue = getConditionElementValue(conditionElement)

			if (condition.type === 'add-price-if' && conditionElement.type !== 'TableElement') {
				if (condition.condition === 'equal') {
					if (
						Array.isArray(conditionElementValue) &&
						conditionElementValue.includes(condition.conditionValue)
					) {
						paymentValue += Number(condition.price) * itemsNumber
					} else if (conditionElementValue == condition.conditionValue) {
						paymentValue += Number(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'notEqual') {
					if (Array.isArray(conditionElementValue)) {
						if (!conditionElementValue.includes(condition.conditionValue)) {
							paymentValue += Number(condition.price) * itemsNumber
						}
					} else if (conditionElementValue != condition.conditionValue) {
						paymentValue += Number(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'empty') {
					if (conditionElementValue.length === 0) {
						paymentValue += Number(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'notEmpty') {
					if (conditionElementValue.length > 0) {
						paymentValue += Number(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'equalToValue') {
					if (conditionElementValue.length > 0) {
						paymentValue += conditionElementValue * itemsNumber
					}
				}
			}
		})
	} else {
		// We don't have conditions for payment we use payment and quantity from data
		paymentValue = element?.data?.price
	}

	if (element) {
		element.value = Number(paymentValue)
	}

	return itemsNumber
}

// Create condition - create condition in string format with concatenation
const createConditionsString = (condition, item) => {
	let conditionString = ''
	if (condition) {
		if (condition.condition === 'equal') {
			conditionString =
				' ' +
				condition.operation +
				' ' +
				'"' +
				item[condition.column] +
				'"' +
				' === ' +
				'"' +
				condition.conditionValue +
				'"'
		} else if (condition.condition === 'notEqual') {
			conditionString =
				' ' +
				condition.operation +
				' ' +
				'"' +
				item[condition.column] +
				'"' +
				' !== ' +
				'"' +
				condition.conditionValue +
				'"'
		} else if (condition.condition === 'empty') {
			conditionString = ' ' + condition.operation + ' ' + item[condition.column].length + ' === 0 '
		} else if (condition.condition === 'notEmpty') {
			conditionString = ' ' + condition.operation + ' ' + item[condition.column].length + ' > 0 '
		}
	}

	return conditionString
}

const fillTextWithVariables = (text, formFields) => {
	const fnRegex = /([a-z]+)\(([a-z0-9()\s+\-*.]+)\)/i

	//console.log('doing text replacement')

	text = text.replace(/{{\s([a-z0-9()\s+\-*.]+)\s?}}/gi, (match, variable) => {
		let val = '_____'
		variable = variable.trim()
		//console.log('Forms TextFill', match, `"${variable}"`)

		if (variable === 'year') {
			val = new Date().getFullYear()
		} else if (variable === 'nextyear') {
			val = new Date().getFullYear() + 1
		} else if (fnRegex.test(variable)) {
			// handle function, ex: dateToYears(Field Name)
			//console.log('FnReplacement', variable)
			val = variable.replace(fnRegex, (fnMatch, fnName, args) => {
				let fnVal = '(___)'

				//console.log('function', fnMatch, fnName, args)

				if (['calc', 'math', 'money'].includes(fnName)) {
					formFields
						.filter(f => f.type === 'NumberInputElement')
						.forEach(field => {
							args = args
								.replace(`field${field.id}`, field.value || 0)
								.replace(field.label, field.value || 0)
						})

					//console.log('text fill Calc args', args)

					fnVal = evaluate(args)

					if (fnName === 'money') {
						fnVal = numberToCurrency(fnVal)
					}
				} else {
					const field = formFields.find(f => f.label == args || f.id == args)

					if (field && field.value) {
						if (fnName === 'dateToYears') {
							const date = parse(field.value, 'yyyy-MM-dd', new Date())
							fnVal = formatDistanceToNowStrict(date)
						} else {
							fnVal = '(unknown function)'
						}
					}
				}

				return fnVal
			})
		} else {
			// find value for field name
			//console.log('VarReplacement', variable)
			variable = variable.replace('field-', '').replace('field', '')
			const field = formFields.find(f => f.label == variable || f.id == variable)

			if (field?.value) {
				val = displayFieldAnswer(field, field.value, true)
			}
		}

		return `<span class="text-highlight">${val}</span>`
	})

	return text
}

// Update YearElements with DateElements values
const updateYearElements = formElements => {
	let yearElements = formElements.filter(element => element.name === 'Year')
	yearElements.forEach(element => {
		if (element.data.useDynamicMinYear) {
			if (element.data.minYearDynamic && element.data.minYearDynamic.includes('date-year-')) {
				let elementForMinYearID = parseInt(
					element.data.minYearDynamic.split('-')[element.data.minYearDynamic.split('-').length - 1]
				)
				let elementForMinYear = formElements.find(element => element.id === elementForMinYearID)
				element.data.minYear = elementForMinYear.value ? elementForMinYear.value.substring(0, 4) : 1900
			} else if (element.data.minYearDynamic === 'current') {
				element.data.minYear = new Date().getFullYear()
			}
		}

		if (element.data.useDynamicMaxYear) {
			if (element.data.maxYearDynamic && element.data.maxYearDynamic.includes('date-year-')) {
				let elementForMaxYearID = parseInt(
					element.data.maxYearDynamic.split('-')[element.data.maxYearDynamic.split('-').length - 1]
				)
				let elementForMaxYear = formElements.find(element => element.id === elementForMaxYearID)
				element.data.maxYear = elementForMaxYear.value ? elementForMaxYear.value.substring(0, 4) : 1900
			} else if (element.data.maxYearDynamic === 'current') {
				element.data.maxYear = new Date().getFullYear()
			}
		}
	})
}

// Update DatePicker with DatePicker values
const updateDatePickerElements = formElements => {
	const date = new Date()
	let currentDate = date.toISOString().split('T')[0]
	let dateElements = formElements.filter(element => element.name === 'DatePicker')
	dateElements.forEach(element => {
		if (element.data.useDynamicMinDate) {
			if (element.data.minDateDynamic.includes('date-')) {
				let elementForMinDateID = parseInt(
					element.data.minDateDynamic.split('-')[element.data.minDateDynamic.split('-').length - 1]
				)
				let elementForMinDate = formElements.find(element => element.id === elementForMinDateID)
				element.data.minDate = elementForMinDate.value ? elementForMinDate.value : '2021-01-01'
			} else if (element.data.minDateDynamic === 'today') {
				element.data.minDate = currentDate
			}
		}

		if (element.data.useDynamicMaxDate) {
			if (element.data.maxDateDynamic.includes('date-')) {
				let elementForMaxDateID = parseInt(
					element.data.maxDateDynamic.split('-')[element.data.maxDateDynamic.split('-').length - 1]
				)
				let elementForMaxDate = formElements.find(element => element.id === elementForMaxDateID)
				element.data.maxDate = elementForMaxDate.value ? elementForMaxDate.value : '2021-01-01'
			} else if (element.data.maxDateDynamic === 'today') {
				element.data.maxDate = currentDate
			}
		}
	})
}

const yearsFromDateToNow = fromDateString => {
	const fromDate = new Date(fromDateString)
	let fnVal = formatDistanceToNowStrict(fromDate)
	return fnVal
}

// Update DatePicker with DatePicker values
const updateNumberInputElement = formElements => {
	let dateElements = formElements.filter(element => element.name === 'Number')
	dateElements.forEach(element => {
		if (element.data.useDynamicValue) {
			// Years from selected date component to current day
			if (element.data.valueCalculation === 'years-from-date-to-now') {
				let elementForNumberID = parseInt(
					element.data.valueElement.split('-')[element.data.valueElement.split('-').length - 1]
				)
				let elementForNumber = formElements.find(element => element.id === elementForNumberID)
				if (elementForNumber.value) {
					const fromDate = parse(elementForNumber.value, 'yyyy-MM-dd', new Date())
					console.log(fromDate)
					console.log(new Date())
					let dateValue = differenceInYears(new Date(), fromDate)
					element.value = dateValue
				} else {
					element.value = ''
				}
			}
			// Days from selected date to current day
			if (element.data.valueCalculation === 'days-from-date-to-now') {
				let elementForNumberID = parseInt(
					element.data.valueElement.split('-')[element.data.valueElement.split('-').length - 1]
				)
				let elementForNumber = formElements.find(element => element.id === elementForNumberID)
				if (elementForNumber.value) {
					const fromDate = parse(elementForNumber.value, 'yyyy-MM-dd', new Date())
					const difference = new Date() - fromDate
					const differenceInDays = Math.round(Math.abs(difference / (24 * 60 * 60 * 1000)))
					element.value = differenceInDays
				} else {
					element.value = ''
				}
			}
		}
	})
}

/**
 * Calculate form or venue price based on price conditions
 */
const calculatePrice = (price, deposit, priceConditions, fields, answers) => {
	const matchedRules = []

	priceConditions.forEach(priceCondition => {
		let apply = false
		const field = fields.find(f => f.id === priceCondition.field)
		let value = answers[priceCondition.field] || ''

		if (value && field.type === 'RadioButtonElement') {
			value = value.value
		} else if (value && field.type === 'CheckboxElement') {
			value = value.values
		}

		// remove spaces and unwanted characters
		if (typeof value === 'string') {
			value = value.toLowerCase().trim()
		}
		if (typeof priceCondition.value === 'string') {
			priceCondition.value = priceCondition.value.toLowerCase().trim()
		}

		// check values
		if (priceCondition.op === '=' && value == priceCondition.value) {
			apply = true
		} else if (priceCondition.op === '!=' && value != priceCondition.value) {
			apply = true
		} else if (priceCondition.op === 'empty' && !value.length) {
			apply = true
		} else if (priceCondition.op === '!empty' && value.length) {
			apply = true
		} else if (priceCondition.op === 'contains' && value.includes(priceCondition.value)) {
			apply = true
		}

		if (apply) {
			matchedRules.push(priceCondition)
			priceCondition.amount = Number(priceCondition.amount)
			//console.log('✅', priceCondition.type, priceCondition.amountType, priceCondition.amount)

			if (priceCondition.amountType === 'fixed' && priceCondition.type.startsWith('add')) {
				if (priceCondition.type.endsWith('-deposit')) {
					deposit += priceCondition.amount
				} else {
					price += priceCondition.amount
				}
			} else if (priceCondition.amountType === 'fixed' && priceCondition.type.startsWith('sub')) {
				if (priceCondition.type.endsWith('-deposit')) {
					deposit -= priceCondition.amount
				} else {
					price -= priceCondition.amount
				}
			} else if (priceCondition.amountType === 'percentage' && priceCondition.type.startsWith('add')) {
				if (priceCondition.type.endsWith('-deposit')) {
					deposit = deposit * (1 + priceCondition.amount / 100)
				} else {
					price = price * (1 + priceCondition.amount / 100)
				}
			} else if (priceCondition.amountType === 'percentage' && priceCondition.type.startsWith('sub')) {
				if (priceCondition.type.endsWith('-deposit')) {
					deposit = deposit * (1 - priceCondition.amount / 100)
				} else {
					price = price * (1 - priceCondition.amount / 100)
				}
			}
		}
	})

	return { price, deposit, matchedRules }
}

//todo store license/certificate flag in form.has_license_template
const formsWithLicenseTemplates = [
	// Sheboygan forms
	'alarm-business-license-application',
	'alarm-user-permit-application',
	'alarm-user-permit-application-commercial',
	'quadricycle-business-license-application',
	'electric-scooter-business-license-application',
	'secondhand-article-and-jewelry-dealer-license-application',
	'taxicab-business-license',
	'massage-establishment-license',
	'application-for-temporary-class-bclass-b-retailers-license-872',
	'commercial-operators-license-application',

	'liquor-license',
	'taxicab-driver-license-application',
]

export {
	formStatuses,
	formRequestStatuses,
	formRequestStatusesApplicant,
	elementIsVisible,
	handlePayment,
	fillTextWithVariables,
	updateYearElements,
	updateDatePickerElements,
	yearsFromDateToNow,
	updateNumberInputElement,
	calculatePrice,
	formsWithLicenseTemplates,
}
