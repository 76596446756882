<template>
	<div class="meeting-overview">
		<div class="card mb-4">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3">
						<p class="mb-2">
							<span class="text-neutral-400">Date: </span>
							<strong>{{
								new Date(meeting.starts_at).toLocaleString('en-US', {
									dateStyle: 'long',
									timeStyle: 'short',
									timeZone: j.timezone,
								})
							}}</strong>
						</p>
						<p class="mb-0">
							<span class="text-neutral-400">Agenda: </span>
							<router-link
								v-if="meeting.agenda_items.length"
								:to="`/${j.slug}/meetings/${meeting.pid}/agenda`"
								>{{ meeting.agenda_items.length }} agenda items</router-link
							>
							<router-link v-else :to="`/${j.slug}/meetings/${meeting.pid}/agenda`" class="text-primary"
								><font-awesome-icon :icon="['fas', 'plus']" /> Add agenda</router-link
							>
						</p>
					</div>
					<div class="col-lg-6 mb-3">
						<p class="mb-2">
							<span class="text-neutral-400 me-1">Department:</span>
							<template v-if="departments && meeting.department_id">{{
								departments.find(d => d.id == meeting.department_id).name
							}}</template>
							<small v-else class="text-neutral-300">(none)</small>
						</p>
						<p class="mb-0">
							<span class="text-neutral-400 me-1">
								{{ meeting.categories && meeting.categories.length === 1 ? 'Category' : 'Categories' }}:
							</span>
							<template v-if="meeting.categories && meeting.categories.length">
								{{
									meetingCategories
										.filter(c => meeting.categories.includes(c.id))
										.map(c => c.name)
										.join(', ')
								}}
							</template>
							<small v-else class="text-neutral-300">(none)</small>
						</p>
					</div>
				</div>

				<p class="card-text">
					<template v-if="meeting.description">{{ meeting.description }}</template>
					<small v-else class="text-neutral-300">(no meeting description)</small>
				</p>
			</div>
		</div>

		<h5>Meeting info</h5>
		<div class="card mb-4">
			<div v-if="meeting.state === 'upcoming'" class="card-body text-center py-6">
				<p class="card-text">
					This meeting will take place
					<strong>{{ formatDistanceToNow(new Date(meeting.starts_at), { addSuffix: true }) }}</strong>
				</p>
				<p class="card-text">
					<button class="btn btn-sm btn-outline-dark">
						<font-awesome-icon :icon="['fas', 'calendar-alt']" class="me-1" /> Add to calendar
					</button>
				</p>
			</div>
			<div v-else-if="meeting.state === 'happening'" class="card-body">
				<p class="card-text">
					⏺️ This meeting is happening right now!
				</p>
			</div>
			<div v-else class="card-body">
				<div class="row">
					<div class="col-lg-6">
						<p class="card-text text-neutral-500">
							This is how the meeting went.
						</p>

						<p class="mb-2">
							<span class="text-neutral-400 me-1">Recording:</span>
							<router-link
								v-if="meeting.transcript_job_status === 'not-started'"
								:to="`/${j.slug}/meetings/${meeting.pid}/transcript`"
								class="text-warning"
								>Add audio/video</router-link
							>
							<span v-else-if="meeting.transcript_job_status === 'started'"
								><span class="spinner-border spinner-border-sm"></span> Processing</span
							>
							<strong v-else-if="meeting.transcript_job_status === 'transcribed'">✅</strong>
							<code v-else>{{ meeting.transcript_job_status }}</code>
						</p>
						<p class="card-text">
							<span class="text-neutral-400 me-1">Minutes:</span>
							<router-link
								v-if="meeting.minutes_status === 'missing'"
								:to="`/${j.slug}/meetings/${meeting.pid}/minutes`"
								class="text-warning"
								>Add minutes</router-link
							>
							<span v-else-if="meeting.minutes_status === 'generating'"
								><span class="spinner-border spinner-border-sm"></span> Generating</span
							>
							<strong v-else-if="['done', 'fresh-done'].includes(meeting.minutes_status)">✅</strong>
							<code v-else>{{ meeting.minutes_status }}</code>
						</p>

						<template v-if="meeting.motions && meeting.motions.length">
							<h6>Motions</h6>

							<div
								v-for="(motion, index) in meeting.motions"
								:key="index"
								class="p-2 bg-primary-50 rounded-1 mb-2"
							>
								<p class="mb-2">{{ motion.motion }}</p>
								<p class="mb-0">
									{{ motion.vote }} &middot; By <person-link :id="motion.voted_by_id" /> &middot;
									Seconded by
									<person-link :id="motion.seconded_by_id" />
								</p>
							</div>
						</template>
					</div>
					<div class="col-lg-6">
						<div
							v-if="meeting.video_file_path || meeting.audio_file_path"
							class="ratio bg-light rounded-1"
							:class="meeting.video_file_path ? 'ratio-16x9' : 'ratio-21x9'"
							ref="meetingOverviewPlayer"
						>
							<div
								v-if="meetingPlayer.position === 'custom'"
								class="d-flex align-items-center justify-content-center"
								@click="setPlayerPosition"
							>
								<span class="text-neutral-400"
									><font-awesome-icon :icon="['fas', 'video']" class="me-1" /> Bring the video
									here</span
								>
							</div>
						</div>
						<router-link
							:to="`/${j.slug}/meetings/${meeting.pid}/transcript`"
							v-else
							class="d-block bg-light rounded-1 border border-dashed border-warning text-neutral-400 text-center p-3"
						>
							Upload the audio/video recording
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isStaff" class="card mt-6">
			<div class="card-header bg-danger-lighter">
				<h5 class="my-0">Info for HeyGov staff</h5>
			</div>
			<div class="card-body">
				<p class="card-text">
					AI cost: <strong>{{ numberToCurrency(meeting.ai_cost) }}</strong>
				</p>
				<p class="card-text">
					Transcript:
					<a :href="`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-job-start`" target="_blank"
						>Run transcript job again</a
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { formatDistanceToNow } from 'date-fns'

import { hgApi } from '@/api.js'
import { getPublicFileUrl, numberToCurrency } from '@/utils.js'

import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'MeetingsOverview',
	components: { PersonLink },
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	data() {
		return {
			meetingCategories: [],
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'departments']),
		...mapGetters(['auth', 'currentRole', 'isStaff']),
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.loadMeetingCategories()

		if (this.meeting.motions.length) {
			this.$store.dispatch('loadPeople', this.meeting.motions.map(m => [m.voted_by_id, m.seconded_by_id]).flat())
		}
	},
	mounted() {
		if (this.meetingPlayer.position === 'default') {
			this.setPlayerPosition()
		}
	},
	methods: {
		getPublicFileUrl,
		formatDistanceToNow,
		numberToCurrency,

		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`).then(response => {
				if (response.ok) {
					response.json().then(categories => {
						this.meetingCategories.push(...categories)
					})
				} else {
					Vue.toasted.error('Failed to load meeting categories')
				}
			})
		},

		setPlayerPosition() {
			const $player = this.$refs.meetingOverviewPlayer

			if ($player) {
				const $playerPosition = $player.getBoundingClientRect()

				this.$emit('playerStyles', {
					position: 'absolute',
					width: `${$playerPosition.width}px`,
					top: `${$playerPosition.top + window.scrollY}px`,
					left: `${$playerPosition.left}px`,
					right: 'auto',
					//bottom: '10px',
				})
			}
		},
	},
}
</script>
